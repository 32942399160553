// eslint-disable-next-line no-unused-vars
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';

// constants
import { TABS } from '@/constants/patient';
export default {
  props: {
    tab: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState('patients', ['statusCounts']),
    TABS: () => TABS
  },
  methods: {
    setTab(tab) {
      this.$emit('set-tab', tab.id);
    }
  }
};