var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    attrs: {
      "hide-header": "",
      "hide-footer": "",
      centered: "",
      "no-close-on-backdrop": "",
      "no-stacking": "",
      size: "lg"
    },
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c("b-alert", {
    attrs: {
      variant: "warning",
      show: _vm.alert.show
    }
  }, [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]), _c("b-row", {
    staticClass: "mb-4",
    attrs: {
      "align-h": "between"
    }
  }, [_c("b-col", {
    attrs: {
      cols: "10"
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/icon_logo_violet.svg"),
      alt: ""
    }
  })]), _c("b-col", {
    staticClass: "text-right"
  }, [_c("a", {
    staticClass: "tw-text-eclipse",
    attrs: {
      href: "javascript:;"
    },
    on: {
      click: function ($event) {
        _vm.showDialog = false;
      }
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "x-circle",
      "font-scale": "1.5"
    }
  })], 1)])], 1), _c("b-row", {
    staticClass: "mt-4 pr-4"
  }, [_c("b-col", {
    staticClass: "text-primary",
    attrs: {
      cols: "3"
    }
  }, [_vm._v("New Reassignment")]), _c("b-col", [_c("span", {
    staticClass: "text-primary text-small"
  }, [_vm._v("Doctor")]), _c("b-form-select", {
    attrs: {
      options: _vm.doctorOptions
    },
    model: {
      value: _vm.selectedDoctor,
      callback: function ($$v) {
        _vm.selectedDoctor = $$v;
      },
      expression: "selectedDoctor"
    }
  }), _c("span", {
    staticClass: "text-muted text-small mb-3"
  }, [_vm._v(" " + _vm._s(_vm.selectedDoctorStates) + " ")]), _c("br"), _c("span", {
    staticClass: "text-primary text-small tw-mt-4 tw-block"
  }, [_vm._v(" Reassignment Type ")]), _c("b-form-select", {
    staticClass: "mb-5",
    attrs: {
      options: _vm.reassignmentOptions
    },
    model: {
      value: _vm.reassignmentType,
      callback: function ($$v) {
        _vm.reassignmentType = $$v;
      },
      expression: "reassignmentType"
    }
  }), _c("span", {
    staticClass: "text-primary text-small mt-4"
  }, [_vm._v("Out of Office")]), _c("br"), _c("span", {
    staticClass: "text-muted text-small"
  }, [_vm._v("From")]), _c("b-form-datepicker", {
    model: {
      value: _vm.outFrom,
      callback: function ($$v) {
        _vm.outFrom = $$v;
      },
      expression: "outFrom"
    }
  }), _c("b-form-timepicker", {
    attrs: {
      "minutes-step": "15"
    },
    model: {
      value: _vm.outFromTime,
      callback: function ($$v) {
        _vm.outFromTime = $$v;
      },
      expression: "outFromTime"
    }
  }), _c("span", {
    staticClass: "text-muted text-small tw-mt-4 tw-block"
  }, [_vm._v("To")]), _c("b-form-datepicker", {
    attrs: {
      disabled: _vm.indefinite
    },
    model: {
      value: _vm.outTo,
      callback: function ($$v) {
        _vm.outTo = $$v;
      },
      expression: "outTo"
    }
  }), _c("b-form-timepicker", {
    attrs: {
      "minutes-step": "15",
      disabled: _vm.indefinite
    },
    model: {
      value: _vm.outToTime,
      callback: function ($$v) {
        _vm.outToTime = $$v;
      },
      expression: "outToTime"
    }
  })], 1), _c("b-col", [_c("span", {
    staticClass: "text-primary text-small"
  }, [_vm._v("Reassign To")]), _c("b-row", {
    staticClass: "mb-3",
    attrs: {
      "h-align": "between"
    }
  }, [_c("b-col", [_c("span", {
    staticClass: "text-muted text-small"
  }, [_vm._v("Doctor")])]), _c("b-col", {
    attrs: {
      cols: "3"
    }
  }, [_c("span", {
    staticClass: "text-muted text-small"
  }, [_vm._v("State")])])], 1), _vm._l(_vm.doctorsByState, function (doctor, index) {
    return _c("b-row", {
      key: index,
      attrs: {
        "h-align": "between"
      }
    }, [_c("b-col", [_c("b-form-checkbox", {
      attrs: {
        value: doctor.id,
        "unchecked-value": null
      },
      model: {
        value: _vm.doctorsToReassign[index],
        callback: function ($$v) {
          _vm.$set(_vm.doctorsToReassign, index, $$v);
        },
        expression: "doctorsToReassign[index]"
      }
    }, [_vm._v(" Dr " + _vm._s(doctor.firstname) + " " + _vm._s(doctor.lastname) + " ")])], 1), _c("b-col", {
      attrs: {
        cols: "3"
      }
    }, [_vm._v(" " + _vm._s(_vm.getStates(doctor)) + " ")]), _c("b-col", {
      attrs: {
        cols: "12"
      }
    }, [_c("hr", {
      staticStyle: {
        "margin-top": "8px",
        "margin-bottom": "8px"
      }
    })])], 1);
  })], 2)], 1), _c("b-row", {
    staticClass: "mt-4"
  }, [_c("b-col", {
    attrs: {
      offset: "3"
    }
  }, [_c("b-row", [_c("b-col", [_c("b-button", {
    attrs: {
      variant: "outline-dark",
      pill: "",
      block: ""
    },
    on: {
      click: function ($event) {
        _vm.showDialog = false;
      }
    }
  }, [_vm._v(" Back ")])], 1), _c("b-col", [_c("b-button", {
    attrs: {
      variant: "secondary",
      pill: "",
      block: ""
    },
    on: {
      click: _vm.addReassignment
    }
  }, [_vm._v(" Confirm ")])], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };