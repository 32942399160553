export default {
  data() {
    return {
      alert: {
        message: '',
        show: false
      },
      showDialog: false,
      loading: false
    };
  }
};