import jwt from 'jsonwebtoken';
import IconIntercom from '@/components/icon/IconIntercom.vue';
import IconStripe from '@/components/icon/IconStripe.vue';
export default {
  components: {
    IconIntercom,
    IconStripe
  },
  props: {
    row: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      navigator: null
    };
  },
  computed: {
    stripeLink() {
      if (process.env.VUE_APP_SERVER == 'production') {
        return `https://dashboard.stripe.com/customers/${this.row.customerid}`;
      } else {
        return `https://dashboard.stripe.com/customers/test/${this.row.customerid}`;
      }
    }
  },
  mounted() {
    this.navigator = window.navigator;
  },
  methods: {
    copy(text) {
      navigator.clipboard.writeText(text);
      this.$emit('show-toast', 'Copied to clipboard successfully!', 'success');
    },
    redirectToIntercom(id) {
      const token = jwt.sign({}, 'NTNv7j0TuYARvmNMmWXo6fKvM4o6nv/aUi9ryX38ZH+L1bkrnD1ObOQ8JAUmHCBq7Iy7otZcyAagBLHVKvvYaIpmMuxmARQ97jUVG16Jkpkp1wXOPsrF9zwew6TpczyHkHgX5EuLg2MeBuiT/qJACs1J0apruOOJCg/gOtkjB4c=', {
        header: {
          predicates: [{
            attribute: 'role',
            comparison: 'eq',
            type: 'role',
            value: 'user_role'
          }, {
            attribute: 'user_id',
            comparison: 'eq',
            type: 'string',
            value: id
          }]
        }
      });
      const toSubmit = token.split('.');
      window.open(`https://app.intercom.com/a/apps/dfvodm6i/users/segments/all-users:${toSubmit[0]}`, '_blank');
    }
  }
};