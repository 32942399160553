// libs
import { mapState } from 'vuex';
import _get from 'lodash/get';

// components
import ChatBubble from '@corefront/components-v2/Chat/ChatBubble';
import DoctorRequestDialog from '@/components/patients/DoctorRequestDialog';

// constants
import { MESSAGE_TYPE, COMMUNICATION_ROLE, CHAT_TYPE, ROLES, MESSAGE_SEND_STATUS } from '@corefront/constant/messages';

// mixins
import { failedMessagesMixin } from '@/mixins/failedMessages';
export default {
  components: {
    ChatBubble,
    DoctorRequestDialog
  },
  mixins: [failedMessagesMixin],
  props: {
    message: {
      type: Object,
      required: true
    },
    members: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedForwardedRequest: null
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    listeners() {
      return {
        ...this.$listeners,
        'delete-failed': this.onDeleteFailed,
        'retry-failed': this.onRetryFailed
      };
    },
    COMMUNICATION_ROLE: () => COMMUNICATION_ROLE,
    MESSAGE_TYPE: () => MESSAGE_TYPE,
    CHAT_TYPE: () => CHAT_TYPE,
    ROLES: () => ROLES,
    reversedForwardMessages() {
      const forwardMessage = _get(this.message, 'custommessage.forwardmessage.messagelist', []);
      return [...forwardMessage].reverse();
    },
    communicationRole() {
      return this.getCommunicationRole(this.message);
    },
    profileUrl() {
      return this.member ? this.member.profileUrl || require('@/assets/images/anon.jpeg') : '';
    },
    member() {
      switch (this.message.type) {
        case MESSAGE_TYPE.FOLLOWUP:
          return {
            firstName: 'Follow Up'
          };
        case MESSAGE_TYPE.PRESCRIPTION_CONFIRMATION:
          return {
            firstName: 'Prescription Confirmation'
          };
        case MESSAGE_TYPE.PRESCRIPTION_FOLLOWUP:
          return {
            firstName: 'Prescription Follow Up'
          };
        case MESSAGE_TYPE.FORWARD_MESSAGE:
          return {
            firstName: 'Forwarded Message'
          };
        default:
          {
            const foundMember = this.members.find(x => x.id === this.message.userid);
            return foundMember || {
              firstName: 'Unknown'
            };
          }
      }
    }
  },
  methods: {
    openRequestDialog(fwd) {
      this.selectedForwardedRequest = fwd;
      this.$refs.DoctorRequestDialog.showDialog = true;
    },
    getCommunicationRole(msg) {
      if (msg.type === MESSAGE_TYPE.FOLLOWUP) {
        return COMMUNICATION_ROLE.RECEIVER;
      }
      return this.currentUser.id === msg.userid ? COMMUNICATION_ROLE.SENDER : COMMUNICATION_ROLE.RECEIVER;
    },
    onDeleteFailed() {
      const id = this.message.id;
      this.$store.commit('message/DELETE_MESSAGE_BY_ID', id);
      this.$store.dispatch('failedMessages/resolveByMessageId', id);
    },
    async onRetryFailed() {
      this.$store.commit('message/UPDATE_MESSAGE_BY_ID', {
        id: this.message.id,
        sentStatus: MESSAGE_SEND_STATUS.PENDING
      });
      await this.$_failedMessages_sendAdminMessage({
        message: {
          ...this.message,
          sentat: null
        },
        isRetry: true
      });
    }
  }
};