import { mapState } from 'vuex';

// constants
import { COMMUNICATION_ROLE } from '@corefront/constant/messages';

// components
import CoreTooltip from '@corefront/components-v2/Core/CoreTooltip.vue';
import ChatBubble from '@corefront/components-v2/Chat/ChatBubble.vue';
import CoreAvatar from '@corefront/components-v2/Core/CoreAvatar.vue';
export default {
  components: {
    CoreTooltip,
    ChatBubble,
    CoreAvatar
  },
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    ...mapState('doctorMessageRouter', ['selectedConversation']),
    communicationRole() {
      const {
        currentUser,
        message
      } = this;
      return currentUser.id === message.userid ? COMMUNICATION_ROLE.SENDER : COMMUNICATION_ROLE.RECEIVER;
    },
    avatarDetails() {
      const {
        member
      } = this;
      if (member.role === 'admin') {
        return {
          text: 'A',
          color: 'eclipse'
        };
      }
      if (member.role === 'doctor') {
        return {
          text: 'D',
          color: 'chestnut'
        };
      }
      if (member.role === 'patient') {
        return {
          text: 'P',
          color: 'evergreen'
        };
      }
      return {};
    },
    member() {
      const {
        message,
        currentUser,
        selectedConversation
      } = this;
      const userId = message.userid;
      if (userId === selectedConversation.doctor.id) {
        return {
          id: selectedConversation.doctor.id,
          firstname: selectedConversation.doctor.firstname,
          lastname: selectedConversation.doctor.lastname,
          role: 'doctor',
          profileUrl: selectedConversation.doctor.profileUrl
        };
      }
      if (userId === selectedConversation.patient.id) {
        return {
          id: selectedConversation.patient.id,
          firstname: selectedConversation.patient.firstName,
          lastname: selectedConversation.patient.lastName,
          profileUrl: selectedConversation.patient.profileUrl,
          role: 'patient'
        };
      }
      return {
        id: currentUser.id,
        firstname: currentUser.firstname,
        lastname: currentUser.lastname,
        role: 'admin'
      };
    },
    bubbleBackgroundColorClass() {
      const {
        member,
        message
      } = this;
      if (message.messageroutedto) {
        return 'tw-bg-light-gray';
      }

      // Retain doctor color even if message is for admin review
      if (!message.foradminreview && member.role !== 'doctor') {
        return 'tw-bg-light-gray';
      }
      if (member.role === 'admin') {
        return 'tw-bg-mischka-light';
      }
      if (member.role === 'doctor') {
        return 'tw-bg-warm-gray';
      }
      if (member.role === 'patient') {
        return 'tw-bg-zinc-300';
      }
      return '';
    }
  }
};