var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-card", {
    staticClass: "chat-patient-list",
    attrs: {
      "no-body": ""
    }
  }, [_c("b-card-header", {
    attrs: {
      id: _vm.$_message_CHAT_HEADER_ID
    }
  }, [_c("b-row", [_c("b-col", [_c("div", {
    staticClass: "input-group input-group-flush"
  }, [_c("div", {
    staticClass: "input-group-prepend"
  }, [_c("span", {
    staticClass: "input-group-text"
  }, [_c("i", {
    staticClass: "fe fe-search text-primary"
  })])]), _c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filter,
      expression: "filter"
    }],
    staticClass: "form-control list-search",
    attrs: {
      type: "search",
      placeholder: "Search"
    },
    domProps: {
      value: _vm.filter
    },
    on: {
      input: [function ($event) {
        if ($event.target.composing) return;
        _vm.filter = $event.target.value;
      }, _vm.onConversationSearch]
    }
  })])])], 1), _vm.isSearching ? _c("b-row", [_c("b-col", [_c("IconLoading", {
    staticClass: "tw-size-4"
  })], 1)], 1) : _vm.paginatedConversationList.length && _vm.conversationListNextPage.length ? _c("b-row", [_c("b-col", [_c("div", {
    staticClass: "px-2 py-2 d-flex justify-content-center align-items-center"
  }, [_vm.conversationListPageNumber > 1 ? _c("a", {
    attrs: {
      href: ""
    },
    on: {
      click: function ($event) {
        $event.preventDefault();
        return _vm.fetchPreviousConversationList.apply(null, arguments);
      }
    }
  }, [_c("svg", {
    staticClass: "tw-size-[20px]",
    attrs: {
      xmlns: "http://www.w3.org/2000/svg",
      fill: "none",
      viewBox: "0 0 24 24",
      stroke: "currentColor"
    }
  }, [_c("path", {
    attrs: {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      d: "M15 19l-7-7 7-7"
    }
  })])]) : _vm._e(), _c("small", {
    staticClass: "tw-text-davys-grey px-1 tw-leading-none"
  }, [_vm._v(" Page " + _vm._s(_vm.conversationListPageNumber) + " ")]), !(_vm.hasUndefinedNextPage && _vm.conversationListPageNumber === _vm.$store.state.message.maxPageNumber) ? _c("a", {
    class: {
      "tw-pointer-events-none tw-cursor-not-allowed": _vm.fetchingNextPage
    },
    attrs: {
      href: ""
    },
    on: {
      click: function ($event) {
        $event.preventDefault();
        return _vm.nextConversationList.apply(null, arguments);
      }
    }
  }, [_vm.fetchingNextPage ? _c("b-spinner", {
    staticClass: "tw-ml-1",
    attrs: {
      small: ""
    }
  }) : _c("svg", {
    staticClass: "tw-size-[20px]",
    attrs: {
      xmlns: "http://www.w3.org/2000/svg",
      fill: "none",
      viewBox: "0 0 24 24",
      stroke: "currentColor"
    }
  }, [_c("path", {
    attrs: {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      d: "M9 5l7 7-7 7"
    }
  })])], 1) : _vm._e()])])], 1) : _vm._e()], 1), _c("ul", {
    ref: "conversationListRef",
    style: {
      height: `${_vm.$_message_bubbleListHeight}px`
    }
  }, [_vm._l(_vm.paginatedConversationList, function (conv, conversationKey) {
    return _c("li", {
      key: conversationKey,
      staticClass: "patient-list",
      class: [_vm.conversation.id == conv.id ? "selected" : ""],
      on: {
        click: function ($event) {
          return _vm.$emit("select-conversation", conv);
        }
      }
    }, [_c("div", {
      staticClass: "list-img"
    }, [_c("b-avatar", {
      attrs: {
        variant: "tertiary",
        src: conv.profileUrl
      }
    })], 1), _c("div", {
      staticClass: "list-content"
    }, [_c("div", {
      staticClass: "tw-flex justify-content-between tw-gap-1 tw-items-center"
    }, [_c("h3", {
      staticClass: "tw-mb-0 tw-text-nowrap tw-text-ellipsis tw-overflow-hidden tw-text-[14px]",
      attrs: {
        title: conv.name
      }
    }, [_vm._v(" " + _vm._s(conv.name) + " ")]), _c("p", {
      staticClass: "tw-mb-0 tw-text-muted tw-shrink-0 tw-text-[10px]"
    }, [_vm._v(" Dr. " + _vm._s(conv.doctorname) + " ")])]), _c("div", {
      staticClass: "d-flex justify-content-between tw-items-center"
    }, [_c("p", {
      staticClass: "mb-0 text-truncate"
    }, [_vm._v(" " + _vm._s(_vm.trimHtmlTags(conv.lastmessage)) + " ")]), !conv.unreadcount && conv.lastmessage ? _c("b-dropdown", {
      staticClass: "tw-p-0",
      attrs: {
        variant: "link",
        "toggle-class": "text-decoration-none",
        "no-caret": "",
        size: "sm"
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_c("b-icon", {
            attrs: {
              icon: "three-dots"
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c("b-dropdown-item", {
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _vm.onMarkUnread(conv.id);
        }
      }
    }, [_vm._v(" Mark as Unread ")])], 1) : _vm._e(), conv.unreadcount ? _c("b-badge", {
      staticClass: "ml-2",
      staticStyle: {
        padding: "5px",
        "border-radius": "9999px",
        display: "inline-block",
        "background-color": "#396b70 !important"
      }
    }) : _vm._e()], 1)])]);
  }), _vm.$route.query.patientid ? _c("li", {
    staticClass: "text-center"
  }, [_c("a", {
    staticClass: "py-3 d-inline-block tw-cursor-pointer",
    on: {
      click: function ($event) {
        $event.preventDefault();
        return _vm.onLoadAllConversations.apply(null, arguments);
      }
    }
  }, [_vm._v(" Load all conversations ")])]) : _vm._e()], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };