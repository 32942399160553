var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    staticClass: "p-4",
    attrs: {
      "hide-header": "",
      "hide-footer": "",
      centered: "",
      "no-close-on-backdrop": "",
      "no-stacking": "",
      size: "md"
    },
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c("b-alert", {
    attrs: {
      variant: "warning",
      show: _vm.alert.show
    }
  }, [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]), _c("b-row", {
    staticClass: "mb-4",
    attrs: {
      "align-h": "between"
    }
  }, [_c("b-col", {
    attrs: {
      cols: "10"
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/icon_logo_violet.svg"),
      alt: ""
    }
  })]), _c("b-col", {
    staticClass: "text-right"
  })], 1), _c("b-row", [_c("b-col", {
    attrs: {
      offset: "2"
    }
  }, [_c("h2", {
    staticClass: "heading tw-mb-2 tw-text-2xl"
  }, [_vm._v("Revert Patient Removal")]), _c("p", {
    staticClass: "tw-text-muted tw-text-base"
  }, [_vm._v(" Are you sure you want to revert removing " + _vm._s(_vm.rowData.firstname) + " " + _vm._s(_vm.rowData.lastname) + "? ")])])], 1), _c("b-row", {
    staticClass: "mt-4"
  }, [_c("b-col", {
    attrs: {
      offset: "2"
    }
  }, [_c("label", {
    attrs: {
      for: "revertNotes"
    }
  }, [_vm._v("Notes")]), _c("b-form-textarea", {
    attrs: {
      id: "revertNotes"
    },
    model: {
      value: _vm.revertNotes,
      callback: function ($$v) {
        _vm.revertNotes = $$v;
      },
      expression: "revertNotes"
    }
  })], 1)], 1), _c("b-row", {
    staticClass: "mt-3"
  }, [_c("b-col", {
    attrs: {
      offset: "2"
    }
  }, [_c("b-row", [_c("b-col", [_c("b-button", {
    attrs: {
      variant: "outline-primary",
      block: "",
      disabled: _vm.buttonDisabled,
      pill: ""
    },
    on: {
      click: function ($event) {
        _vm.showDialog = false;
      }
    }
  }, [_vm._v(" Back ")])], 1), _c("b-col", [_c("b-button", {
    attrs: {
      variant: "primary",
      block: "",
      disabled: _vm.buttonDisabled,
      pill: ""
    },
    on: {
      click: function ($event) {
        return _vm.revert();
      }
    }
  }, [_vm._v(" Confirm ")])], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };