var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("SideBar", {
    staticClass: "tw-hidden md:tw-block"
  }), _c("div", {
    staticClass: "main-content tw-pl-[76px]"
  }, [_c("vue-page-transition", [_c("router-view")], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };