var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "tw-flex tw-h-screen"
  }, [_c("ChatCategoryColumn", {
    staticClass: "tw-w-[200px] tw-bg-slate-100 tw-p-6",
    on: {
      "category-update": _vm.onCategoryUpdate
    }
  }), _c("ChatMessageListColumn", {
    ref: "chatMessageListColumn",
    staticClass: "tw-w-[280px] tw-border-x tw-border-y-0 tw-border-solid tw-border-slate-300",
    attrs: {
      "is-processing": _vm.isProcessing
    },
    on: {
      select: _vm.onConversationSelect
    }
  }), _vm.selectedConversation ? _c("ChatMessagesColumn", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedConversation,
      expression: "selectedConversation"
    }],
    key: _vm.chatMessageColumnKey,
    staticClass: "tw-flex-1",
    attrs: {
      conversation: _vm.selectedConversation,
      "is-messages-loaded": _vm.isMessagesLoaded,
      "online-status-response": _vm.onlineStatusResponse
    }
  }) : _vm._e(), !_vm.selectedConversation ? _c("div", {
    staticClass: "tw-flex-1 tw-flex tw-justify-center tw-items-center tw-text-xl tw-text-gray-500 tw-col-span-7"
  }, [_vm._v(" No chats selected ")]) : _vm._e(), _c("ChatProfileDisplay", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showProfile,
      expression: "showProfile"
    }],
    staticClass: "tw-w-[260px] tw-border-x tw-border-y-0 tw-border-solid tw-border-slate-300"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };