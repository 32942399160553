import "core-js/modules/es.array.push.js";
import { InventoryService } from '@/services/inventory.service';
export default {
  data() {
    return {
      headers: [{
        label: 'Product Name',
        key: 'productname'
      }],
      selectedRow: null,
      items: []
    };
  },
  async mounted() {
    const {
      data
    } = await InventoryService.getInventory();
    let itemsByProduct = [];
    let objectKeys = Object.keys(data);
    // Loop by state
    for (let state of objectKeys) {
      // Loop through items in state
      let itemKeys = Object.keys(data[state]);
      for (let product of itemKeys) {
        // Check if item is already on the table
        let exists = itemsByProduct.find(o => o.productname == product);
        // Add item to the table
        if (!exists) {
          let item = {
            productname: product
          };
          // Assign the state levels to the new item
          item[`${state} Levels`] = data[state][product];
          itemsByProduct.push(item);
          // Add state to header
          this.headers.push(`${state} Levels`);
        } else {
          // Add state levels to item
          let index = itemsByProduct.findIndex(o => o.productname == product);
          itemsByProduct[index][`${state} Levels`] = data[state][product];
          // Add state to header
          this.headers.push(`${state} Levels`);
        }
      }
    }
    this.items = itemsByProduct;
  }
};