var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("svg", {
    attrs: {
      version: "1.1",
      xmlns: "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      x: "0px",
      y: "0px",
      viewBox: "0 0 256 256",
      "enable-background": "new 0 0 256 256",
      "xml:space": "preserve"
    }
  }, [_c("metadata", [_vm._v(" Svg Vector Icons : http://www.onlinewebfonts.com/icon ")]), _c("g", [_c("g", [_c("g", [_c("path", {
    attrs: {
      fill: "#000000",
      d: "M103.8,10.4C80.3,14.1,61.9,31.9,57.9,55c-1,5.7-0.6,17,0.7,22.1c6,22.8,24.2,38.6,47.4,41.2c12.7,1.4,26.8-2.5,37.3-10.3c14.9-11,22.8-27.6,22.1-46.2c-0.6-13.6-5.8-25.7-15.3-35.4c-7.5-7.7-15.4-12.2-26.1-14.9C118.9,10.2,108.7,9.6,103.8,10.4z M123.4,26.5c13.1,4.3,22.9,14.8,26.4,28.2c1.3,5.1,1.4,13.3,0,18.7c-3.8,15.6-16.2,27.1-32.2,29.9c-7.1,1.3-16.2,0-22.9-3.2C83.6,94.8,76.1,85.9,72.9,74c-1.3-5-1.4-14.2-0.2-18.9c1.9-7.3,4.9-12.7,9.7-17.8c5.6-6,12-9.8,19.6-11.7c3-0.7,4.8-0.9,10.3-0.7C118.3,25.1,119.5,25.2,123.4,26.5z"
    }
  }), _c("path", {
    attrs: {
      fill: "#000000",
      d: "M100.3,137.7c-31.2,1.8-54.1,10.5-70.2,26.6c-10.5,10.5-16.9,22.9-19.4,37c-1.1,6.2-1,18.6,0.2,23.1c2.6,10.4,10.3,18,20.6,20.6c3.5,0.9,5.4,0.9,69,0.9h65.4l-3.4-2.7c-3.5-2.8-6.7-6.1-9.6-10l-1.6-2.2H93.6c-65.8,0-60.6,0.3-64.8-3.9c-3.4-3.5-3.9-5.2-3.9-14.9c0-5.2,0.2-9.1,0.7-11.2c5-23.3,24.4-40,53.7-46.1c11.2-2.4,16.5-2.8,32.2-2.8c18.5,0,28.1,1.2,41.1,5.1l5.2,1.6l2.9-2.6c1.6-1.4,4.5-3.7,6.4-5c2-1.3,3.4-2.5,3.2-2.7c-0.7-0.7-9.8-4.2-15-5.7C140.1,138.6,119.3,136.5,100.3,137.7z"
    }
  }), _c("path", {
    attrs: {
      fill: "#000000",
      d: "M191.9,152.5c-15.6,2.6-29.3,13-35.6,27.1c-3.9,8.7-5.1,19.3-3.3,28.3c3.1,14.7,11.8,26.3,25,33c23.2,11.7,51.3,2.3,63-21.1c12.3-24.4,0.7-54.4-24.7-64.5C209.2,152.5,198.9,151.3,191.9,152.5z M209.7,164.1c10.7,3.3,19.3,11.3,23.3,21.7c2,5.3,2.5,8.5,2.3,15c-0.4,9.7-3.6,16.9-10.6,23.9c-7,6.9-14.2,10.1-23.9,10.6c-6.5,0.2-9.7-0.2-15-2.3c-14.1-5.4-23.1-18.7-23.1-34c0-14.1,7.6-26.4,20.2-32.8C190.6,162.3,201.4,161.5,209.7,164.1z"
    }
  }), _c("path", {
    attrs: {
      fill: "#000000",
      d: "M191.8,182.6l-0.1,8.9l-8.9,0.1l-8.9,0.1v7.2v7.2l8.9,0.1l8.9,0.1l0.1,8.9l0.1,8.9h7.2h7.2v-9v-9h9h9V199v-7.2l-8.9-0.1l-8.9-0.1l-0.1-8.9l-0.1-8.9h-7.2h-7.2L191.8,182.6z"
    }
  })])])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };