import "core-js/modules/es.array.push.js";
// Services
import { mapState } from 'vuex';
import TableList from '@/components/tables/list/TableList';
import Main from '@/components/tables/partials/Main';

// Dialogs
import ActionDialog from '@/components/actions/ActionDialog';
import ActionHistory from '@/components/actions/ActionHistory';
import PatientsInfoSidePanel from '@/components/patients/PatientsInfoSidePanel';
import { patientViewMixin } from '@/mixins/patientView';
import { TAB_ID, HEADERS_BY_TAB_ID, TABS } from '@/constants/patient';
export default {
  components: {
    Main,
    TableList,
    // PatientInfoView,
    ActionDialog,
    ActionHistory,
    PatientsInfoSidePanel
  },
  mixins: [patientViewMixin],
  data() {
    return {
      showTableList: true,
      search: this.$route.query.search || '',
      tab: this.$route.query.tab || TAB_ID.ALL,
      refList: {
        all: 'AllTable',
        active: 'ActiveTable',
        inactive: 'InactiveTable',
        failedtransaction: 'FailedTable',
        needspatientreply: 'NeedsReplyTable',
        followupstart: 'FollowUpStartTable',
        followupcompletion: 'FollowUpCompleteTable',
        fullycancelled: 'FullyCancelledTable',
        onboarding: 'OnboardingTable',
        verificationrequired: 'VerificationRequiredTable',
        verificationrequireddoctor: 'VerificationRequiredDoctorTable',
        doctorrequest: 'DoctorRequestTable',
        refundrequest: 'RefundRequestTable',
        notaccepted: 'NotAcceptedTable',
        'cs-hold': 'OrderHoldsTable',
        'card-expired': 'CardExpiredTable',
        transferrx: 'TransferRxTable',
        failedaddress: 'AddressVerificationRequiredTable',
        voicemail: 'VoicemailTable',
        'no-follow-up': 'NoFollowUpTable',
        banned: 'BannedTable',
        'cancelled-by-stripe': 'CancelledByStripeTable',
        'prescription-end-before-followup': 'PrescriptionEndBeforeFollowupTable',
        'script-end-before-followup-no-dhea': 'ScriptEndBeforeFollowupNoDhea',
        'card-updated-without-prescription-restart': 'CardUpdatedWithoutPrescriptionRestartTable'
      },
      selectedRow: {
        id: ''
      },
      showInfoViewModal: false,
      toast: {
        message: '',
        variant: ''
      },
      // Generic alert component
      alert: {
        show: false,
        message: ''
      }
    };
  },
  computed: {
    ...mapState('patients', ['totalPatients', 'isFetchingPatients']),
    tabName() {
      const tab = TABS.flatMap(x => x.tabs).find(tab => tab.id === this.tab);
      return tab.text || 'All';
    },
    headers() {
      return HEADERS_BY_TAB_ID[this.tab];
    },
    options() {
      switch (this.tab) {
        case TAB_ID.FOLLOW_UP_COMPLETION:
        case TAB_ID.FOLLOW_UP_START:
          return {
            sort: 'desc',
            sortBy: 'followupdatetime'
          };
        case TAB_ID.NEEDS_PATIENT_REPLY:
          return {
            sort: 'desc',
            sortBy: 'needspatientreplydatetime'
          };
        case TAB_ID.PAYMENT_REFUND_REQUEST:
          return {
            sort: 'desc',
            sortBy: 'timestampcancelled'
          };
        default:
          return {
            sort: 'desc',
            sortBy: 'lastupdateactivity'
          };
      }
    }
  },
  watch: {
    '$route.query': {
      handler(query) {
        const {
          sortBy,
          sort
        } = query;
        const sortField = sortBy || this.options.sortBy;
        const sortDirection = sort ? sort === 'desc' ? 'DESC' : 'ASC' : this.options.sort.toUpperCase();
        this.getPatients({
          page: this.$route.query.page || 1,
          sortDirection,
          sortField
        });
      },
      immediate: true
    }
  },
  async mounted() {
    this.getStatusCount();
    this.loadSinglePatient();
  },
  methods: {
    async getPatients({
      page,
      sortDirection = 'DESC',
      sortField = 'lastupdateactivity'
    } = {}) {
      await this.$store.dispatch('patients/getPatients', {
        type: this.tab,
        search: this.search,
        page: page || this.$route.query.page || 1,
        sortDirection,
        sortField
      });
    },
    async onSort({
      sortBy,
      sortDesc
    }) {
      const sortField = sortBy === 'name' ? 'firstname' : sortBy;
      const sortDirection = sortDesc ? 'desc' : 'asc';
      this.$router.push({
        query: {
          ...this.$route.query,
          sortBy: sortField,
          sort: sortDirection,
          page: 1
        }
      });
    },
    async loadSinglePatient() {
      if (!this.$route.params.patientid) {
        return;
      }
      if (this.$route.name !== 'patientprofile') {
        return;
      }
      try {
        this.selectedRow.id = this.$route.params.patientid;
        await this.$nextTick();
        this.$refs.PatientsInfoSidePanel.show();
      } catch (err) {
        console.log(err);
        this.showAlert('A patient with this ID could not be found.');
      }
    },
    setTab(tab) {
      this.tab = tab;
      this.search = '';
      this.$router.push({
        query: {
          page: undefined,
          tab
        }
      });
      this.getStatusCount();
      this.alert.show = false;
    },
    showAlert(msg) {
      this.alert.show = true;
      this.alert.message = msg;
    },
    async getStatusCount() {
      await this.$store.dispatch('patients/getStatusCounts');
    },
    async viewInfo(row) {
      this.selectedRow = row;
      this.alert.show = false;
      this.showInfoViewModal = true;
      await this.$nextTick();
      this.$refs.PatientsInfoSidePanel.show();
    },
    showToast(message, variant = 'danger') {
      this.toast.message = message;
      this.toast.variant = variant;
      this.$bvToast.show('viewToast');
    },
    updateTime(id) {
      this.$refs[this.refList[this.tab]].updateTime(id);
    },
    clearSearch() {
      this.$refs.searchInput.focus();
      this.search = '';
      this.$router.push({
        query: {
          ...this.$route.query,
          page: 1,
          search: undefined
        }
      });
    }
  }
};