var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("svg", {
    staticStyle: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "2"
    },
    attrs: {
      width: "25",
      height: "25",
      viewBox: "0 0 35 35",
      version: "1.1",
      xmlns: "http://www.w3.org/2000/svg"
    }
  }, [_c("path", {
    staticStyle: {
      fill: "#fff"
    },
    attrs: {
      d: "M34.479,32.984l0.521,0.396l0,-30.415l-0.173,-0.291c-0.615,-1.737 -1.929,-2.674 -3.795,-2.674l-2.985,-0c-2.105,-0 -3.803,1.698 -3.803,3.804l-0,27.301c-0,2.105 1.698,3.803 3.803,3.803c1.063,0 2.126,0.028 3.189,0l0.001,0c1.457,-0.043 2.534,-0.706 3.242,-1.924Zm-34.478,-17.158l-0.001,0.099l0,15.152c0,2.137 1.694,3.822 3.831,3.822l3.076,0c2.149,0 3.84,-1.676 3.84,-3.813l0,-15.206c0,-2.095 -1.697,-3.795 -3.804,-3.795l-3.123,0c-2.104,0.012 -3.767,1.653 -3.819,3.741Zm22.867,15.275l0.001,-0.106l0,-21.103c0,-2.18 -1.672,-3.859 -3.84,-3.859l-3.078,0c-2.137,0.012 -3.829,1.697 -3.829,3.832l-0,21.203c-0,2.137 1.685,3.831 3.822,3.831l3.03,0c2.207,0 3.843,-1.599 3.894,-3.798Zm10.476,0.42l-0.062,0.141l0.004,0.003l-0.142,0.307c-0.409,0.805 -1.052,1.253 -1.953,1.281c-1.048,0.027 -2.096,-0.001 -3.144,-0.001c-1.188,0 -2.147,-0.959 -2.147,-2.147l-0,-27.301c-0,-1.189 0.959,-2.148 2.147,-2.148l2.985,0c1.108,0 1.884,0.563 2.249,1.61l0.063,0.143l-0,28.112Zm-31.687,-0.387l-0.001,-0.057l0,-15.152c0,-1.213 0.956,-2.177 2.17,-2.184c-0,0 3.117,0 3.117,0c1.189,0 2.148,0.957 2.148,2.139l0,15.206c0,1.212 -0.965,2.157 -2.184,2.157l-3.076,0c-1.193,0 -2.145,-0.924 -2.174,-2.109Zm19.555,-21.3l0.001,0.058l-0,21.103c-0,1.296 -0.949,2.248 -2.239,2.248l-3.03,0c-1.212,0 -2.166,-0.963 -2.166,-2.175l-0,-21.203c-0,-1.214 0.965,-2.168 2.178,-2.175c0,-0 3.073,-0 3.073,-0c1.216,-0 2.154,0.93 2.183,2.144Zm12.132,21.687l-0,0.004l-0.058,0.14l0.058,0.058l-0,-0.198l0.059,-0.141l-0.059,0.137Zm-12.131,-11.045c0.003,0.347 0.184,0.549 0.375,0.665c0.192,0.116 0.395,0.146 0.443,0.152l-0.818,-0.817Zm0.787,0.778c-0.083,-0.102 -0.279,-0.341 -0.455,-0.534c-0.176,-0.192 -0.332,-0.338 -0.332,-0.254l0.787,0.788Z"
    }
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };