var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "tw-p-4 tw-flex tw-items-center tw-border-0 tw-border-b tw-border-solid tw-border-slate-300 tw-h-[69px]"
  }, [_c("h1", {
    staticClass: "h1 tw-text-lg tw-black tw-uppercase tw-tracking-widest tw-mb-0"
  }, [_vm._v(" " + _vm._s(_vm.chatTitle) + " ")])]), _c("div", {
    directives: [{
      name: "on-scroll-to-bottom",
      rawName: "v-on-scroll-to-bottom",
      value: _vm.goToNextPage,
      expression: "goToNextPage"
    }],
    staticClass: "tw-overflow-y-auto",
    staticStyle: {
      height: "calc(100vh - 69px)"
    }
  }, [_vm._l(_vm.conversations, function (conversation) {
    return _c("ChatMessageListItem", {
      key: `${conversation.id}-${conversation.isOnline ? "online" : "offline"}'}`,
      staticClass: "tw-border-0 tw-border-b tw-border-solid tw-border-slate-300",
      attrs: {
        conversation: conversation,
        "is-selected": conversation.id === _vm.selectedConversationId
      },
      on: {
        select: _vm.onConversationSelect
      }
    });
  }), _vm.isFetchingConversations || _vm.isProcessing || !_vm.conversations.length ? _c("div", {
    staticClass: "tw-flex tw-items-center tw-justify-center tw-h-[100px] tw-gap-2 tw-text-gray-500"
  }, [_vm.isFetchingConversations || _vm.isProcessing ? [_vm._v(" Loading "), _c("IconLoading")] : !_vm.conversations.length ? [_vm._v(" Empty conversations ")] : _vm._e()], 2) : _vm._e()], 2)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };