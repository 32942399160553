// libs
import { mapState } from 'vuex';

// mixins
import { chatMixin } from '@/mixins/chat';
import { chatThread } from '@corefront/mixins-v2/chatThread';

// components
import ChatThread from '@corefront/components-v2/Chat/ChatThread';
export default {
  components: {
    ChatThread
  },
  mixins: [chatMixin, chatThread],
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('doctorMessageRouter', ['messages', 'isFetchingMessages', 'selectedConversation'])
  },
  mounted() {
    this.$_chatThread_scrollToBottom();
  }
};