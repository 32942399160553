// libs
import { mapState } from 'vuex';

// components
import ChatCategoryItem from './ChatCategoryItem.vue';

// constants
import { CATEGORY } from '@corefront/constant/messages';
export default {
  components: {
    ChatCategoryItem
  },
  computed: {
    ...mapState('chat', ['conversationCount', 'category']),
    categories() {
      return [{
        label: 'My Inbox',
        value: CATEGORY.ASSIGNED,
        unreadCount: this.conversationCount.assigned
      }, {
        label: 'All',
        value: CATEGORY.ALL,
        unreadCount: this.conversationCount.total
      }, {
        label: 'Unassigned',
        value: CATEGORY.OPEN,
        unreadCount: this.conversationCount.open
      }, {
        label: 'Closed',
        value: CATEGORY.DISMISSED,
        unreadCount: 0
      }];
    }
  },
  mounted() {
    this.$store.dispatch('chat/getMetrics');
  },
  methods: {
    onCategoryClick(categoryItem) {
      this.$store.commit('chat/SET_STATE', {
        category: categoryItem.value
      });
      this.$emit('category-update', categoryItem.value);
    }
  }
};