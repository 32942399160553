import { PatientService } from '@/services/patient.service';
import { PrescriptionService } from '@/services/prescription.service';
import { LookupService } from '@/services/lookup.service';
export default {
  props: {
    rowData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      showDialog: false,
      patientData: {},
      prescriptionData: [],
      buttonDisabled: false,
      alert: {
        show: false,
        message: ''
      },
      cancellationReasons: [],
      chosenReason: '',
      cancellationNotes: ''
    };
  },
  computed: {
    // failedprescriptions() {
    //   return this.prescriptionData.filter((o) => o.paymentstatus == 'FAILED');
    // },
    total() {
      let total = 0;
      for (const prescription of this.prescriptionData) {
        total += prescription.refundamount;
      }
      return total;
    }
  },
  watch: {
    async showDialog(show) {
      if (show) {
        try {
          this.buttonDisabled = true;
          const patientData = await PatientService.getPatient(this.rowData.id);
          this.patientData = patientData.data;
          const {
            data
          } = await PrescriptionService.getPrescriptionForCancellation(this.rowData.id);
          this.prescriptionData = data;
          this.buttonDisabled = false;
        } catch (err) {
          this.alert.show = true;
          this.alert.message = err;
          this.buttonDisabled = false;
        }
      } else {
        this.buttonDisabled = false;
        this.alert.show = false;
        this.patientData = {};
        this.prescriptionData = [];
        this.chosenReason = '';
        this.cancellationNotes = '';
      }
    }
  },
  async mounted() {
    const {
      data
    } = await LookupService.getCancelReason();
    this.cancellationReasons = data.map(o => ({
      value: o.id,
      text: o.name
    }));
  },
  methods: {
    getRefillString(med) {
      if (med.refills) {
        if (['SCHEDULED', 'WAITING', 'PROCESSING', 'MIGRATED', 'DRAFTED'].includes(med.prescriptionstatus)) {
          return `Refills: ${med.refillinput}`;
        }
        const refills = med.refills - 1 - (med.paidrefills == 0 ? 0 : med.paidrefills - 1);
        if (refills == 0 && med.refills - 1 == 0) {
          return 'One time purchase';
        } else {
          return `${refills}/${med.refills - 1} refills`;
        }
      } else {
        return '-';
      }
    },
    async cancelPatient() {
      try {
        this.buttonDisabled = true;
        if (!this.chosenReason || !this.cancellationNotes) {
          throw 'You must fill in all the fields.';
        }
        const reason = this.cancellationReasons.find(o => o.value == this.chosenReason);
        const dataToSubmit = {
          reason: reason.text,
          notes: this.cancellationNotes,
          refund: []
        };
        const {
          data
        } = await PatientService.cancelPatient(this.patientData.id, dataToSubmit);
        if (data) {
          this.$emit('delete-self', this.patientData.id);
          this.showDialog = false;
        }
      } catch (err) {
        this.alert.message = err;
        this.alert.show = true;
        this.buttonDisabled = false;
      }
    },
    getDate(date) {
      if (date == '-') {
        return '-';
      }
      return new Date(date * 1000).toDateString();
    }
  }
};