var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("svg", {
    attrs: {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 22.44 18.31",
      width: "18",
      height: "18",
      fill: "currentColor"
    }
  }, [_c("g", {
    attrs: {
      id: "Layer_1-2"
    }
  }, [_c("g", {
    attrs: {
      id: "jkgAR3.tif"
    }
  }, [_c("path", {
    staticClass: "cls-1",
    attrs: {
      d: "M0,8.91c.2-.47.56-.7,1.05-.81.77-.17,1.01-.5,1.01-1.3,0-1.28-.01-2.56,0-3.84C2.08,1.33,3.42,0,5.02,0c.6,0,1.02.34,1.07.88.06.57-.29,1-.9,1.12-.92.18-1.12.41-1.12,1.32,0,1.01-.02,2.03,0,3.04.02,1.02-.09,1.99-.85,2.79.72.79.87,1.72.85,2.71-.02,1.1,0,2.19,0,3.29,0,.73.3,1.05,1.02,1.15.68.09,1.05.52.99,1.13-.05.55-.49.89-1.13.88-1.53-.03-2.84-1.28-2.89-2.81-.04-1.3-.01-2.59-.02-3.89,0-.93-.2-1.2-1.11-1.42-.46-.11-.76-.37-.95-.79v-.5Z"
    }
  }), _c("path", {
    staticClass: "cls-1",
    attrs: {
      d: "M12.5,9.15c1.02,1.31,2.03,2.59,3.02,3.88.55.72.22,1.61-.62,1.7-.42.05-.73-.14-.98-.47-.88-1.14-1.77-2.28-2.7-3.47-.79,1.02-1.56,2.01-2.34,3-.14.18-.28.37-.43.55-.38.45-.99.53-1.42.19-.43-.34-.53-.95-.17-1.42.88-1.16,1.79-2.31,2.69-3.47.12-.16.24-.32.38-.5-.97-1.25-1.94-2.49-2.9-3.74-.14-.18-.28-.38-.34-.6-.13-.46.09-.93.5-1.14.44-.23.95-.12,1.29.31.83,1.06,1.66,2.12,2.48,3.18.08.1.17.2.27.33.89-1.15,1.77-2.28,2.65-3.4.44-.56,1.03-.7,1.51-.34.5.37.54,1,.09,1.58-.97,1.26-1.96,2.52-2.97,3.82Z"
    }
  }), _c("path", {
    staticClass: "cls-1",
    attrs: {
      d: "M19.21,9.16c-.71-.77-.87-1.68-.85-2.66.02-1.1,0-2.19,0-3.29,0-.77-.28-1.09-1.03-1.2-.69-.11-1.05-.52-.99-1.14.05-.57.5-.89,1.18-.87,1.48.05,2.77,1.28,2.83,2.77.05,1.31.02,2.63.03,3.94,0,.92.2,1.18,1.07,1.4.64.17.97.51.98,1.03.01.51-.32.87-.95,1.04-.91.25-1.1.49-1.1,1.44,0,1.28.02,2.56-.02,3.84-.04,1.56-1.36,2.82-2.9,2.85-.64.01-1.06-.33-1.11-.89-.05-.6.31-1.02.95-1.12.8-.12,1.06-.42,1.07-1.21,0-1.06.02-2.13,0-3.19-.02-1.01.1-1.96.85-2.74Z"
    }
  })])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };