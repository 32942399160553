import moment from 'moment';
import { PatientService } from '@/services/patient.service';
export default {
  props: {
    rowData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      alert: {
        show: false,
        message: ''
      },
      showDialog: false,
      selectedDoctor: ''
    };
  },
  watch: {
    showDialog(show) {
      if (!show) {
        this.selectedDoctor = '';
        this.alert.show = false;
      }
    }
  },
  methods: {
    getDate(date) {
      return moment(date * 1000).format('MM/DD/YYYY LT');
    },
    async assignDoctor() {
      if (!this.selectedDoctor) {
        this.alert.message = 'Please select a doctor.';
        this.alert.show = true;
        return;
      }
      try {
        const body = {
          doctorid: this.selectedDoctor
        };
        const {
          data
        } = await PatientService.assignPatient(this.rowData.id, body);
        if (data) {
          this.$emit('delete-self', this.rowData.id);
          this.showDialog = false;
        }
      } catch (err) {
        this.alert.message = err;
        this.alert.show = true;
      }
    }
  }
};