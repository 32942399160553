import { AirtableService } from '@/services/airtable.service';
import { format } from 'date-fns';
export default {
  props: {
    order: {
      type: Object,
      default: () => ({})
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      viewAirtable: false,
      isButtonDisabled: false
    };
  },
  mounted() {
    if (this.index === 0) {
      this.viewAirtable = true;
    }
  },
  methods: {
    parseDate(date, dateFormat) {
      if (date) {
        return format(new Date(date), dateFormat);
      }
    },
    async onAddressRemove() {
      try {
        await AirtableService.removeAddress(this.order['Prescription ID']);
        this.$emit('refresh-self', this.order['Prescription ID']);
      } catch (e) {
        console.error(e);
      }
    },
    async onShippingRemove() {
      try {
        await AirtableService.removeShipping(this.order['Prescription ID']);
        this.$emit('refresh-self', this.order['Prescription ID']);
      } catch (e) {
        console.error(e);
      }
    },
    async onHoldOrder() {
      try {
        console.log(this.order['Prescription ID']);
        this.isButtonDisabled = true;
        await AirtableService.holdOrder(this.order['Prescription ID']);
        setTimeout(() => {
          this.$emit('refresh-self', this.order['Prescription ID'], true);
          this.isButtonDisabled = false;
        }, 5000);
      } catch (e) {
        console.error(e);
      }
    },
    async onLiftOrder() {
      try {
        this.isButtonDisabled = true;
        await AirtableService.liftOrder(this.order['Prescription ID']);
        setTimeout(() => {
          this.$emit('refresh-self', this.order['Prescription ID'], true);
          this.isButtonDisabled = false;
        }, 5000);
      } catch (e) {
        console.error(e);
      }
    }
  }
};