var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.conversation ? _c("div", {
    staticClass: "tw-h-screen tw-flex tw-flex-col"
  }, [_c("ChatMacroDialog", {
    on: {
      "open-edit": function ($event) {
        _vm.showMacroEdit = true;
      }
    }
  }), _c("div", {
    staticClass: "tw-p-4 tw-flex tw-justify-between tw-items-center tw-border-0 tw-border-b tw-border-solid tw-border-slate-300"
  }, [_c("div", {
    staticClass: "tw-flex tw-items-center tw-gap-2"
  }, [_c("div", {
    staticClass: "tw-relative"
  }, [_c("b-avatar", {
    attrs: {
      variant: "tertiary",
      size: "35",
      src: _vm.conversation.patient.profileUrl
    }
  }), _vm.onlineStatus ? _c("svg", {
    staticClass: "tw-absolute -tw-right-0.5 -tw-top-0.5",
    attrs: {
      xmlns: "http://www.w3.org/2000/svg",
      width: "15",
      height: "15",
      viewBox: "0 0 24 24"
    }
  }, [_c("path", {
    attrs: {
      fill: "#33926A",
      d: "M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2Z"
    }
  })]) : _vm._e()], 1), _c("div", [_c("div", {
    attrs: {
      role: "button"
    },
    on: {
      click: _vm.openPatientInfoSlidePanel
    }
  }, [_c("p", {
    staticClass: "tw-m-0 tw-text-sm hover:tw-text-secondary"
  }, [_vm._v(" " + _vm._s(_vm.conversation.patient.firstName) + " " + _vm._s(_vm.conversation.patient.lastName) + " ")])]), _c("p", {
    staticClass: "tw-m-0 tw-text-slate-400 tw-text-xs"
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.conversation.patient.birthday, "MM/dd/yy")) + " ")])])]), _c("div", {
    staticClass: "tw-flex tw-gap-2 tw-max-h-[38px]"
  }, [!_vm.isDismissed ? _c("CoreTooltip", {
    attrs: {
      text: _vm.assignee ? `Assigned to ${_vm.assignee}` : "Assign to myself",
      placement: "bottom"
    }
  }, [_c("BaseButton", {
    staticClass: "tw-uppercase tw-h-inherit",
    attrs: {
      outline: "",
      loading: _vm.isProcessingAssignment
    },
    on: {
      click: function ($event) {
        return _vm.assignConversation(_vm.conversation.id, _vm.assignee ? false : true);
      }
    }
  }, [_c("div", {
    staticClass: "tw-flex tw-items-center tw-w-full tw-gap-2"
  }, [_c("div", {
    staticClass: "tw-size-[16px]"
  }, [_vm.assignee ? _c("IconUnassign") : _c("IconAssign")], 1), _c("span", {
    staticClass: "tw-text-[13px] tw-leading-none",
    class: _vm.isApple ? "tw-h-[14px]" : "tw-h-[9.5px]"
  }, [_vm._v(_vm._s(_vm.assignee ? "Unassign" : "Assign"))])])])], 1) : _vm._e(), _c("BaseButton", {
    staticClass: "tw-uppercase tw-w-[130px] tw-h-inherit",
    attrs: {
      outline: ""
    },
    on: {
      click: _vm.openActionDialog
    }
  }, [_c("div", {
    staticClass: "tw-flex tw-items-center tw-justify-between tw-gap-2"
  }, [_c("b-icon", {
    staticClass: "tw-size-[16px]",
    attrs: {
      icon: "pencil"
    }
  }), _c("span", {
    staticClass: "tw-text-[13px] tw-block tw-leading-none",
    class: _vm.isApple ? "tw-h-[14px]" : "tw-h-[9.5px]"
  }, [_vm._v("Action")])], 1)]), _c("BaseButton", {
    staticClass: "tw-uppercase tw-flex-1 tw-h-inherit",
    attrs: {
      outline: ""
    },
    on: {
      click: function ($event) {
        _vm.isDismissed ? _vm.onShowReopenModal() : _vm.onShowDismissModal();
      }
    }
  }, [_c("div", {
    staticClass: "tw-flex tw-items-center tw-gap-2 tw-w-full"
  }, [_c("div", {
    staticClass: "tw-size-[16px]"
  }, [_vm.isDismissed ? _c("IconFolderOutline") : _c("IconFolderFilled")], 1), _c("span", {
    staticClass: "tw-text-[13px] tw-text-nowrap tw-leading-none",
    class: _vm.isApple ? "tw-h-[14px]" : "tw-h-[9.5px]"
  }, [_vm._v(_vm._s(_vm.isDismissed ? "Re-open" : "Close"))])])])], 1)]), _c("div", {
    staticClass: "tw-px-6 tw-py-2 tw-flex tw-justify-between tw-border-0 tw-border-b tw-border-solid tw-border-slate-300"
  }, [_c("div", {
    staticClass: "tw-cursor-pointer hover:tw-underline",
    on: {
      click: function ($event) {
        _vm.isEventsHidden = !_vm.isEventsHidden;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.isEventsHidden ? "Show events" : "Hide events") + " ")])]), _c("ChatMessagesContainer", {
    key: _vm.conversation.id,
    ref: "ChatMessagesContainer",
    staticClass: "tw-flex-1 tw-overflow-y-auto",
    attrs: {
      "is-messages-loaded": _vm.isMessagesLoaded,
      "is-recipient-typing": _vm.isRecipientTyping,
      "is-events-hidden": _vm.isEventsHidden,
      "patient-name": _vm.patientName
    }
  }), _c("div", {
    staticClass: "tw-relative"
  }, [_vm.isRecipientTyping && _vm.isMessagesLoaded ? _c("div", {
    staticClass: "tw-absolute tw-left-6 tw-top-0.5"
  }, [_c("p", {
    staticClass: "tw-text-muted tw-text-sm"
  }, [_vm._v(" " + _vm._s(_vm.stringifyTypers) + " " + _vm._s(_vm.typersList.length > 1 ? "are" : "is") + " typing... ")])]) : _vm._e(), _c("ChatMessageInputArea", {
    attrs: {
      socket: _vm.socket,
      conversation: _vm.conversation
    },
    on: {
      "assign-to-self": function ($event) {
        return _vm.assignConversation(_vm.conversation.id, true);
      },
      input: _vm.onType,
      message: _vm.onMessageSent,
      messageCategoryChanged: _vm.handleMessageCategoryChanged
    }
  })], 1), _c("ChatDismissModal", {
    ref: "ChatDismissModal",
    on: {
      dismiss: _vm.dismissConversation
    }
  }), _c("ChatReopenModal", {
    ref: "ChatReopenModal",
    on: {
      reopen: _vm.reopenConversation
    }
  }), _c("ChatMacroEditModal", {
    ref: "ChatMacroEditModal",
    attrs: {
      show: _vm.showMacroEdit
    },
    on: {
      "update:show": function ($event) {
        _vm.showMacroEdit = $event;
      }
    }
  }), _c("div", {
    staticClass: "background",
    style: {
      "background-color": _vm.bgModal.color,
      display: _vm.bgModal.show
    },
    on: {
      click: function ($event) {
        _vm.showMacroEdit = false;
      }
    }
  }), _vm.patientId ? _c("PatientsInfoSidePanel", {
    ref: "PatientsInfoSidePanel",
    attrs: {
      "patient-id": _vm.patientId,
      "update-url": false
    }
  }) : _vm._e(), _vm.patientData ? _c("div", [_c("ActionsActionDialog", {
    ref: "ActionsActionDialog",
    attrs: {
      "row-data": _vm.patientData,
      "action-id": _vm.tab
    },
    on: {
      "show-toast": _vm.showToast
    }
  }), _c("b-toast", {
    attrs: {
      id: "dialogToast",
      variant: _vm.toast.variant,
      "no-close-button": "",
      "auto-hide-delay": "2500",
      toaster: "b-toaster-top-center",
      solid: ""
    }
  }, [_vm._v(" " + _vm._s(_vm.toast.message) + " ")])], 1) : _vm._e()], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };