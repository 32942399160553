import { PatientService } from '@/services/patient.service';
export default {
  props: {
    rowData: {
      type: Object,
      default() {
        return {
          firstname: '',
          lastname: ''
        };
      }
    }
  },
  data() {
    return {
      showDialog: false
    };
  },
  methods: {
    async restartIntake() {
      try {
        const {
          data
        } = await PatientService.restartIntake(this.rowData.id);
        if (data) {
          this.$emit('show-toast', 'Onboarding reset successfully.', 'success');
          this.showDialog = false;
        }
      } catch (error) {
        this.$emit('show-toast', error);
      }
    }
  }
};