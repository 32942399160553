/**
import { failedMessagesMixin } from "@/mixins/failedMessages";
 */

// libs
import Vue from 'vue'
import { mapState } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

// utils
import { prefixMethods } from '@/utils/prefixMethods'
import { now } from '@corefront/utils/now';
import { sleep } from '@corefront/utils/sleep';

// constants
import { MESSAGE_ACTION, MESSAGE_TYPE, TYPES, MESSAGE_SEND_STATUS, FAILED_MESSAGE_TYPE } from '@corefront/constant/messages'
import { EVENTS } from '@corefront/constant/socket'
const PREFIX = '$_failedMessages_';

// utils
import messageSocket from '@corefront/utils/message-socket';

// mixins
import { chatThread } from '@corefront/mixins-v2/chatThread';

// assets
import audio from '@/assets/notifications/pristine.mp3';

// audio
const notificationSound = new Audio(audio);

const data = Vue.observable({
  failedMessagesInterval: null
})

const FAILED_MESSAGES_KEY = {
  ADMIN_PATIENT_CHAT_MESSAGE: userId => `failed-messages:admin-patient-chat-messages-${userId}`,
}

function transformFilesForEntry (files) {
  if (!files) {
    return []
  }

  return files.map(file => {
    return {
      name: file.name,
      size: file.size,
      type: file.type,
      file,
    }
  });
}

function createAdminMessageEntry (message) {
  const messageClone = { ...message }

  if (messageClone.files && messageClone.files.length) {
    messageClone.attachment = transformFilesForEntry(messageClone.files)

    delete messageClone.files
  }

  return {
    id: messageClone.id,
    userid: this.currentUser.id,
    message: messageClone.message,
    conversationId: messageClone.conversationId,
    sentat: messageClone.sentat || now(),
    attachment: messageClone.attachment || [],
    type: 'text',
    sentStatus: MESSAGE_SEND_STATUS.PENDING
  }
}

function createAdminChatEntry (message) {
  const attachments = transformFilesForEntry(message.files) || []

  delete message.files

  return {
    id: message.id,
    type: TYPES.MESSAGE,
    cursor: null,
    data: {
      ...message,
      sentAt: message.sentAt || now(),
      attachments
    },
    sentStatus: MESSAGE_SEND_STATUS.PENDING
  }
}

export const failedMessagesMixin = {
  mixins: [chatThread],

  computed: {
    ...mapState('chat', {
      chatMessages: 'messages',
    }),
    ...mapState('message', {
      messages: 'messages',
    }),
    ...mapState('auth', ['currentUser']),
  },

  methods: prefixMethods({
    getLocalFailedMessages () {
      const key = FAILED_MESSAGES_KEY.ADMIN_PATIENT_CHAT_MESSAGE(this.currentUser.id)

      return JSON.parse(localStorage.getItem(key) || '[]')
    },

    setLocalFailedMessages (messages) {
      const key = FAILED_MESSAGES_KEY.ADMIN_PATIENT_CHAT_MESSAGE(this.currentUser.id)

      localStorage.setItem(key, JSON.stringify(messages))
    },
    async pollSaveFailedMessages () {
      console.log('Start polling post/failed-messages')

      const callback = async () => {
        const failedMessages = this.$_failedMessages_getLocalFailedMessages()

        const filteredFailedMessages = await Promise.all(
          failedMessages.map(async failedMessage => {
            try {
              const { message, messageType } = failedMessage

              await this.$store.dispatch('failedMessages/store', {
                type: messageType,
                payload: message
              })
            } catch (error) {
              return failedMessage
            }
          })
        )

        this.$_failedMessages_setLocalFailedMessages(filteredFailedMessages.filter(x => !!x))
      }

      if (data.failedMessagesInterval) {
        return
      }

      callback()
      data.failedMessagesInterval = setInterval(callback, 5000)
    },

    onSendFail ({ messageType, message }) {
      const failedMessages = this.$_failedMessages_getLocalFailedMessages()

      const messageExists = failedMessages.find(failedMessage => failedMessage.id === message.id)

      if (messageExists) {
        return
      }

      failedMessages.push({
        messageType,
        message
      })

      this.$_failedMessages_setLocalFailedMessages(failedMessages)
    },

    resolveFailedMessage (id) {
      this.$store.dispatch('failedMessages/resolveByMessageId', id);

      const failedMessages = this.$_failedMessages_getLocalFailedMessages()

      const filteredFailedMessages = failedMessages.filter(failedMessage => failedMessage.id !== id)

      this.$_failedMessages_setLocalFailedMessages(filteredFailedMessages)
    },

    async sendMessage ({
      message,
      isRetry = false,
      sendEvent,
      messageType,
      commitDeleteMessageById,
      commitAppendEntryToThread,
      commitUpdateMessage,
      commitUpdateConversation,
    }) {
      const messageClone = { ...message };

      try {
        if (isRetry) {
          commitDeleteMessageById()
        }

        commitAppendEntryToThread()

        commitUpdateMessage({
          id: message.id,
          sentStatus: MESSAGE_SEND_STATUS.PENDING
        })

        this.$_chatThread_scrollToBottom();

        const response = await messageSocket.send(sendEvent, {
          ...messageClone,
          id: isRetry
            ? uuidv4()
            : messageClone.id,
        });

        if (isRetry) {
          this.$_failedMessages_resolveFailedMessage(message.id)
          this.$store.dispatch('failedMessages/resolveByMessageId', message.id);
        }

        commitUpdateMessage({
          id: message.id,
          sentStatus: MESSAGE_SEND_STATUS.SENT
        })

        commitUpdateConversation(response)
      } catch (error) {
        if (isRetry) {
          await sleep(1000);
        }

        commitUpdateMessage({
          id: message.id,
          sentStatus: MESSAGE_SEND_STATUS.FAILED
        })

        this.$_chatThread_scrollToBottom();

        this.$_failedMessages_onSendFail({
          messageType,
          message: messageClone
        })

        if (isRetry) {
          // Add delay to show pending status
          await sleep(1000);
        }

        if (!isRetry) {
          for (let i = 0; i < 2; i++) {
            await sleep(1000);

            try {
              console.log('Retrying message send...')

              await this.$_failedMessages_sendMessage({
                ...arguments[0],
                isRetry: true
              })

              break
            } catch (error) {
            // Ignore error
            }
          }
        }

        throw error;
      }
    },

    async sendAdminPatientChatMessage ({ message, isRetry = false }) {
      await this.$_failedMessages_sendMessage({
        message,
        isRetry,
        sendEvent: EVENTS.ADMIN_CHAT_NEW_MESSAGE,
        messageType: FAILED_MESSAGE_TYPE.ADMIN_PATIENT_CHAT_MESSAGE,
        commitDeleteMessageById: () => {
          this.$store.commit('chat/DELETE_MESSAGE_BY_ID', message.id)
        },
        commitAppendEntryToThread: () => {
          this.$store.commit('chat/APPEND_MESSAGE', createAdminChatEntry({ ...message }))
        },
        commitUpdateMessage: properties => {
          this.$store.commit('chat/UPDATE_MESSAGE_BY_ID', properties)
        },
        commitUpdateConversation: response => {
          this.$store.commit('chat/UPDATE_CONVERSATION_BY_ID', {
            id: response.conversationId,
            sender: response.sender,
            lastMessage: response.message,
            lastMessageSenderRole: response.senderRole,
            lastMessageActivity: response.sentAt,
            lastMessageType: response.type,
          });
        }
      })
    },

    async sendAdminMessage ({ message, isRetry = false }) {
      const messageClone = { ...message }

      try {
        if (isRetry) {
          this.$store.commit('message/REMOVE_MESSAGE_BY_ID', message.id)
        }

        const entry = createAdminMessageEntry.call(this, message)

        this.$store.commit('message/APPEND_MESSAGE', entry);

        this.$_chatThread_scrollToBottom()

        messageClone.attachment = entry.attachment

        delete messageClone.files

        const response = await messageSocket.send(
          EVENTS.ADMIN_MESSAGE_NEW_MESSAGE,
          {
            ...messageClone,
            id: isRetry
              ? uuidv4()
              : messageClone.id,
          }
        )

        if (isRetry) {
          this.$store.dispatch('failedMessages/resolveByMessageId', message.id)
        }

        this.$store.commit('message/UPDATE_MESSAGE_BY_ID', {
          id: entry.id,
          sentStatus: MESSAGE_SEND_STATUS.SENT
        })

        if (response.action === MESSAGE_ACTION.SENT) {
          this.$store.commit('message/UPDATE_CONVERSATION_BY_ID', {
            id: messageClone.conversationid,
            lastmessage: messageClone.message,
            unreadcount: false,
          });
        } else if (message.action === MESSAGE_ACTION.UPDATED) {
          const msgToUpdate = this.messages.find(x => x.id === response.id);

          if (!msgToUpdate) {
            return
          }

          if (msgToUpdate.type === MESSAGE_TYPE.PRESCRIPTION_CONFIRMATION) {
            notificationSound.play();
            this.$store.commit('message/UPDATE_MESSAGE_BY_ID', {
              id: response.id,
              actionstatus: response.actionstatus,
            })
          }

          if (msgToUpdate.type === MESSAGE_TYPE.PRESCRIPTION_FOLLOWUP) {
            notificationSound.play();
            this.$store.commit('message/UPDATE_MESSAGE_BY_ID', {
              id: response.id,
              actionstatus: response.actionstatus,
              custommessage: response.custommessage
            })
          }
        }
      } catch (error) {
        this.$store.commit('message/UPDATE_MESSAGE_BY_ID', {
          id: messageClone.id,
          sentStatus: MESSAGE_SEND_STATUS.FAILED
        })

        if (!isRetry) {
          this.$_failedMessages_onSendFail({
            messageType: FAILED_MESSAGE_TYPE.ADMIN_MESSAGE,
            message: messageClone
          })
        }

        throw error
      } finally {
        this.$_chatThread_scrollToBottom()
      }
    },

    async loadAdminMessageFailedMessages (conversationId) {
      const messages = this.$store.state.message.messages
      const messageIds = new Set(messages.map(m => m.id))

      const failedMessages = await this.$store.dispatch('failedMessages/get', {
        conversationId,
        failedMessageType: FAILED_MESSAGE_TYPE.ADMIN_MESSAGE
      })

      const entries = failedMessages
        .filter(message => !messageIds.has(message.payload.id))
        .map(message => ({
          ...createAdminMessageEntry.call(this, message.payload),
          sentat: now(),
          sentStatus: MESSAGE_SEND_STATUS.FAILED
        }))

      failedMessages
        .filter(x => messageIds.has(x.payload.id))
        .forEach(failedMessage => {
          this.$store.dispatch('failedMessages/resolveByMessageId', failedMessage.payload.id)
        })

      this.$store.commit('message/SET_STATE', {
        state: 'messages',
        value: [...entries, ...messages]
      })
    },

    async loadAdminChatFailedMessages (conversationId) {
      const messages = this.chatMessages
      const messageIds = new Set(messages.map(m => m.id))

      const failedMessages = await this.$store.dispatch('failedMessages/get', {
        conversationId,
        failedMessageType: FAILED_MESSAGE_TYPE.ADMIN_PATIENT_CHAT_MESSAGE
      })

      const entries = failedMessages
        .filter(message => !messageIds.has(message.payload.id))
        .map(message => ({
          ...createAdminChatEntry({
            ...message.payload,
            sentAt: now(),
          }),
          sentStatus: MESSAGE_SEND_STATUS.FAILED
        }))

      failedMessages
        .filter(x => messageIds.has(x.payload.id))
        .forEach(failedMessage => {
          this.$store.dispatch('failedMessages/resolveByMessageId', failedMessage.payload.id)
        })

      this.$store.commit('chat/APPEND_MESSAGE', entries)
    },

  }, PREFIX)
};