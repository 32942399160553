var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    attrs: {
      "hide-header": "",
      "hide-footer": "",
      centered: "",
      "no-close-on-backdrop": "",
      "no-stacking": "",
      size: "lg"
    },
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c("b-row", {
    staticClass: "mt-4"
  }, [_c("b-col", {
    attrs: {
      cols: "2"
    }
  }, [_vm._v("Refund Request")]), _c("b-col", [_c("b-row", [_c("b-col", [_c("b", [_vm._v("Patient")]), _c("br"), _c("p", [_vm._v(_vm._s(_vm.rowData.firstname) + " " + _vm._s(_vm.rowData.lastname))])]), _c("b-col", [_c("b", [_vm._v("Doctor")]), _c("br"), _c("p", [_vm._v("Dr. " + _vm._s(_vm.rowData.doctorname))])])], 1), _c("b-row", [_c("b-col", [_c("b", [_vm._v("Prescription")]), _c("br"), _c("p", [_vm._v(_vm._s(_vm.rowData.medicinename))])])], 1), _c("b-row", [_c("b-col", [_c("b", [_vm._v("Reason")]), _c("br"), _c("p", [_vm._v(_vm._s(_vm.rowData.cancellationreason))])])], 1), _c("b-row", [_c("b-col", [_c("b", [_vm._v("Notes")]), _c("br"), _c("p", [_vm._v(_vm._s(_vm.rowData.cancellationnotes))])])], 1)], 1)], 1), _c("b-row", {
    staticClass: "mt-4"
  }, [_c("b-col", {
    attrs: {
      offset: "8"
    }
  }, [_c("b-button", {
    attrs: {
      variant: "primary",
      pill: "",
      block: ""
    },
    on: {
      click: function ($event) {
        _vm.showDialog = false;
      }
    }
  }, [_vm._v(" Ok ")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };