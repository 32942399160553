import CoreTooltip from '@corefront/components-v2/Core/CoreTooltip';
import CoreRealTimeClock from '@corefront/components-v2/Core/CoreRealTimeClock.vue';
import moment from 'moment';
export default {
  components: {
    CoreTooltip,
    CoreRealTimeClock
  },
  props: {
    row: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    items() {
      const {
        item
      } = this.row;
      return [{
        label: 'Patient Name',
        value: item.firstname && item.lastname ? `${item.firstname} ${item.lastname}` : '(No name)'
      }, {
        label: 'State',
        value: item.state
      }, {
        label: 'Local Time',
        key: 'localTime'
      }, {
        label: 'Account Created',
        value: moment(item.signupdate * 1000).fromNow()
      }, {
        label: 'Onboarding Symptoms',
        value: this.symptoms
      }];
    },
    symptoms() {
      const symptomNames = this.row.item.symptomNames || [];
      return symptomNames.join(', ');
    }
  }
};