var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("CoreTooltip", {
    attrs: {
      placement: "left",
      color: "white"
    },
    scopedSlots: _vm._u([{
      key: "popper",
      fn: function () {
        return [_c("div", [_c("h2", {
          staticClass: "tw-text-black tw-text-lg tw-m-0 tw-mb-2"
        }, [_vm._v(" Patient Information ")]), _c("div", {
          staticClass: "tw-flex tw-flex-col tw-gap-2 tw-text-[13px]"
        }, _vm._l(_vm.items, function (item, itemKey) {
          return _c("div", {
            key: itemKey
          }, [_c("label", {
            staticClass: "tw-block tw-m-0 tw-text-black/70"
          }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c("span", {
            staticClass: "tw-text-black"
          }, [item.key === "localTime" ? [_c("CoreRealTimeClock", {
            attrs: {
              timezone: _vm.row.item.timezone || "US/Eastern",
              format: "MMMM DD, YYYY hh:mm:ssA"
            }
          })] : [_vm._v(" " + _vm._s(item.value) + " ")]], 2)]);
        }), 0)])];
      },
      proxy: true
    }])
  }, [_c("b-avatar", {
    staticClass: "tw-mr-4",
    attrs: {
      src: _vm.row.item.avatar
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };