var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("svg", {
    attrs: {
      version: "1.1",
      xmlns: "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      x: "0px",
      y: "0px",
      viewBox: "0 0 256 256",
      "enable-background": "new 0 0 256 256",
      "xml:space": "preserve"
    }
  }, [_c("metadata", [_vm._v(" Svg Vector Icons : http://www.onlinewebfonts.com/icon ")]), _c("g", [_c("g", [_c("g", [_c("path", {
    attrs: {
      fill: "#000000",
      d: "M103.8,10.4C80.3,14.1,61.9,31.9,57.9,55c-1,5.7-0.6,17,0.7,22.1c6,22.8,24.2,38.6,47.4,41.2c12.7,1.4,26.8-2.5,37.3-10.3c14.9-11,22.8-27.6,22.1-46.2c-0.6-13.6-5.8-25.7-15.3-35.4c-7.5-7.7-15.4-12.2-26.1-14.9C118.9,10.2,108.7,9.6,103.8,10.4z"
    }
  }), _c("path", {
    attrs: {
      fill: "#000000",
      d: "M100.3,137.7c-31.2,1.8-54.1,10.5-70.2,26.6c-10.5,10.5-16.9,22.9-19.4,37c-1.1,6.2-1,18.6,0.2,23.1c2.6,10.4,10.3,18,20.6,20.6c3.5,0.9,5.4,0.9,70.6,0.9h67.1l-3.7-2.8c-11.3-8.5-19.1-21.4-21.4-35.6c-0.8-4.6-0.6-14,0.2-18.7c0.8-4.4,3-10.8,4.8-14.4c4.2-8.3,11.9-17,19.4-21.8l4.3-2.8l-3.2-1.5c-11.1-5.1-24-8.5-38.5-10C122.6,137.5,108.8,137.1,100.3,137.7z"
    }
  }), _c("path", {
    attrs: {
      fill: "#000000",
      d: "M191.9,152.5c-15.6,2.6-29.3,13-35.6,27.1c-3.9,8.7-5.1,19.3-3.3,28.3c3.1,14.7,11.8,26.3,25,33c23.2,11.7,51.3,2.3,63-21.1c12.3-24.4,0.7-54.4-24.7-64.5C209.2,152.5,198.9,151.3,191.9,152.5z M229.4,199v9l-30.4-0.1l-30.5-0.1l-0.1-8.3c0-4.5,0-8.6,0.1-8.9c0.2-0.5,6.3-0.6,30.6-0.6h30.3V199z"
    }
  })])])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };