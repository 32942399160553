var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("b-row", {
    staticClass: "patient-history tw-py-2",
    attrs: {
      id: "patient-history-action-bar",
      "align-h": "between"
    }
  }, [_c("b-col", {
    attrs: {
      cols: "2"
    }
  }, [_c("a", {
    staticClass: "text-primary",
    attrs: {
      href: "javascript:;"
    },
    on: {
      click: _vm.toggleEvents
    }
  }, [_vm.eventsHidden ? _c("span", [_vm._v("Show Events")]) : _c("span", [_vm._v("Hide Events")])])]), _c("b-col", {
    attrs: {
      cols: "4"
    }
  }, [_c("b-row", [_c("b-col", {
    attrs: {
      cols: "6"
    }
  }, [_c("b-dropdown", {
    ref: "dropdown",
    attrs: {
      id: "dropdown-link",
      text: "Filter",
      variant: "link"
    },
    on: {
      hide: function ($event) {
        return _vm.handleHide($event);
      },
      hidden: function ($event) {
        _vm.closeable = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c("b-dropdown-form", {
          staticStyle: {
            width: "250px"
          }
        }, [_c("b-row", [_c("b-col", [_c("b-form-group", [_c("b-form-radio", {
          attrs: {
            value: "ASC"
          },
          model: {
            value: _vm.sortBy,
            callback: function ($$v) {
              _vm.sortBy = $$v;
            },
            expression: "sortBy"
          }
        }, [_vm._v(" Ascending ")]), _c("b-form-radio", {
          staticClass: "mt-2",
          attrs: {
            value: "DESC"
          },
          model: {
            value: _vm.sortBy,
            callback: function ($$v) {
              _vm.sortBy = $$v;
            },
            expression: "sortBy"
          }
        }, [_vm._v(" Descending ")])], 1), _c("b-dropdown-divider"), _vm.selectedFilter.length == 0 ? _c("a", {
          staticClass: "text-primary",
          attrs: {
            href: "javascript:;"
          },
          on: {
            click: _vm.selectAllFilters
          }
        }, [_vm._v(" Select All ")]) : _c("a", {
          staticClass: "text-primary",
          attrs: {
            href: "javascript:;"
          },
          on: {
            click: _vm.clearFilters
          }
        }, [_vm._v(" Clear ")]), _c("b-form-group", [_c("b-form-checkbox-group", {
          attrs: {
            stacked: ""
          },
          model: {
            value: _vm.selectedFilter,
            callback: function ($$v) {
              _vm.selectedFilter = $$v;
            },
            expression: "selectedFilter"
          }
        }, _vm._l(Object.keys(_vm.pillIds), function (filter) {
          return _c("b-form-checkbox", {
            key: filter,
            attrs: {
              value: filter
            }
          }, [_vm._v(" " + _vm._s(filter) + " ")]);
        }), 1)], 1), _c("b-dropdown-divider"), _c("b-form-group", {
          attrs: {
            label: "From"
          }
        }, [_c("b-form-datepicker", {
          attrs: {
            size: "sm",
            max: _vm.maxFrom
          },
          model: {
            value: _vm.filterFrom,
            callback: function ($$v) {
              _vm.filterFrom = $$v;
            },
            expression: "filterFrom"
          }
        })], 1), _c("b-form-group", {
          attrs: {
            label: "To"
          }
        }, [_c("b-form-datepicker", {
          attrs: {
            min: _vm.filterFrom,
            max: _vm.maxDate,
            size: "sm"
          },
          model: {
            value: _vm.filterTo,
            callback: function ($$v) {
              _vm.filterTo = $$v;
            },
            expression: "filterTo"
          }
        })], 1), _c("a", {
          staticClass: "text-primary",
          attrs: {
            href: "javascript:;"
          },
          on: {
            click: _vm.clearDateFilters
          }
        }, [_vm._v(" Clear Date Filters ")]), _c("b-row", {
          staticClass: "mt-3"
        }, [_c("b-col", {
          attrs: {
            cols: "12"
          }
        }, [_c("b-button", {
          attrs: {
            variant: "secondary",
            pill: "",
            block: ""
          },
          on: {
            click: _vm.setOptions
          }
        }, [_vm._v(" Filter ")])], 1), _c("b-col", {
          attrs: {
            cols: "12"
          }
        }, [_c("b-button", {
          staticClass: "mt-2",
          attrs: {
            variant: "outline-dark",
            pill: "",
            block: ""
          },
          on: {
            click: _vm.close
          }
        }, [_vm._v(" Close ")])], 1)], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c("b-col", {
    attrs: {
      cols: "6"
    }
  }, [_c("b-button", {
    attrs: {
      variant: "link",
      block: ""
    },
    on: {
      click: function ($event) {
        return _vm.$emit("switch-mode", "info");
      }
    }
  }, [_c("a", {
    attrs: {
      href: "javascript:;"
    }
  }, [_c("u", [_vm._v("Back")])])])], 1)], 1)], 1)], 1), _c("b-row", [_c("b-col", [_c("b-toast", {
    attrs: {
      id: "historyToast",
      variant: "danger",
      "no-close-button": "",
      "auto-hide-delay": "2500",
      toaster: "b-toaster-top-center",
      solid: ""
    }
  }, [_vm._v(" " + _vm._s(_vm.alert.message) + " ")])], 1)], 1), _vm.dataloaded ? [_c("b-row", {
    staticClass: "tw-bg-snow-white tw-overflow-auto",
    attrs: {
      id: "patient-history-thread"
    }
  }, [_vm._l(_vm.historyData, function (entry) {
    return _c("b-col", {
      key: entry.id,
      attrs: {
        cols: "12"
      }
    }, [_c("b-row", {
      attrs: {
        "align-h": _vm.getAlignment(entry)
      }
    }, [_vm.getAlignment(entry) == "start" ? _c("b-col", {
      attrs: {
        cols: "1"
      }
    }, [_c("b-avatar", {
      directives: [{
        name: "html-safe",
        rawName: "v-html-safe",
        value: _vm.getIcon(entry),
        expression: "getIcon(entry)"
      }],
      attrs: {
        size: "40px"
      }
    })], 1) : _vm._e(), _c("b-col", {
      staticClass: "message px-5 py-3 tw-text-wrap",
      class: _vm.getStyle(entry),
      attrs: {
        cols: "8"
      }
    }, [entry.event == "JustCall" && ["Call", "SMS"].includes(entry.eventcategory) ? _c("span", {
      staticClass: "ml-n3"
    }, [_vm._v(" " + _vm._s(entry.direction) + " " + _vm._s(entry.eventcategory) + " ")]) : _vm.getStyle(entry) == "message-event" ? _c("span", {
      staticClass: "ml-n3"
    }, [_vm._v(" " + _vm._s(entry.eventname) + " ")]) : _vm._e(), _c("b-row", [_c("b-col", [entry.eventcategory == "Doctor Reassigned" ? _c("b-row", {
      staticClass: "tw-block"
    }, [_c("span", [_vm._v(" " + _vm._s(_vm.getAssignerName(entry.transferredby || entry.requestedby)) + " reassigned the doctor ")]), _c("span", {
      staticClass: "bold"
    }, [_c("span", [_vm._v(" from " + _vm._s(_vm.getDoctorName(entry.doctoridfrom)) + " ")]), _c("span", [_vm._v(" to " + _vm._s(_vm.getDoctorName(entry.doctoridto)) + " ")]), _vm._v(" " + _vm._s(entry.assignmenttype === "PERMANENT" ? " permanently." : " until " + _vm.getDate(entry.enddatestamp * 1000) + ".") + " ")])]) : entry.eventcategory == "Intercom" ? _c("b-row", [_c("b-col", [_c("b-row", [_c("b-col", {
      attrs: {
        id: entry.id
      }
    }, [_c("div", {
      directives: [{
        name: "html-safe",
        rawName: "v-html-safe",
        value: _vm.parseHtml(entry.messagebody),
        expression: "parseHtml(entry.messagebody)"
      }]
    })])], 1)], 1)], 1) : entry.eventcategory === "JustCall" ? _c("b-row", [_c("b-col", [_c("b-row", [_c("b-col", [_c("span", {
      staticClass: "text-muted"
    }, [_vm._v("Note By:")]), _c("b", {
      staticClass: "ml-1"
    }), _c("br")])], 1), entry.eventcategory == "JustCall" ? _c("b-row", [_c("b-col", [_c("span", {
      staticClass: "text-muted"
    }, [_vm._v("Call Status:")]), _c("p", [_vm._v(" " + _vm._s(entry.callstatus) + " ")])])], 1) : _vm._e(), _c("b-row", [_c("b-col", [_c("span", {
      staticClass: "text-muted"
    }, [_vm._v("Note Content:")]), _c("p", [_vm._v(" " + _vm._s(entry.messagebody) + " ")])])], 1)], 1)], 1) : entry.eventcategory == "Notes" ? _c("b-row", [_c("span", {
      directives: [{
        name: "html-safe",
        rawName: "v-html-safe",
        value: entry.messagebody,
        expression: "entry.messagebody"
      }],
      staticClass: "tw-break-all"
    })]) : entry.event == "JustCall" ? _c("b-row", [_c("div", [entry.direction === "Outgoing" ? _c("div", [_c("small", {
      staticClass: "tw-text-sm tw-mr-2 tw-text-black/50"
    }, [_vm._v("Patient Contacted:")]), _c("span", {
      staticClass: "tw-font-bold"
    }, [_vm._v(_vm._s(_vm.patientFullName) + " (" + _vm._s(_vm.patient.phoneno) + ")")])]) : _vm._e(), _c("div", [_c("small", {
      staticClass: "tw-text-sm tw-mr-2 tw-text-black/50"
    }, [_vm._v(" " + _vm._s(entry.eventcategory === "Call" ? "Caller" : "Messenger") + ": ")]), _c("span", {
      staticClass: "tw-font-bold"
    }, [entry.agent_id ? [_vm._v(" " + _vm._s(entry.agent_name) + " (" + _vm._s(entry.contact_number) + ") ")] : [_vm._v(" " + _vm._s(entry.fullJSON.justcall_line_name) + " (" + _vm._s(entry.fullJSON.justcall_number) + ") ")]], 2)]), entry.friendly_duration ? _c("div", [_c("small", {
      staticClass: "tw-text-sm tw-mr-2 tw-text-black/50"
    }, [_vm._v("Call Duration:")]), _c("span", {
      staticClass: "tw-font-bold"
    }, [_vm._v(_vm._s(entry.friendly_duration))])]) : _vm._e(), _vm._get(entry, "fullJSON.call_info.recording") ? _c("div", [_c("small", {
      staticClass: "tw-text-sm tw-mr-2 tw-text-black/50"
    }, [_vm._v("Recording:")]), _c("a", {
      attrs: {
        href: entry.fullJSON.call_info.recording,
        target: "_blank"
      }
    }, [_vm._v(" Listen here ")])]) : _vm._e(), _vm._get(entry, "fullJSON.sms_info.body") ? _c("div", [_c("span", {
      staticClass: "tw-text-sm tw-mr-2 tw-text-black/50"
    }, [_vm._v("Message:")]), _c("p", {
      directives: [{
        name: "html-safe",
        rawName: "v-html-safe",
        value: entry.fullJSON.sms_info.body,
        expression: "entry.fullJSON.sms_info.body"
      }]
    })]) : _vm._e()])]) : entry.eventname === "Follow Up Created" ? _c("b-row", [_c("b-col", [_c("b-row", [_c("b-col", [_c("span", {
      staticClass: "text-muted"
    }, [_vm._v("Scheduled:")]), _c("b", {
      staticClass: "ml-1"
    }, [_vm._v(_vm._s(entry.scheduleddate))]), _c("br")])], 1), _c("b-row", [_c("b-col", [_c("span", {
      staticClass: "text-muted"
    }, [_vm._v("Doctor:")]), _c("span", {
      staticClass: "d-inline-block ml-1"
    }, [_vm._v(" " + _vm._s(entry.messagebody) + " ")])])], 1)], 1)], 1) : entry.eventname === "Follow Up Updated" ? _c("b-row", [_c("b-col", [_c("b-row", [_c("b-col", [_c("span", {
      staticClass: "text-muted"
    }, [_vm._v("New Date:")]), _c("b", {
      staticClass: "ml-1"
    }, [_vm._v(_vm._s(entry.scheduleddate))]), _c("br")])], 1), _c("b-row", [_c("b-col", [_c("span", {
      staticClass: "text-muted"
    }, [_vm._v("Original Date:")]), _c("b", {
      staticClass: "ml-1"
    }, [_vm._v(_vm._s(entry.previousdate))]), _c("br")])], 1), _c("b-row", [_c("b-col", [_c("span", {
      staticClass: "text-muted"
    }, [_vm._v("Doctor:")]), _c("span", {
      staticClass: "d-inline-block ml-1"
    }, [_vm._v(" " + _vm._s(entry.messagebody) + " ")])])], 1)], 1)], 1) : entry.eventcategory == "SMS" ? _c("b-row", [_vm._v(" " + _vm._s(entry.messagebody) + " ")]) : entry.eventname === "Patient Cancelled" ? _c("b-row", {
      staticClass: "text-muted"
    }, [entry.cancelledbyrole === "ADMIN" ? [_vm._v(" Admin "), _c("strong", {
      staticClass: "tw-text-black"
    }, [_vm._v(_vm._s(entry.cancelledbyname))]), _vm._v(" has cancelled patient "), _c("strong", {
      staticClass: "tw-text-black"
    }, [_vm._v(_vm._s(entry.patientname))]), _vm._v(". ")] : entry.cancelledbyrole === "DOCTOR" ? [_c("strong", {
      staticClass: "tw-text-black"
    }, [_vm._v("Dr. " + _vm._s(entry.cancelledbyname))]), _vm._v(" has cancelled patient "), _c("strong", {
      staticClass: "tw-text-black"
    }, [_vm._v(_vm._s(entry.patientname))]), _vm._v(". ")] : [_vm._v(" Patient "), _c("strong", {
      staticClass: "tw-text-black"
    }, [_vm._v(_vm._s(entry.patientname))]), _vm._v(" has cancelled her Winona Subscription. ")]], 2) : _c("b-row", {
      staticClass: "text-muted"
    }, [_vm._v(" " + _vm._s(entry.messagebody) + " ")])], 1)], 1), entry.eventname == "Treatment Shipped" ? _c("b-row", [_c("b-col", [_c("b-row", [_c("b-col", [_c("span", {
      staticClass: "text-muted"
    }, [_vm._v("Tracking Number:")]), _vm._v(" " + _vm._s(entry.trackingno ? entry.trackingno : "") + " ")])], 1), _c("b-row", [_c("b-col", [_c("span", {
      staticClass: "text-muted"
    }, [_vm._v("Delivery Address:")]), _vm._v(" " + _vm._s(entry.deliveryaddress ? entry.deliveryaddress : "") + " ")])], 1)], 1)], 1) : entry.eventname == "Payment Failed" ? _c("b-row", [_c("b-col", [_c("b-row", [_c("b-col", [_c("span", {
      staticClass: "text-muted"
    }, [_vm._v("Payment Failed:")]), _vm._v(" " + _vm._s(entry.paymentfailed ? entry.paymentfailed : "") + " ")])], 1), _c("b-row", [_c("b-col", [_c("span", {
      staticClass: "text-muted"
    }, [_vm._v("Stripe Decline Code:")]), _vm._v(" " + _vm._s(entry.stripedeclinecode ? entry.stripedeclinecode : "") + " ")])], 1), _c("b-row", [_c("b-col", [_c("span", {
      staticClass: "text-muted"
    }, [_vm._v("Stripe Error:")]), _vm._v(" " + _vm._s(entry.stripedesc ? entry.stripedesc : "") + " ")])], 1), _c("b-row", [_c("b-col", [_c("span", {
      staticClass: "text-muted"
    }, [_vm._v("Next Step:")]), _vm._v(" " + _vm._s(entry.stripenextstep ? entry.stripenextstep : "") + " ")])], 1)], 1)], 1) : entry.eventname == "Email Updated" ? _c("b-row", [_c("b-col", [_c("b-row", [_c("b-col", [_c("span", {
      staticClass: "text-muted"
    }, [_vm._v("Email:")]), _vm._v(" " + _vm._s(entry.changedfrom.email) + " "), _c("b-icon", {
      attrs: {
        icon: "arrow-right"
      }
    }), _c("b", [_vm._v(_vm._s(entry.changedto.email))])], 1)], 1)], 1)], 1) : entry.eventname == "Address Verification" ? _c("b-row", [_c("b-col", [_c("b-row", [_c("b-col", [_c("span", {
      staticClass: "text-muted"
    }, [_vm._v("Entered Address:")]), _vm._v(" " + _vm._s(entry.enteredaddress ? entry.enteredaddress : "") + " ")])], 1), _c("b-row", [_c("b-col", [_c("span", {
      staticClass: "text-muted"
    }, [_vm._v("Suggested Address:")]), _vm._v(" " + _vm._s(entry.suggestedaddress ? entry.suggestedaddress : "") + " ")])], 1)], 1)], 1) : entry.eventcategory == "Admin Update" || entry.eventcategory == "Information Update" || entry.event == "Patient Migrated" ? _c("b-row", [_c("b-col", _vm._l(entry.changedfrom, function (prop, key) {
      return _c("b-row", {
        key: key
      }, [_c("b-col", [_c("span", {
        staticClass: "text-muted text-capitalize"
      }, [_vm._v(" " + _vm._s(_vm.getKeyName(key)) + " ")]), _vm._v(" : "), key == "birthday" ? _c("span", [_vm._v(" " + _vm._s(_vm.formatInfoUpdateBirthday(prop)) + " ")]) : _c("span", [_vm._v(_vm._s(prop))]), _c("b-icon", {
        attrs: {
          icon: "arrow-right"
        }
      }), key == "birthday" ? _c("b", [_vm._v(" " + _vm._s(_vm.formatInfoUpdateBirthday(entry.changedto[key])) + " ")]) : _c("b", [_vm._v(" " + _vm._s(entry.changedto[key]) + " "), !entry.changedto[key] ? _c("span", [_vm._v("(none)")]) : _vm._e()])], 1)], 1);
    }), 1)], 1) : entry.eventcategory == "Refund" ? _c("b-row", [_c("b-col", [_c("b-row", [_c("b-col", [_c("span", {
      staticClass: "text-muted text-small"
    }, [_vm._v("Refund:")]), _c("br"), entry.issuedby ? _c("b", [_vm._v(_vm._s(entry.issuedby))]) : _vm._e(), _vm._v(" refunded the following with a total amount of $" + _vm._s(entry.refundamount) + ": ")])], 1), _c("b-row", {
      staticClass: "mt-3"
    }, [_c("b-col", {
      attrs: {
        cols: "10"
      }
    }, [_c("b-row", [_c("b-col", [_c("b-table-simple", [_c("b-tbody", [_c("b-tr", [_c("b-th", [_vm._v("Prescription Order No.")]), _c("b-th", [_vm._v("Medicine Name")]), _c("b-th", [_vm._v("Refund Amount")])], 1), _c("b-tr", [_c("b-td", [_vm._v(" " + _vm._s(entry.prescription.prescriptionorderno) + " ")]), _c("b-td", [_vm._v(" " + _vm._s(entry.prescription.medicinename) + " ")]), _c("b-td", [_vm._v("$" + _vm._s(entry.refundamount))])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1) : entry.eventcategory == "Support Interaction" ? _c("b-row", [_c("b-col", [_c("span", {
      staticClass: "text-muted"
    }, [_vm._v("Record URL:")]), _c("a", {
      staticClass: "ml-2",
      attrs: {
        href: entry.transcriptionurl
      }
    }, [_vm._v("Listen here")])])], 1) : _vm._e()], 1), _vm.getAlignment(entry) == "end" ? _c("b-col", {
      attrs: {
        cols: "1"
      }
    }, [_c("b-avatar")], 1) : _vm._e()], 1), _c("b-row", {
      staticClass: "mt-2 mb-3",
      attrs: {
        "align-h": _vm.getAlignment(entry)
      }
    }, [_vm.getAlignment(entry) == "start" ? _c("b-col", {
      attrs: {
        cols: "1"
      }
    }) : _vm._e(), _c("b-col", {
      staticClass: "text-small",
      attrs: {
        cols: "8"
      }
    }, [_c("b-row", [_c("b-col", [entry.eventcategory == "Notes" ? _c("span", [_vm._v(" " + _vm._s(entry.noteby) + " - ")]) : _vm._e(), entry.eventcategory == "Intercom" ? _c("span", [entry.eventname == "Incoming" ? _c("span", [_vm._v(" " + _vm._s(_vm.patientName) + " - ")]) : _vm._e(), _vm._v(" " + _vm._s(entry.eventcategory) + " ")]) : entry.event == "JustCall" && ["Call", "SMS"].includes(entry.eventcategory) ? _c("span", [_vm._v(" " + _vm._s(entry.event) + " ")]) : _c("span", [_vm._v(" " + _vm._s(entry.eventcategory) + " ")])]), _c("b-col", {
      staticClass: "text-right"
    }, [_vm._v(" " + _vm._s(_vm.getDateTime(entry.timestampinteraction * 1000)) + " ")])], 1)], 1), _vm.getAlignment(entry) == "end" ? _c("b-col", {
      attrs: {
        cols: "1"
      }
    }) : _vm._e()], 1)], 1);
  }), _vm.historyData.length == 0 ? _c("b-col", {
    attrs: {
      cols: "12"
    }
  }, [_vm._v(" No events have been recorded for this user under the specified filters. ")]) : _vm._e(), _c("div", {
    ref: "bottomOfPage",
    attrs: {
      id: "bottomOfPage"
    }
  })], 2), _c("b-row", [_c("b-col", {
    staticClass: "tw-py-3 tw-px-0 bg-white",
    attrs: {
      id: "patient-history-footer",
      cols: "12"
    }
  }, [_c("AddNoteHistory", {
    attrs: {
      "patient-id": _vm.patientId
    },
    on: {
      "add-event": _vm.addEvent,
      "show-toast": _vm.showAlert
    }
  })], 1)], 1)] : _c("b-row", [_c("b-col", {
    attrs: {
      cols: "12"
    }
  }, [_c("b-card", [_c("b-row", [_c("b-col", {
    attrs: {
      cols: "2"
    }
  }, [_c("b-skeleton", {
    attrs: {
      width: "80%"
    }
  })], 1), _c("b-col", {
    attrs: {
      cols: "8"
    }
  }, [_c("b-skeleton"), _c("b-skeleton", {
    attrs: {
      width: "60%"
    }
  })], 1), _c("b-col", {
    attrs: {
      cols: "2"
    }
  }, [_c("b-skeleton", {
    attrs: {
      width: "70%"
    }
  })], 1)], 1), _c("b-row", [_c("b-col", [_c("b-skeleton", {
    attrs: {
      width: "10%"
    }
  })], 1)], 1)], 1)], 1)], 1)], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };