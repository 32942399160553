export default {
  props: {
    size: {
      type: String,
      default: "md",
      validator: value => ["md", "lg"].includes(value)
    },
    color: {
      type: String,
      default: 'primary'
    },
    outline: {
      type: Boolean,
      default: false
    },
    text: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    sizeClass() {
      switch (this.size) {
        case "md":
          return `
            tw-min-w-[120px]
            tw-text-[14px]
            tw-py-[10px]
            tw-px-[30px]
            tw-leading-[20px]
            tw-letter-spacing-[-0.25px]
          `;
        case "lg":
          return `
            tw-min-w-[160px]
            tw-text-[16px]
            tw-py-[16px]
            tw-px-[48px]
            tw-leading-[24px]
            tw-letter-spacing-[-0.5px]
          `;
        default:
          return "";
      }
    },
    loadingColorClass() {
      if (this.outline && this.color === 'primary') {
        return 'tw-text-primary';
      }
      return 'tw-text-white';
    },
    colorClass() {
      switch (this.color) {
        case "primary":
          {
            if (this.outline) {
              return `
              tw-bg-transparent hover:tw-bg-primary/10
              tw-border-primary
              tw-text-primary
            `;
            }
            return `
            tw-bg-primary
            tw-border-primary
            tw-text-white
          `;
          }
        case "secondary":
          {
            if (this.outline) {
              return `
              tw-bg-transparent hover:tw-bg-secondary/10
              tw-border-secondary
              tw-text-secondary
            `;
            }
            if (this.text) {
              return "tw-bg-transparent tw-border-transparent tw-text-secondary hover:tw-bg-secondary/10";
            }
            return `
            tw-bg-secondary
            tw-border-secondary
            tw-text-white
          `;
          }
        case "info":
          return this.outline ? "tw-border-info tw-bg-transparent tw-text-info" : "tw-bg-info tw-text-black";
        case "primary-to-secondary":
          return "tw-bg-gradient-to-r tw-from-primary tw-to-secondary tw-text-white";
        case "evergreen":
          return this.outline ? "tw-border-evergreen hover:tw-bg-green-500/10 tw-bg-transparent tw-text-evergreen" : "tw-bg-evergreen tw-text-mint-cream";
        case "secondary-mischka":
          return this.outline ? "tw-border-secondary-mischka tw-bg-transparent tw-text-secondary-mischka" : "tw-border-secondary-mischka tw-bg-secondary-mischka tw-text-black";
        case "posey-green":
          return this.outline ? "tw-border-posey-green tw-bg-transparent tw-text-posey-green" : "tw-bg-posey-green tw-text-white";
        case "gray":
          return this.outline ? "tw-border-gray tw-bg-transparent tw-text-gray" : "tw-bg-gray tw-text-black";
        default:
          return "";
      }
    },
    disabledClass() {
      return this.$attrs.disabled ? "tw-opacity-[0.80] tw-cursor-not-allowed" : "";
    }
  }
};