var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    attrs: {
      "hide-header": "",
      "hide-footer": "",
      centered: "",
      "no-close-on-backdrop": "",
      "no-stacking": "",
      size: "lg"
    },
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c("b-alert", {
    attrs: {
      variant: "warning",
      show: _vm.alert.show
    }
  }, [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]), _c("b-row", {
    staticClass: "mb-4",
    attrs: {
      "align-h": "between"
    }
  }, [_c("b-col", {
    attrs: {
      cols: "10"
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/icon_logo_violet.svg"),
      alt: ""
    }
  })]), _c("b-col", {
    staticClass: "text-right"
  }, [_c("a", {
    staticClass: "tw-text-eclipse",
    attrs: {
      href: "javascript:;"
    },
    on: {
      click: function ($event) {
        _vm.showDialog = false;
      }
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "x-circle",
      "font-scale": "1.5"
    }
  })], 1)])], 1), _c("b-row", {
    staticClass: "mt-4 pr-4"
  }, [_c("b-col", {
    staticClass: "mb-3",
    attrs: {
      cols: "4"
    }
  }, [_c("span", {
    staticClass: "tw-text-primary h2"
  }, [_vm._v("Doctor Settings")])]), _c("b-col", {
    staticClass: "mb-3",
    attrs: {
      cols: "8"
    }
  }, [_c("b-col", {
    staticClass: "mb-4"
  }, [_c("b-row", [_c("b-col", [_c("div", {
    staticClass: "text-primary"
  }, [_vm._v(" Doctor ")]), _c("div", [_vm._v("Dr. " + _vm._s(_vm.rowData.firstname) + " " + _vm._s(_vm.rowData.lastname))])])], 1)], 1), _c("b-col", [_vm.currentUser.roletype === "SUPER ADMIN" ? _c("b-row", [_c("b-col", [_c("b-row", [_c("b-col", {
    staticClass: "text-primary"
  }, [_vm._v(" Active ")]), _c("b-col", {
    staticClass: "text-primary"
  }, [_c("b-form-checkbox", {
    attrs: {
      switch: ""
    },
    model: {
      value: _vm.canAcceptPatient,
      callback: function ($$v) {
        _vm.canAcceptPatient = $$v;
      },
      expression: "canAcceptPatient"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _c("b-row", {
    staticClass: "mt-4"
  }, [_c("b-col", [_c("b-row", [_c("b-col", {
    staticClass: "text-primary"
  }, [_vm._v(" State ")]), _c("b-col", {
    staticClass: "text-primary"
  }, [_vm._v(" Max Daily Accept ")])], 1)], 1)], 1), _c("b-row", {
    staticClass: "mt-2"
  }, [_c("b-col", _vm._l(_vm.rowData.coveredstate, function (state) {
    return _c("b-row", {
      key: state.state
    }, [_c("b-col", {
      staticClass: "d-flex align-items-center"
    }, [_vm._v(" " + _vm._s(state.state) + " ")]), _c("b-col", [_c("b-form-input", {
      attrs: {
        type: "number",
        min: "0"
      },
      model: {
        value: _vm.limits[state.state],
        callback: function ($$v) {
          _vm.$set(_vm.limits, state.state, $$v);
        },
        expression: "limits[state.state]"
      }
    })], 1)], 1);
  }), 1)], 1)], 1)], 1), _c("b-col", {
    attrs: {
      cols: "4"
    }
  }), _c("b-col", {
    staticClass: "mb-3",
    attrs: {
      cols: "8"
    }
  }, [_c("b-col", [_c("div", {
    staticClass: "text-primary"
  }, [_vm._v(" Versions ")]), _c("b-row", [_c("b-col", {
    staticClass: "d-flex align-items-center"
  }, [_vm._v(" 10 Week Follow-up ")]), _c("b-col", [_c("v-select", {
    attrs: {
      options: [{
        label: "v3",
        code: "fv-3"
      }, {
        label: "v4",
        code: "fv-4"
      }],
      reduce: version => version.code
    },
    model: {
      value: _vm.version10Week,
      callback: function ($$v) {
        _vm.version10Week = $$v;
      },
      expression: "version10Week"
    }
  })], 1)], 1), _vm.version10Week === "fv-3" ? _c("small", {
    staticClass: "text-muted"
  }, [_vm._v(" v3 - Basic short follow up flow with no conditional logic. ")]) : _vm._e(), _vm.version10Week === "fv-4" ? _c("small", {
    staticClass: "text-muted"
  }, [_vm._v(" v4 - Large flow with complex logic conditions for if patient is unsure they want to continue. ")]) : _vm._e(), _c("b-row", [_c("b-col", {
    staticClass: "d-flex align-items-center"
  }, [_vm._v(" 1 Year Follow-up ")]), _c("b-col", [_c("v-select", {
    attrs: {
      options: [{
        label: "v3",
        code: "fv-3"
      }, {
        label: "v4",
        code: "fv-4"
      }],
      reduce: version => version.code
    },
    model: {
      value: _vm.version1Year,
      callback: function ($$v) {
        _vm.version1Year = $$v;
      },
      expression: "version1Year"
    }
  })], 1)], 1), _vm.version1Year === "fv-3" ? _c("small", {
    staticClass: "text-muted"
  }, [_vm._v(" v3 - Basic short follow up flow with no conditional logic. ")]) : _vm._e(), _vm.version1Year === "fv-4" ? _c("small", {
    staticClass: "text-muted"
  }, [_vm._v(" v4 - Large flow with complex logic conditions for if patient is unsure they want to continue. ")]) : _vm._e()], 1)], 1), _c("b-col", {
    attrs: {
      cols: "4"
    }
  }), _c("b-col", {
    attrs: {
      cols: "8"
    }
  }, [_c("b-row", [_c("b-col", {
    staticClass: "pl-4"
  }, [_c("small", {
    staticClass: "text-primary"
  }, [_vm._v("Action")]), _c("br"), _c("div", [_vm._v("New Patient")]), _c("br"), _c("div", [_vm._v("Transferred Patient")]), _c("br"), _c("div", [_vm._v("10W Follow Up")]), _c("br"), _c("div", [_vm._v("1Y Follow Up")]), _c("br"), _c("div", [_vm._v("Messages Adjusted")]), _c("br")]), _c("b-col", [_c("small", {
    staticClass: "text-primary"
  }, [_vm._v("Earnings")]), _c("b-form-input", {
    attrs: {
      placeholder: `$${5}`,
      formatter: _vm.getMoneyValue
    },
    model: {
      value: _vm.newPatient,
      callback: function ($$v) {
        _vm.newPatient = $$v;
      },
      expression: "newPatient"
    }
  }), _c("b-form-input", {
    attrs: {
      placeholder: `$${5}`,
      formatter: _vm.getMoneyValue
    },
    model: {
      value: _vm.transferredPatient,
      callback: function ($$v) {
        _vm.transferredPatient = $$v;
      },
      expression: "transferredPatient"
    }
  }), _c("b-form-input", {
    attrs: {
      placeholder: `$${5}`,
      formatter: _vm.getMoneyValue
    },
    model: {
      value: _vm.tenWeek,
      callback: function ($$v) {
        _vm.tenWeek = $$v;
      },
      expression: "tenWeek"
    }
  }), _c("b-form-input", {
    attrs: {
      placeholder: `$${5}`,
      formatter: _vm.getMoneyValue
    },
    model: {
      value: _vm.oneYear,
      callback: function ($$v) {
        _vm.oneYear = $$v;
      },
      expression: "oneYear"
    }
  }), _c("b-form-input", {
    attrs: {
      placeholder: `$${5}`,
      formatter: _vm.getMoneyValue
    },
    model: {
      value: _vm.messagesAdjusted,
      callback: function ($$v) {
        _vm.messagesAdjusted = $$v;
      },
      expression: "messagesAdjusted"
    }
  })], 1)], 1)], 1), _c("b-col", {
    attrs: {
      cols: "4"
    }
  }), _c("b-col", {
    staticClass: "mt-3",
    attrs: {
      cols: "8"
    }
  }, [_c("b-button", {
    attrs: {
      variant: "secondary",
      pill: "",
      block: ""
    },
    on: {
      click: _vm.setDoctorSettings
    }
  }, [_vm.processing ? _c("b-spinner", {
    attrs: {
      small: ""
    }
  }) : _c("span", [_vm._v("Confirm")])], 1), _c("b-button", {
    attrs: {
      variant: "outline-dark",
      pill: "",
      block: ""
    },
    on: {
      click: function ($event) {
        _vm.showDialog = false;
      }
    }
  }, [_vm._v(" Back ")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };