import jwt from 'jsonwebtoken';
import { PatientService } from '@/services/patient.service';
import { mapState } from 'vuex';
import moment from 'moment';
import PatientsInfoSidePanel from '@/components/patients/PatientsInfoSidePanel';
export default {
  components: {
    PatientsInfoSidePanel
  },
  data() {
    return {
      loading: true,
      patient: null,
      showPatientInfo: false,
      toastMessage: ''
    };
  },
  computed: {
    ...mapState('chat', ['selectedConversation', 'selectedPatient', 'showProfile', 'assignee']),
    currentLoggedAdmin() {
      const logincookie = $cookies.get('accessToken');
      const token = jwt.decode(logincookie);
      const {
        firstname,
        lastname
      } = this.$store.state.auth.currentUser;
      const {
        given_name,
        family_name
      } = token;
      if (firstname && lastname) {
        return `${firstname} ${lastname}`;
      }
      if (given_name && family_name) {
        return `${given_name} ${family_name}`;
      } else {
        return '';
      }
    },
    showAssignButton() {
      return this.assignee != this.currentLoggedAdmin;
    },
    showUnassignButton() {
      return this.assignee == this.currentLoggedAdmin;
    },
    bgModal() {
      return {
        color: this.showPatientInfo ? 'rgba(12, 53, 35, 0.2)' : 'rgba(12, 53, 35, 0.0)',
        show: this.showPatientInfo ? 'block' : 'none'
      };
    }
  },
  watch: {
    async selectedPatient() {
      this.fetchPatientData();
    }
  },
  mounted() {
    this.fetchPatientData();
  },
  methods: {
    viewPatientInfo() {
      this.$refs.PatientsInfoSidePanel.show();
    },
    async assignConversation(conversationid, assign) {
      const payload = {
        conversationid,
        assign
      };
      await this.$store.dispatch('chat/assignConversation', payload);
      await this.$store.dispatch('chat/refreshAssignee');
    },
    toggleDisplay() {
      this.$store.commit('chat/SET_STATE', {
        showProfile: !this.showProfile
      });
    },
    copy(text) {
      navigator.clipboard.writeText(text);
      this.$bvToast.toast('Copied text to clipboard!', {
        title: 'Copied!',
        variant: 'success',
        toaster: 'b-toaster-bottom-right',
        'auto-hide-delay': 1000
      });
    },
    formatDate(date) {
      return new moment(date).format('MM/DD/YYYY');
    },
    getAddress(address) {
      let addressString = '';
      if (address.address1) {
        addressString = addressString.concat(`${address.address1}, `);
      }
      if (address.address2) {
        addressString = addressString.concat(`${address.address2}, `);
      }
      if (address.city) {
        addressString = addressString.concat(`${address.city}, `);
      }
      if (address.zipcode) {
        addressString = addressString.concat(`${address.zipcode}, `);
      }
      return addressString.substr(0, addressString.length - 2);
    },
    async fetchPatientData() {
      if (this.selectedPatient) {
        this.loading = true;
        try {
          const {
            data
          } = await PatientService.getPatient(this.selectedPatient);
          this.patient = data;
        } catch (error) {
          console.error('Error fetching patient data:', error);
        } finally {
          this.loading = false;
        }
      } else {
        this.patient = null;
        this.loading = false;
      }
    }
  }
};