var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("b-modal", {
    attrs: {
      "hide-header": "",
      "hide-footer": "",
      centered: "",
      "no-close-on-backdrop": "",
      size: "lg"
    },
    on: {
      hide: _vm.onHide
    },
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [!_vm.isTransfer ? _c("div", [_c("ValidationObserver", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function ({
        handleSubmit
      }) {
        return [_c("b-row", {
          staticClass: "mb-4",
          attrs: {
            "align-h": "between"
          }
        }, [_c("b-col", {
          attrs: {
            cols: "10"
          }
        }, [_c("img", {
          attrs: {
            src: require("@/assets/images/icon_logo_violet.svg"),
            alt: ""
          }
        })]), _c("b-col", {
          staticClass: "text-right"
        }, [_c("a", {
          staticClass: "tw-text-eclipse",
          attrs: {
            href: "javascript:;"
          },
          on: {
            click: function ($event) {
              _vm.showDialog = false;
            }
          }
        }, [_c("b-icon", {
          attrs: {
            icon: "x-circle",
            "font-scale": "1.5"
          }
        })], 1)])], 1), _c("b-alert", {
          attrs: {
            variant: "warning"
          },
          model: {
            value: _vm.alert.show,
            callback: function ($$v) {
              _vm.$set(_vm.alert, "show", $$v);
            },
            expression: "alert.show"
          }
        }, [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]), _c("b-row", [_c("b-col", {
          attrs: {
            cols: "3"
          }
        }, [_c("h3", {
          staticClass: "text-primary mb-2"
        }, [_vm._v(" " + _vm._s(_vm.admin ? "Edit Admin" : "New Admin") + " ")])]), _c("b-col", {
          attrs: {
            cols: "9"
          }
        }, [_c("b-row", [_c("b-col", {
          staticClass: "mb-4",
          attrs: {
            cols: "6"
          }
        }, [_c("ValidationProvider", {
          attrs: {
            name: "First Name",
            rules: "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function ({
              errors
            }) {
              return [_c("p", {
                staticClass: "text-muted mb-0"
              }, [_vm._v(" First Name ")]), _c("b-form-input", {
                attrs: {
                  type: "text"
                },
                model: {
                  value: _vm.firstname,
                  callback: function ($$v) {
                    _vm.firstname = $$v;
                  },
                  expression: "firstname"
                }
              }), _c("small", {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c("b-col", {
          staticClass: "mb-4",
          attrs: {
            cols: "6"
          }
        }, [_c("ValidationProvider", {
          attrs: {
            name: "Last Name",
            rules: "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function ({
              errors
            }) {
              return [_c("p", {
                staticClass: "text-muted mb-0"
              }, [_vm._v(" Last Name ")]), _c("b-form-input", {
                attrs: {
                  type: "text"
                },
                model: {
                  value: _vm.lastname,
                  callback: function ($$v) {
                    _vm.lastname = $$v;
                  },
                  expression: "lastname"
                }
              }), _c("small", {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c("b-col", {
          staticClass: "mb-4",
          attrs: {
            cols: "6"
          }
        }, [_c("ValidationProvider", {
          attrs: {
            name: "Email",
            rules: "required|email"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function ({
              errors
            }) {
              return [_c("p", {
                staticClass: "text-muted mb-0"
              }, [_vm._v(" Email ")]), _c("b-form-input", {
                attrs: {
                  type: "text",
                  disabled: Boolean(_vm.admin)
                },
                model: {
                  value: _vm.email,
                  callback: function ($$v) {
                    _vm.email = $$v;
                  },
                  expression: "email"
                }
              }), _c("small", {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c("b-col", {
          staticClass: "mb-4",
          attrs: {
            cols: "6"
          }
        }, [_c("ValidationProvider", {
          attrs: {
            name: "Phone number",
            rules: "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function ({
              errors
            }) {
              return [_c("p", {
                staticClass: "text-muted mb-0"
              }, [_vm._v(" Phone no. ")]), _c("b-form-input", {
                attrs: {
                  type: "text"
                },
                model: {
                  value: _vm.phone,
                  callback: function ($$v) {
                    _vm.phone = $$v;
                  },
                  expression: "phone"
                }
              }), _c("small", {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c("b-col", {
          staticClass: "mb-5",
          attrs: {
            cols: "12"
          }
        }, [_c("ValidationProvider", {
          attrs: {
            name: "Role",
            rules: "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function ({
              errors
            }) {
              return [_c("p", {
                staticClass: "text-muted mb-0"
              }, [_vm._v(" Role ")]), _c("v-select", {
                staticClass: "role",
                attrs: {
                  options: _vm.roleOptions,
                  reduce: role => role.code
                },
                model: {
                  value: _vm.role,
                  callback: function ($$v) {
                    _vm.role = $$v;
                  },
                  expression: "role"
                }
              }), _c("small", {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c("b-col", {
          staticClass: "mb-4",
          attrs: {
            cols: "12"
          }
        }, [_c("b-row", [_c("b-col", {
          staticClass: "mb-3",
          attrs: {
            cols: "6"
          }
        }, [_c("b-button", {
          attrs: {
            block: "",
            variant: "outline-dark",
            pill: ""
          },
          on: {
            click: function ($event) {
              _vm.showDialog = false;
            }
          }
        }, [_vm._v(" Back ")])], 1), _c("b-col", {
          staticClass: "mb-3",
          attrs: {
            cols: "6"
          }
        }, [_c("b-button", {
          attrs: {
            block: "",
            variant: "secondary",
            pill: "",
            disabled: _vm.isProcessing
          },
          on: {
            click: function ($event) {
              return handleSubmit(_vm.onSubmit);
            }
          }
        }, [_vm.isProcessing ? _c("b-spinner", {
          attrs: {
            small: ""
          }
        }) : _c("span", [_vm._v("Confirm")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)];
      }
    }], null, false, 3751259654)
  })], 1) : _c("div", [_c("ValidationObserver", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function ({
        handleSubmit
      }) {
        return [_c("b-row", {
          staticClass: "mb-4",
          attrs: {
            "align-h": "between"
          }
        }, [_c("b-col", {
          attrs: {
            cols: "10"
          }
        }, [_c("img", {
          attrs: {
            src: require("@/assets/images/icon_logo_violet.svg"),
            alt: ""
          }
        })]), _c("b-col", {
          staticClass: "text-right"
        }, [_c("a", {
          staticClass: "tw-text-eclipse",
          attrs: {
            href: "javascript:;"
          },
          on: {
            click: function ($event) {
              _vm.showDialog = false;
            }
          }
        }, [_c("b-icon", {
          attrs: {
            icon: "x-circle",
            "font-scale": "1.5"
          }
        })], 1)])], 1), _c("b-alert", {
          attrs: {
            variant: "warning"
          },
          model: {
            value: _vm.alert.show,
            callback: function ($$v) {
              _vm.$set(_vm.alert, "show", $$v);
            },
            expression: "alert.show"
          }
        }, [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]), _c("b-row", [_c("b-col", {
          attrs: {
            cols: "3"
          }
        }, [_c("h3", {
          staticClass: "text-primary mb-2"
        }, [_vm._v(" Transfer Super Admin ")])]), _c("b-col", {
          attrs: {
            cols: "9"
          }
        }, [_c("b-row", [_c("b-col", {
          staticClass: "mb-5",
          attrs: {
            cols: "12"
          }
        }, [_c("ValidationProvider", {
          attrs: {
            name: "Transfer to",
            rules: "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function ({
              errors
            }) {
              return [_c("p", {
                staticClass: "text-muted mb-0"
              }, [_vm._v(" Transfer to ")]), _c("v-select", {
                staticClass: "admin",
                attrs: {
                  options: _vm.filteredAdmins,
                  reduce: admin => admin.code
                },
                model: {
                  value: _vm.selectedAdmin,
                  callback: function ($$v) {
                    _vm.selectedAdmin = $$v;
                  },
                  expression: "selectedAdmin"
                }
              }), _c("small", {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c("b-col", {
          staticClass: "mb-4",
          attrs: {
            cols: "12"
          }
        }, [_c("b-row", [_c("b-col", {
          staticClass: "mb-3",
          attrs: {
            cols: "6"
          }
        }, [_c("b-button", {
          attrs: {
            block: "",
            variant: "outline-dark",
            pill: ""
          },
          on: {
            click: function ($event) {
              _vm.showDialog = false;
            }
          }
        }, [_vm._v(" Back ")])], 1), _c("b-col", {
          staticClass: "mb-3",
          attrs: {
            cols: "6"
          }
        }, [_c("b-button", {
          attrs: {
            block: "",
            variant: "secondary",
            pill: "",
            disabled: _vm.isProcessing
          },
          on: {
            click: function ($event) {
              return handleSubmit(_vm.onSubmitTransfer);
            }
          }
        }, [_vm.isProcessing ? _c("b-spinner", {
          attrs: {
            small: ""
          }
        }) : _c("span", [_vm._v("Confirm")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)];
      }
    }])
  })], 1)]), _c("b-modal", {
    attrs: {
      "hide-header": "",
      "hide-footer": "",
      centered: "",
      "no-close-on-backdrop": "",
      size: "lg"
    },
    model: {
      value: _vm.showConfirmationDialog,
      callback: function ($$v) {
        _vm.showConfirmationDialog = $$v;
      },
      expression: "showConfirmationDialog"
    }
  }, [_c("div", [_c("b-row", {
    staticClass: "mb-4",
    attrs: {
      "align-h": "between"
    }
  }, [_c("b-col", {
    attrs: {
      cols: "10"
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/icon_logo_violet.svg"),
      alt: ""
    }
  })]), _c("b-col", {
    staticClass: "text-right"
  }, [_c("a", {
    on: {
      click: function ($event) {
        $event.preventDefault();
        _vm.showConfirmationDialog = false;
      }
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "x-circle",
      "font-scale": "1.5"
    }
  })], 1)])], 1), _c("b-alert", {
    attrs: {
      variant: "warning"
    },
    model: {
      value: _vm.alert.show,
      callback: function ($$v) {
        _vm.$set(_vm.alert, "show", $$v);
      },
      expression: "alert.show"
    }
  }, [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]), _c("b-row", [_c("b-col", {
    attrs: {
      cols: "3"
    }
  }), _c("b-col", {
    attrs: {
      cols: "9"
    }
  }, [_c("b-row", [_c("b-col", {
    staticClass: "mb-3",
    attrs: {
      cols: "12"
    }
  }, [!_vm.isTransfer ? _c("p", {
    staticClass: "heading",
    staticStyle: {
      "font-size": "1.5rem"
    }
  }, [_vm._v(" Change " + _vm._s(`${_vm.firstname} ${_vm.lastname}`) + " role to " + _vm._s(_vm.capitalizedRole) + "? ")]) : _c("p", {
    staticClass: "heading",
    staticStyle: {
      "font-size": "1.5rem"
    }
  }, [_vm._v(" Transfer your SUPER ADMIN role? ")])]), _c("b-col", {
    staticClass: "mb-3",
    attrs: {
      cols: "12"
    }
  }, [!_vm.isTransfer ? _c("b-button", {
    attrs: {
      block: "",
      variant: "secondary",
      pill: ""
    },
    on: {
      click: _vm.onConfirmationSubmit
    }
  }, [_vm._v(" Change to " + _vm._s(_vm.role) + " ")]) : _c("b-button", {
    attrs: {
      block: "",
      variant: "secondary",
      pill: ""
    },
    on: {
      click: _vm.onConfirmationTransfer
    }
  }, [_vm._v(" Transfer Role ")])], 1), _c("b-col", {
    staticClass: "mb-3",
    attrs: {
      cols: "12"
    }
  }, [_c("b-button", {
    attrs: {
      block: "",
      variant: "outline-dark",
      pill: ""
    },
    on: {
      click: function ($event) {
        _vm.showConfirmationDialog = false;
      }
    }
  }, [_vm._v(" Cancel ")])], 1)], 1)], 1)], 1)], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };