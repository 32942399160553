var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("nav", {
    staticClass: "tw-fixed tw-top-0 tw-left-0 tw-bottom-0 tw-bg-primary tw-text-white tw-z-10 tw-px-4 tw-py-8 tw-flex tw-items-center tw-justify-center tw-grow tw-flex-col tw-transition-all tw-pl-6 tw-w-[76px] hover:tw-w-60 tw-overflow-hidden",
    on: {
      mouseenter: function ($event) {
        _vm.showSidebar = true;
      },
      mouseleave: function ($event) {
        _vm.showSidebar = false;
      }
    }
  }, [_c("div", {
    staticClass: "tw-flex"
  }, [_c("svg", {
    attrs: {
      width: "25",
      height: "25",
      viewBox: "0 0 38 31",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg"
    }
  }, [_c("path", {
    attrs: {
      d: "M32.8398 21.1L19.5498 7.55001H18.0098L4.71984 21.1L4.68984 0.450012H0.339844V30.53H2.00984L18.7798 12.49L35.4998 30.42L35.6098 30.53H37.2298V0.450012H32.8298L32.8598 21.1H32.8398Z",
      fill: "white"
    }
  })])]), _c("div", {
    staticClass: "tw-flex tw-flex-1 tw-flex-col tw-h-full"
  }, [_c("div", {
    staticClass: "tw-flex tw-flex-1 tw-flex-col tw-items-start tw-gap-y-7"
  }, [_c("div", {
    staticClass: "tw-flex tw-flex-col tw-items-center tw-justify-center tw-space-y-12 tw-flex-1"
  }, _vm._l(_vm.navList, function (navItem) {
    return _c("router-link", {
      key: navItem.name,
      staticClass: "tw-cursor-pointer tw-text-white hover:tw-text-white tw-flex tw-items-center tw-w-full tw-relative",
      attrs: {
        to: navItem.to,
        custom: ""
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function ({
          navigate,
          href
        }) {
          return [_c("a", {
            staticClass: "tw-block",
            attrs: {
              href: href
            },
            on: {
              click: navigate
            }
          }, [navItem.is_active ? _c("span", {
            staticClass: "sidebar-active tw-absolute tw-top-0 -tw-left-[24px] tw-h-full tw-w-1 tw-bg-white tw-scale-y-150 tw-block"
          }) : _vm._e(), _c(navItem.icon, {
            tag: "component",
            staticClass: "tw-flex-shrink-0",
            class: navItem.iconClass
          }), _c("span", {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: _vm.showSidebar,
              expression: "showSidebar"
            }],
            staticClass: "tw-uppercase tw-text-base tw-tracking-[1.6px] tw-ml-8 tw-block"
          }, [_vm._v(" " + _vm._s(navItem.name) + " ")])], 1)];
        }
      }], null, true)
    });
  }), 1), _c("div", {
    staticClass: "tw-mt-auto tw-pb-8"
  }, [_c("a", {
    staticClass: "tw-cursor-pointer tw-text-white hover:tw-text-white tw-flex tw-items-center tw-justify-center",
    on: {
      click: _vm.onLogout
    }
  }, [_c("ProfileIcon"), _vm.showSidebar ? _c("div", {
    staticClass: "tw-capitalize tw-text-base tw-tracking-wider tw-ml-10"
  }, [_vm._v(" Logout ")]) : _vm._e()], 1)])])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };