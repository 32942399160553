var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    attrs: {
      "hide-header": "",
      "hide-footer": "",
      centered: "",
      "no-close-on-backdrop": "",
      "no-stacking": "",
      size: "xl"
    },
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c("div", [_c("b-alert", {
    attrs: {
      variant: "warning"
    },
    model: {
      value: _vm.alert.show,
      callback: function ($$v) {
        _vm.$set(_vm.alert, "show", $$v);
      },
      expression: "alert.show"
    }
  }, [_vm._v(" " + _vm._s(_vm.alert.message) + " ")])], 1), _c("b-row", [_c("b-col", {
    attrs: {
      cols: "2"
    }
  }, [_vm._v("ID and Photo")]), _c("b-col", [_vm.id.existingPhotoUrl ? _c("b-row", [_c("b-col", [_c("span", {
    staticClass: "text-muted"
  }, [_vm._v("Doctor")]), _vm.patientData.doctorid !== "NONE" ? _c("p", [_vm._v(" Dr. " + _vm._s(_vm.patientData.doctorname) + " ")]) : _c("p", [_vm._v("No doctor assigned yet.")])]), _c("b-col", [_c("span", {
    staticClass: "text-muted"
  }, [_vm._v("Date")]), _vm.patientData.dateaccepted ? _c("p", [_vm._v(" " + _vm._s(_vm.getDate(_vm.patientData.dateaccepted)) + " ")]) : _vm._e()]), _c("b-col", [_c("span", {
    staticClass: "text-muted"
  }, [_vm._v("Notes")]), _vm.patientData.dateaccepted ? _c("p", [_vm._v(" " + _vm._s(_vm.patientData.failedreason) + " ")]) : _c("p", [_vm._v("This patient has not yet been accepted.")])])], 1) : _vm._e()], 1)], 1), _c("b-row", [_c("b-col", [_c("b-row", [_vm.id.existingPhotoUrl || _vm.photo.existingPhotoUrl ? _c("b-col", [_c("p", {
    staticClass: "text-muted"
  }, [_vm._v("ID (Current)")]), _c("div", {
    staticClass: "photo-holder"
  }, [_vm.id.existingPhotoType == "application/pdf" ? _c("div", {
    staticClass: "photo"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/pdf-icon.png"),
      alt: ""
    }
  })]) : _c("div", {
    staticClass: "photo"
  }, [_c("img", {
    attrs: {
      src: _vm.id.existingPhotoUrl,
      alt: ""
    }
  })])])]) : _vm._e(), _c("b-col", [_c("p", {
    staticClass: "text-muted"
  }, [_vm._v(" ID "), _vm.id.existingPhotoUrl ? _c("span", [_vm._v("(New)")]) : _vm._e()]), _c("div", {
    staticClass: "photo-holder"
  }, [_vm.id.pdf ? _c("div", {
    staticClass: "photo"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/pdf-icon.png"),
      alt: ""
    }
  })]) : _c("div", {
    staticClass: "photo"
  }, [_vm.id.photo ? _c("img", {
    attrs: {
      src: _vm.id.photo,
      alt: ""
    }
  }) : _c("img", {
    attrs: {
      src: require("@/assets/images/id.png"),
      alt: ""
    }
  })])])])], 1), _c("b-row", {
    staticClass: "mb-3"
  }, [_c("b-col", {
    attrs: {
      offset: "6"
    }
  }, [_c("input", {
    ref: "id",
    staticClass: "d-none",
    attrs: {
      type: "file",
      id: "identification",
      accept: "image/jpeg, image/png, image/tiff, application/pdf, .heic"
    },
    on: {
      change: function ($event) {
        return _vm.upload("id");
      }
    }
  }), _c("label", {
    staticClass: "mb-0 btn btn-sm btn-outline-secondary rounded-0",
    staticStyle: {
      background: "#ffecca",
      border: "1px solid #ffecca"
    },
    attrs: {
      for: "identification"
    }
  }, [_c("b-icon-upload")], 1), _vm.id.existingPhotoType == "application/pdf" ? _c("b-button", {
    staticClass: "ml-3",
    attrs: {
      variant: "info",
      size: "sm"
    },
    on: {
      click: function ($event) {
        return _vm.openPDF("id");
      }
    }
  }, [_vm._v(" View PDF ")]) : _vm._e()], 1)], 1), _c("b-row", [_vm.id.existingPhotoUrl || _vm.photo.existingPhotoUrl ? _c("b-col", [_c("p", {
    staticClass: "text-muted"
  }, [_vm._v("Photo (Current)")]), _c("div", {
    staticClass: "photo-holder"
  }, [_vm.photo.existingPhotoType == "application/pdf" ? _c("div", {
    staticClass: "photo"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/pdf-icon.png"),
      alt: ""
    }
  })]) : _c("div", {
    staticClass: "photo"
  }, [_c("img", {
    attrs: {
      src: _vm.photo.existingPhotoUrl,
      alt: ""
    }
  })])])]) : _vm._e(), _c("b-col", [_c("p", {
    staticClass: "text-muted"
  }, [_vm._v("Photo")]), _c("div", {
    staticClass: "photo-holder"
  }, [_vm.photo.pdf ? _c("div", {
    staticClass: "photo"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/pdf-icon.png"),
      alt: ""
    }
  })]) : _c("div", {
    staticClass: "photo"
  }, [_vm.photo.photo ? _c("img", {
    attrs: {
      src: _vm.photo.photo,
      alt: ""
    }
  }) : _c("img", {
    attrs: {
      src: require("@/assets/images/photo.jpg"),
      alt: ""
    }
  })])])])], 1), _c("b-row", {
    staticClass: "mb-3"
  }, [_c("b-col", {
    attrs: {
      offset: "6"
    }
  }, [_c("input", {
    ref: "photo",
    staticClass: "d-none",
    attrs: {
      type: "file",
      id: "photo",
      accept: "image/jpeg, image/png, image/tiff, application/pdf, .heic"
    },
    on: {
      change: function ($event) {
        return _vm.upload("photo");
      }
    }
  }), _c("label", {
    staticClass: "mb-0 btn btn-sm btn-outline-secondary rounded-0",
    staticStyle: {
      background: "#ffecca",
      border: "1px solid #ffecca"
    },
    attrs: {
      for: "photo"
    }
  }, [_c("b-icon-upload")], 1), _vm.photo.existingPhotoType == "application/pdf" ? _c("b-button", {
    staticClass: "ml-3",
    attrs: {
      variant: "info",
      size: "sm"
    },
    on: {
      click: function ($event) {
        return _vm.openPDF("photo");
      }
    }
  }, [_vm._v(" View PDF ")]) : _vm._e()], 1)], 1), _c("b-row", [_c("b-col", [_c("b-row", [_c("b-col", {
    attrs: {
      cols: "6"
    }
  }, [_c("b-button", {
    staticClass: "mr-3",
    attrs: {
      block: "",
      variant: "outline-dark",
      pill: "",
      disabled: _vm.buttonDisabled
    },
    on: {
      click: _vm.toggleDialog
    }
  }, [_vm._v(" Cancel ")])], 1), _c("b-col", {
    attrs: {
      cols: "6"
    }
  }, [_c("b-button", {
    attrs: {
      block: "",
      variant: "secondary",
      pill: "",
      disabled: _vm.buttonDisabled
    },
    on: {
      click: _vm.save
    }
  }, [_vm._v(" Save ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };