// services
import { ActionService } from '@/services/action.service';

// libs
import moment from 'moment';
import { mapState } from 'vuex';

// components
import TableLinks from '@/components/tables/partials/TableLinks';
import UploadDialog from '@/components/patients/UploadDialog';
import ActionDialog from '@/components/actions/ActionDialog';
import ActionHistory from '@/components/actions/ActionHistory';
import ResetPasswordDialog from '@/components/patients/ResetPasswordDialog';
import ResetEmailDialog from '@/components/patients/ResetEmailDialog';
import HSAReceiptDialog from '@/components/patients/HSAReceiptDialog';
import ResetIntakeDialog from '@/components/patients/ResetIntakeDialog';
import RetryPaymentDialog from '@/components/patients/RetryPaymentDialog';
import CancelPatientDialog from '@/components/patients/CancelPatientDialog';
import DismissDialog from '@/components/patients/DismissDialog';
import DoctorRequestListDialog from '@/components/patients/DoctorRequestListDialog';
import DoctorRequestDialog from '@/components/patients/DoctorRequestDialog';
import AssignPatientDialog from '@/components/patients/AssignPatientDialog';
import RefundRequestDialog from '@/components/patients/RefundRequestDialog';
import RefundConfirmationDialog from '@/components/patients/RefundConfirmationDialog';
import RevertRemovalDialog from '@/components/patients/RevertRemovalDialog';
import MainPatientAvatar from './MainPatientAvatar.vue';

// mixins
import { patientViewMixin } from '@/mixins/patientView';

// utils
import { now } from '@corefront/utils/now';
export default {
  components: {
    MainPatientAvatar,
    TableLinks,
    UploadDialog,
    ActionDialog,
    ActionHistory,
    ResetPasswordDialog,
    ResetEmailDialog,
    HSAReceiptDialog,
    ResetIntakeDialog,
    RetryPaymentDialog,
    CancelPatientDialog,
    DismissDialog,
    DoctorRequestListDialog,
    DoctorRequestDialog,
    AssignPatientDialog,
    RefundRequestDialog,
    RefundConfirmationDialog,
    RevertRemovalDialog
  },
  mixins: [patientViewMixin],
  props: {
    type: {
      type: String,
      default: ''
    },
    headers: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedRow: {},
      toast: {
        message: '',
        variant: ''
      }
    };
  },
  computed: {
    ...mapState('patients', ['patients', 'totalPatients', 'isFetchingPatients'])
  },
  async mounted() {
    await this.$_patientView_initHeights();
  },
  methods: {
    showToast(message, variant = 'error') {
      this.toast.message = message;
      this.toast.variant = variant;
      this.$bvToast.show('alertToast');
    },
    formatBirthdayForDisplay(date) {
      if (date) {
        const dateArray = date.split('-');
        return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
      } else {
        return '(no data)';
      }
    },
    getDays(time, fallback = 'N/A') {
      if (!time) {
        return fallback;
      }
      const today = new Date();
      const dayCount = Math.round((today.getTime() - time * 1000) / (1000 * 60 * 60 * 24));
      if (dayCount == 0) {
        return 'Today';
      } else if (dayCount == 1) {
        return '1 day';
      } else {
        return `${dayCount} days`;
      }
    },
    openUploadDialog(row) {
      this.selectedRow = row;
      this.$refs.UploadDialog.toggleDialog();
    },
    updatePhoto(userid, idphoto, personalphoto) {
      const updateProperties = {};
      if (idphoto) {
        updateProperties.indentificationUrl = idphoto;
      }
      if (personalphoto) {
        updateProperties.profileUrl = personalphoto;
      }
      this.$store.commit('patients/UPDATE_PATIENT_BY_ID', {
        id: userid,
        ...updateProperties
      });
    },
    async deleteItem(id) {
      this.$store.commit('patients/REMOVE_PATIENT_BY_ID', id);
      await this.$store.dispatch('patients/getStatusCounts');
    },
    openActionDialog(row) {
      this.selectedRow = row;
      this.$refs.ActionDialog.toggleDialog();
    },
    updateTime(id) {
      this.$store.commit('patients/UPDATE_PATIENT_BY_ID', {
        id,
        datetimelastaction: now()
      });
    },
    openHistoryDialog(row) {
      this.selectedRow = row;
      this.$refs.ActionHistory.toggleDialog();
    },
    openPasswordResetDialog(row) {
      this.selectedRow = row;
      this.$refs.ResetPasswordDialog.showDialog = true;
    },
    openResetEmail(row) {
      this.selectedRow = row;
      this.$refs.ResetEmailDialog.toggleDialog();
    },
    refreshEmail(id, email) {
      this.$store.commit('patients/UPDATE_PATIENT_BY_ID', {
        id,
        email
      });
    },
    openHSADialog(row) {
      this.selectedRow = row;
      this.$refs.HSAReceiptDialog.showDialog = true;
    },
    openResetIntakeDialog(row) {
      this.selectedRow = row;
      this.$refs.ResetIntakeDialog.showDialog = true;
    },
    openRetryDialog(row) {
      this.selectedRow = row;
      this.$refs.RetryPaymentDialog.showDialog = true;
    },
    openCancelDialog(row) {
      this.selectedRow = row;
      this.$refs.CancelPatientDialog.showDialog = true;
    },
    openDismissDialog(row) {
      this.selectedRow = row;
      this.$refs.DismissDialog.showDialog = true;
    },
    async dequeueItem(id) {
      const {
        data
      } = await ActionService.dequeueItem(id, this.type);
      if (data) {
        this.deleteItem(id);
      }
    },
    async dequeueType(id, type) {
      const {
        data
      } = await ActionService.dequeueType(id, type);
      if (data) {
        this.$store.commit('patients/REMOVE_PATIENT_BY_ID', id);
      }
    },
    openRequestList(row) {
      this.selectedRow = row;
      this.$refs.DoctorRequestListDialog.showDialog = true;
    },
    openRequest(row) {
      this.selectedRow = row;
      this.$refs.DoctorRequestDialog.showDialog = true;
    },
    getDate(date) {
      return moment(date * 1000).format('MM/DD/YYYY LT');
    },
    openAcceptDialog(row) {
      this.selectedRow = row;
      this.$refs.AssignPatientDialog.showDialog = true;
    },
    openRefundRequest(row) {
      this.selectedRow = row;
      this.$refs.RefundRequestDialog.showDialog = true;
    },
    openRefundConfirmation(row) {
      this.selectedRow = row;
      this.$refs.RefundConfirmationDialog.showDialog = true;
    },
    openRevertDialog(row) {
      this.selectedRow = row;
      this.$refs.RevertRemovalDialog.showDialog = true;
    }
  }
};