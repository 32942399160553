const environment = process.env.VUE_APP_SERVER;
let constants = null;

/**
 * Note: These constants are for non-sensitive information
 * such as URLs for redirection only. In the case that the env
 * variable reading fails, production variables are used
 * by default.
 */

// Define data that's the same for all environments here
export const TYPING_INDICATOR = {
  THROTTLE_TIME: 800,
};

// Define data that's different for each environment here
if (environment == 'staging' || environment == 'local') {
  constants = Object.freeze({
    // Filter category names and respective badge colors
    pillIds: {
      SMS: 'badge-sms',
      Intercom: 'badge-intercom',
      Event: 'badge-default',
      'Information Update': 'badge-information',
      'Admin Update': 'badge-information',
      Onboarding: 'badge-primary',
      Orders: 'badge-portal',
      Refund: 'badge-refund',
      'Follow Up': 'badge-primary',
      'Doctor Reassigned': 'badge-reassign',
      Notes: 'badge-information',
      JustCall: 'badge-information',
      'Support Interaction': 'badge-intercom',
      Message: 'badge-primary',
    },
    macroCategoryId: '5ad584bf-3436-4139-aec9-7bca07e896ce',
  });
} else {
  // Production
  constants = Object.freeze({
    pillIds: {
      SMS: 'badge-sms',
      Intercom: 'badge-intercom',
      Event: 'badge-default',
      'Information Update': 'badge-information',
      'Admin Update': 'badge-information',
      Onboarding: 'badge-primary',
      Orders: 'badge-portal',
      Refund: 'badge-refund',
      'Follow Up': 'badge-primary',
      'Doctor Reassigned': 'badge-reassign',
      Notes: 'badge-information',
      JustCall: 'badge-information',
      'Support Interaction': 'badge-intercom',
    },
    macroCategoryId: '1c6d96ac-cb01-4a02-a0c1-4fcf52f71725',
  });
}

export default constants;
