import "core-js/modules/es.array-buffer.detached.js";
import "core-js/modules/es.array-buffer.transfer.js";
import "core-js/modules/es.array-buffer.transfer-to-fixed-length.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.to-reversed.js";
import "core-js/modules/es.typed-array.to-sorted.js";
import "core-js/modules/es.typed-array.with.js";
import "core-js/modules/web.dom-exception.stack.js";
import heic2any from 'heic2any';
import Tiff from 'tiff.js';
import { PatientService } from '@/services/patient.service';
export default {
  props: {
    rowData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      showDialog: false,
      alert: {
        show: false,
        message: ''
      },
      verificationData: {},
      updateID: false,
      id: {
        photo: '',
        existingPhotoUrl: '',
        existingPhotoType: '',
        pdf: false
      },
      photo: {
        photo: '',
        existingPhotoUrl: '',
        existingPhotoType: '',
        pdf: false
      },
      patientData: null,
      buttonDisabled: false
    };
  },
  methods: {
    showAlert(message) {
      this.alert.show = true;
      this.alert.message = message;
    },
    async toggleDialog() {
      if (!this.showDialog) {
        await this.$nextTick();
        const {
          data
        } = await PatientService.getPatientVerification(this.rowData.id);
        const patientData = await PatientService.getPatient(this.rowData.id);
        this.patientData = patientData.data;
        if (data.identificationURL) {
          // this.id.photo = data.identificationURL;
          this.id.existingPhotoUrl = data.identificationURL;
          this.id.existingPhotoType = data.identificationFileType;
        }
        if (data.photoURL) {
          // this.photo.photo = data.photoURL;
          this.photo.existingPhotoUrl = data.photoURL;
          this.photo.existingPhotoType = data.photoFileType;
        }
        this.showDialog = true;
      } else {
        this.id = {
          photo: '',
          existingPhotoUrl: '',
          existingPhotoType: '',
          pdf: false
        };
        this.photo = {
          photo: '',
          existingPhotoUrl: '',
          existingPhotoType: '',
          pdf: false
        };
        this.alert.show = false;
        this.showDialog = false;
      }
    },
    upload(type) {
      this.alert.show = false;
      this[type].pdf = false;
      let fileToUpload = this.$refs[type].files[0];
      // File checks
      if (!fileToUpload) return;
      if (fileToUpload.size > 25600 * 1024) {
        this.showAlert('File too big (> 25MB)');
        return;
      }
      if (!/\.(jpe?g|png|pdf|tif?f|heic)$/i.test(fileToUpload.name)) {
        this.showAlert('Please upload an image or a PDF file!');
        return;
      }
      let reader = new FileReader();
      const filetype = fileToUpload.name.split('.').pop();
      if (filetype == 'heic' || filetype == 'HEIC') {
        console.log('HEIC/HEIF detected', fileToUpload);
        heic2any({
          blob: fileToUpload,
          toType: 'image/jpeg'
        }).then(conversionResult => {
          let file = new File([conversionResult], `${type}_heic_converted.jpg`, {
            type: conversionResult.type
          });
          this[type].photo = reader.readAsDataURL(file);
        });
      }
      if (fileToUpload.type === 'image/tiff') {
        reader.addEventListener('load', function () {
          // this.picture = reader.result;
          let tiff = new Tiff({
            buffer: reader.result
          });
          let canvas = tiff.toCanvas();
          if (canvas) {
            this[type].photo = canvas.toDataURL('image/png');
          }
        }.bind(this), false);
      } else {
        reader.addEventListener('load', function () {
          this[type].photo = reader.result;
          if (fileToUpload.type === 'application/pdf') {
            this[type].pdf = true;
          }
        }.bind(this), false);
      }
      if (/\.(jpe?g|png|pdf)$/i.test(fileToUpload.name)) {
        reader.readAsDataURL(fileToUpload);
      } else if (/\.(tif?f)$/i.test(fileToUpload.name)) {
        reader.readAsArrayBuffer(fileToUpload);
      }
    },
    async save() {
      try {
        this.buttonDisabled = true;
        if (this.id.existingPhotoUrl && !this.id.photo && this.photo.existingPhotoUrl && !this.photo.photo) {
          this.toggleDialog();
        } else if (this.photo.photo == this.photo.existingPhotoUrl || !this.photo.photo) {
          await this.saveID();
          this.$emit('refresh-data');
          // this.$emit('update-photo', this.rowData.id, this.id.photo, null);
          this.toggleDialog();
        } else if (this.id.photo == this.id.existingPhotoUrl || !this.id.photo) {
          await this.savePhoto();
          // this.$emit('update-photo', this.rowData.id, null, this.photo.photo);
          this.$emit('refresh-data');
          this.toggleDialog();
        } else {
          this.buttonDisabled = true;
          await this.saveID();
          await this.savePhoto();

          // Self deletion login in verification
          if (this.$store.state.patients.tab == 'verificationrequired') {
            if (this.id.photo) {
              this.patientData.identificationupload = 'UPLOADED';
            }
            if (this.photo.photo) {
              this.patientData.photoupload = 'UPLOADED';
            }
            if (this.patientData.photoupload == 'UPLOADED' && this.patientData.identificationupload == 'UPLOADED') {
              this.$emit('delete-self', this.patientData.id);
            }
          } else {
            // NOTE: There are no photos on the tables anymore so we can remove this safely
            // this.$emit(
            //   'update-photo',
            //   this.rowData.id,
            //   this.id.photo,
            //   this.photo.photo
            // );
          }
          this.toggleDialog();
        }
      } catch (err) {
        this.showAlert(err);
      } finally {
        this.buttonDisabled = false;
      }
    },
    async saveID() {
      // ID upload
      try {
        let convertedPhoto;
        if (!(this.id.photo instanceof File)) {
          convertedPhoto = this.dataURLtoFile(this.id.photo, 'image');
        }
        let mform = new FormData();
        mform.append('identification', convertedPhoto);
        // TODO add rotationindex
        await PatientService.uploadID(this.rowData.id, mform);
      } catch (err) {
        throw err.message;
      }
    },
    async savePhoto() {
      // Photo upload
      try {
        let convertedPhoto;
        if (!(this.photo.photo instanceof File)) {
          convertedPhoto = this.dataURLtoFile(this.photo.photo, 'image');
        }
        let photoform = new FormData();
        photoform.append('photo', convertedPhoto);
        // TODO add rotationindex
        await PatientService.uploadPhoto(this.rowData.id, photoform);
      } catch (err) {
        throw err.message;
      }
    },
    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1);
        n -= 1; // to make eslint happy
      }
      return new File([u8arr], filename, {
        type: mime
      });
    },
    openPDF(type) {
      window.open(this[type].existingPhotoUrl, '_blank');
    },
    getDate(date) {
      const d = new Date(date * 1000);
      return `${d.getMonth() + 1}-${d.getDate()}-${d.getFullYear()}`;
      // const dateArray = dateObj.split('-');
      // return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
    }
  }
};