var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    attrs: {
      "hide-header": "",
      "hide-footer": "",
      centered: "",
      "no-close-on-backdrop": "",
      "no-stacking": "",
      size: "md"
    },
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c("b-alert", {
    attrs: {
      variant: "warning",
      show: _vm.alert.show
    }
  }, [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]), _c("b-row", {
    staticClass: "mb-4",
    attrs: {
      "align-h": "between"
    }
  }, [_c("b-col", {
    attrs: {
      cols: "10"
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/icon_logo_violet.svg"),
      alt: ""
    }
  })]), _c("b-col", {
    staticClass: "text-right"
  }, [_c("a", {
    staticClass: "tw-text-eclipse",
    attrs: {
      href: "javascript:;"
    },
    on: {
      click: function ($event) {
        _vm.showDialog = false;
      }
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "x-circle",
      "font-scale": "1.5"
    }
  })], 1)])], 1), _c("b-row", [_c("b-col", [_c("h2", {
    staticClass: "heading tw-mb-2 tw-text-2xl"
  }, [_vm._v("Dismiss Conversation")]), _c("p", {
    staticClass: "tw-text-muted tw-text-base"
  }, [_vm._v(" Are you sure you want to dismiss this conversation? ")])])], 1), _c("b-row", [_c("b-col", {
    staticClass: "mb-3",
    attrs: {
      cols: "12"
    }
  }, [_c("b-button", {
    attrs: {
      pill: "",
      block: "",
      variant: "secondary"
    },
    on: {
      click: function ($event) {
        return _vm.$emit("dismiss");
      }
    }
  }, [_vm._v(" Confirm ")])], 1), _c("b-col", {
    attrs: {
      cols: "12"
    }
  }, [_c("b-button", {
    attrs: {
      pill: "",
      block: "",
      variant: "outline-dark"
    },
    on: {
      click: function ($event) {
        _vm.showDialog = false;
      }
    }
  }, [_vm._v(" Cancel ")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };