import { AdminsService } from '@/services/admins.service.js';

export default {
  namespaced: true,
  state: {
    admins: [],
    isFetchingAdmins: false
  },
  mutations: {
    SET_STATE (state, payload) {
      for (const key in payload) {
        state[key] = payload[key]
      }
    }
  },
  actions: {
    async createNewAdmin({ dispatch }, payload) {
      try {
        await AdminsService.createNewAdmin(payload);
        dispatch('getAdmins');
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    async updateAdminDetails({ dispatch }, { adminid, payload }) {
      try {
        await AdminsService.updateAdminDetails(adminid, payload);
        dispatch('getAdmins');
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async getAdmins ({ commit }) {
      try {
        commit("SET_STATE", {
          isFetchingAdmins: true,
        });

        const { data } = await AdminsService.getAdmins();

        commit("SET_STATE", {
          admins: data,
        });
      } catch (error) {
        console.log(error)
        throw error
      } finally {
        commit("SET_STATE", {
          isFetchingAdmins: false,
        });
      }
    },
  },
};
