import "core-js/modules/es.array.push.js";
import moment from 'moment';
import { subMonths, startOfMonth, endOfMonth, startOfYear, getUnixTime, isBefore } from 'date-fns';
import InventoryTable from '@/components/tables/inventory/InventoryTable';
import RestockTable from '@/components/tables/inventory/RestockTable';
import UsageTable from '@/components/tables/inventory/UsageTable';
export default {
  components: {
    InventoryTable,
    RestockTable,
    UsageTable
  },
  data() {
    return {
      tab: 'inventory',
      currentFilter: 'Month to Date',
      showCustom: false,
      months: [{
        text: 'January',
        value: 0
      }, {
        text: 'February',
        value: 1
      }, {
        text: 'March',
        value: 2
      }, {
        text: 'April',
        value: 3
      }, {
        text: 'May',
        value: 4
      }, {
        text: 'June',
        value: 5
      }, {
        text: 'July',
        value: 6
      }, {
        text: 'August',
        value: 7
      }, {
        text: 'September',
        value: 8
      }, {
        text: 'October',
        value: 9
      }, {
        text: 'November',
        value: 10
      }, {
        text: 'December',
        value: 11
      }],
      years: [],
      fromMonth: null,
      fromYear: null,
      toMonth: null,
      toYear: null
    };
  },
  mounted() {
    for (let i = 2021; i <= moment().format('YYYY'); i++) {
      this.years.push(i);
    }
  },
  methods: {
    openInventoryRestockDialog() {
      this.$refs.RestockTable.openInventoryRestockDialog();
    },
    getInventoryUsage() {
      this.currentFilter = 'Custom';
      const from = getUnixTime(startOfMonth(new Date(this.fromYear, this.fromMonth, 1)));
      const to = getUnixTime(endOfMonth(new Date(this.toYear, this.toMonth, 1)));
      if (isBefore(from, to)) {
        this.$store.commit('inventory/SET_FILTERS', from, to);
        this.$refs.UsageTable.getInventoryUsage(from, to);
      } else {
        this.$bvToast.toast(`Kindly enter a valid date range`, {
          title: 'Invalid date range',
          autoHideDelay: 3000,
          variant: 'danger',
          toaster: 'b-toaster-bottom-center'
        });
      }
    },
    showErrorToast(title, msg) {
      console.log(title, msg);
      this.$bvToast.toast(msg, {
        title: title,
        autoHideDelay: 3000,
        variant: 'danger',
        toaster: 'b-toaster-bottom-center'
      });
    }
  }
};