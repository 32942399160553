var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("perfect-scrollbar", {
    staticClass: "ps"
  }, [_c("div", {
    staticClass: "d-flex flex-column"
  }, [_c("div", {
    staticClass: "d-flex justify-content-between mb-3"
  }, [_c("h1", {
    staticClass: "h1 tw-mb-0"
  }, [_vm._v(" Patients ")]), _c("a", {
    attrs: {
      href: "javascript:;"
    },
    on: {
      click: function ($event) {
        return _vm.$emit("toggle-table-list");
      }
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "layout-sidebar",
      "font-scale": "1.5",
      variant: "muted"
    }
  })], 1)]), _vm._l(_vm.TABS, function (patientTab, patientTabKey) {
    return _c("div", {
      key: patientTabKey,
      class: patientTab.name ? "mt-3" : ""
    }, [patientTab.name ? _c("b", [_vm._v(" " + _vm._s(patientTab.name) + " ")]) : _vm._e(), _vm._l(patientTab.tabs, function (item) {
      return _c("div", {
        key: item.id,
        staticClass: "d-flex flex-column"
      }, [_c("div", {
        staticClass: "d-flex justify-content-between pb-1"
      }, [_c("a", {
        staticClass: "text-primary tw-text-sm",
        attrs: {
          href: "javascript:;"
        },
        on: {
          click: function ($event) {
            return _vm.setTab(item);
          }
        }
      }, [_vm._v(" " + _vm._s(item.text) + " ")]), _c("b-badge", {
        staticClass: "pt-2",
        staticStyle: {
          "font-size": "0.75em",
          height: "2em"
        },
        attrs: {
          pill: "",
          variant: _vm.tab == item.id ? "primary" : "white"
        }
      }, [_vm._v(" " + _vm._s(_vm.statusCounts[item.id] || 0) + " ")])], 1)]);
    })], 2);
  })], 2)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };