var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.entry.data.eventCategory === _vm.EVENT_CATEGORY.DOCTOR_REASSIGNED && _vm.entry.type === _vm.ENTRY_TYPES.PATIENT_TRANSFER ? _c("ChatBubble", _vm._g({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      "sent-date-unix": _vm.timestamp,
      "event-name": _vm.entry.data.eventName,
      "message-type": _vm.CHAT_TYPE.EVENT,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function () {
        return [_c("div", {
          domProps: {
            innerHTML: _vm._s(_vm.getDoctorReassignedMessage(_vm.entry))
          }
        })];
      },
      proxy: true
    }], null, false, 1581665068)
  }, _vm.listeners)) : _vm.entry.type === _vm.ENTRY_TYPES.MESSAGE || _vm.entry.type === _vm.ENTRY_TYPES.ATTACHMENT ? _c("ChatBubble", _vm._g({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-url": _vm.communicationRole === _vm.COMMUNICATION_ROLE.RECEIVER ? _vm.entry.data.patient.avatarUrl : null,
      "avatar-text": _vm.communicationRole === _vm.COMMUNICATION_ROLE.SENDER ? _vm.entry.data.sender.firstName.charAt(0) : null,
      message: _vm.entry.data.message,
      "sent-date-unix": _vm.entry.data.sentAt,
      "sender-name": _vm.entry.data.sender.firstName,
      attachments: _vm.entry.data.attachments,
      "send-status": _vm.entry.sentStatus,
      seen: _vm.isSeenByPatient,
      "seen-date-unix": _vm.patientLastSeenTimestamp,
      "hide-name": ""
    }
  }, _vm.listeners)) : _vm.entry.data.eventCategory === _vm.EVENT_CATEGORY.NOTES ? _c("ChatBubble", _vm._g({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.entry.data.noteBy.charAt(0),
      message: _vm.entry.data.messageBody,
      "sent-date-unix": _vm.timestamp,
      "sender-name": _vm.entry.data.noteBy,
      "message-type": _vm.CHAT_TYPE.NOTES,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    }
  }, _vm.listeners)) : _vm.entry.data.eventCategory === _vm.EVENT_CATEGORY.SMS ? _c("ChatBubble", _vm._g({
    attrs: {
      "communication-role": _vm.communicationRole,
      message: _vm.entry.data.messageBody,
      "sent-date-unix": _vm.timestamp,
      "event-name": _vm.entry.data.eventName,
      "message-type": _vm.entry.data.eventName === _vm.EVENT_NAME.OUTGOING ? _vm.CHAT_TYPE.ADMIN : _vm.CHAT_TYPE.EVENT,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    }
  }, _vm.listeners)) : _vm.entry.data.eventCategory === _vm.EVENT_CATEGORY.ONBOARDING ? _c("ChatBubble", _vm._g({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      "sent-date-unix": _vm.timestamp,
      "event-name": _vm.entry.data.eventName,
      "message-type": _vm.CHAT_TYPE.EVENT,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    }
  }, _vm.listeners)) : _vm.entry.data.eventCategory === _vm.EVENT_CATEGORY.FOLLOW_UP ? _c("ChatBubble", _vm._g({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      "sent-date-unix": _vm.timestamp,
      "event-name": _vm.entry.data.eventName,
      "message-type": _vm.CHAT_TYPE.EVENT,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    }
  }, _vm.listeners)) : _vm.entry.data.eventCategory === _vm.EVENT_CATEGORY.EVENT ? _c("ChatBubble", _vm._g({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      message: _vm.entry.data.messageBody,
      "sent-date-unix": _vm.timestamp,
      "event-name": _vm.entry.data.eventName,
      "message-type": _vm.CHAT_TYPE.EVENT,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    }
  }, _vm.listeners)) : _vm.entry.data.eventCategory === _vm.EVENT_CATEGORY.ORDERS && _vm.entry.data.eventName === _vm.EVENT_NAME.PAYMENT_FAILED ? _c("ChatBubble", _vm._g({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      message: _vm.entry.data.messageBody,
      "sent-date-unix": _vm.timestamp,
      "event-name": _vm.entry.data.eventName,
      "message-type": _vm.CHAT_TYPE.EVENT,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function () {
        return [_c("div", {
          staticClass: "tw-flex tw-flex-col tw-gap-2"
        }, [_c("div", [_c("span", {
          staticClass: "tw-text-muted tw-text-xs tw-mb-[-3px] tw-block"
        }, [_vm._v("Payment Failed:")]), _c("p", {
          staticClass: "tw-m-0 tw-text-black"
        }, [_vm._v(" " + _vm._s(_vm.entry.data.paymentFailed) + " ")])]), _c("div", [_c("span", {
          staticClass: "tw-text-muted tw-text-xs tw-mb-[-3px] tw-block"
        }, [_vm._v("Stripe Decline Code:")]), _c("p", {
          staticClass: "tw-m-0 tw-text-black"
        }, [_vm._v(" " + _vm._s(_vm.entry.data.stripeDeclineCode) + " ")])]), _c("div", [_c("span", {
          staticClass: "tw-text-muted tw-text-xs tw-mb-[-3px] tw-block"
        }, [_vm._v("Stripe Error:")]), _c("p", {
          staticClass: "tw-m-0 tw-text-black"
        }, [_vm._v(" " + _vm._s(_vm.entry.data.stripeDesc) + " ")])]), _c("div", [_c("span", {
          staticClass: "tw-text-muted tw-text-xs tw-mb-[-3px] tw-block"
        }, [_vm._v("Next Step:")]), _c("p", {
          staticClass: "tw-m-0 tw-text-black"
        }, [_vm._v(" " + _vm._s(_vm.entry.data.stripeNextStep) + " ")])])])];
      },
      proxy: true
    }])
  }, _vm.listeners)) : _vm.entry.data.eventCategory === _vm.EVENT_CATEGORY.ORDERS ? _c("ChatBubble", _vm._g({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      message: _vm.entry.data.messageBody,
      "sent-date-unix": _vm.timestamp,
      "event-name": _vm.entry.data.eventName,
      "message-type": _vm.CHAT_TYPE.EVENT,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    }
  }, _vm.listeners)) : _vm.entry.data.eventCategory === _vm.EVENT_CATEGORY.INTERCOM ? _c("ChatBubble", _vm._g({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      message: _vm.entry.data.messageBody,
      "sent-date-unix": _vm.timestamp,
      "message-type": _vm.CHAT_TYPE.EVENT,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    }
  }, _vm.listeners)) : _vm.entry.data.eventCategory === _vm.EVENT_CATEGORY.REFUND ? _c("ChatBubble", _vm._g({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      "sent-date-unix": _vm.timestamp,
      "event-name": _vm.entry.data.eventName,
      "message-type": _vm.CHAT_TYPE.EVENT,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function () {
        return [_c("p", [_vm.entry.data.issuedBy ? _c("span", {
          staticClass: "tw-font-bold"
        }, [_vm._v(" " + _vm._s(_vm.entry.data.issuedBy))]) : _vm._e(), _vm._v(" refunded the following with a total amount of $" + _vm._s(_vm.entry.data.refundAmount || 0) + " ")]), _c("table", [_c("thead", [_c("tr", {
          staticClass: "tw-border-y tw-border-x-0 tw-border-solid tw-border-muted/10"
        }, [_c("th", {
          staticClass: "tw-px-2 tw-py-3 tw-break-words"
        }, [_vm._v(" Prescription Order No ")]), _c("th", {
          staticClass: "tw-px-2 tw-py-3"
        }, [_vm._v(" Medicine Name ")]), _c("th", {
          staticClass: "tw-px-2 tw-py-3"
        }, [_vm._v(" Refund Amount ")])])]), _c("tbody", [_c("tr", [_c("td", {
          staticClass: "tw-px-2 tw-py-3"
        }, [_vm._v(" " + _vm._s(_vm.entry.data.prescription.prescriptionorderno) + " ")]), _c("td", {
          staticClass: "tw-px-2 tw-py-3"
        }, [_vm._v(" " + _vm._s(_vm.entry.data.prescription.medicinename) + " ")]), _c("td", {
          staticClass: "tw-px-2 tw-py-3"
        }, [_vm._v(" $" + _vm._s(_vm.entry.data.refundAmount || 0) + " ")])])])])];
      },
      proxy: true
    }])
  }, _vm.listeners)) : _vm.entry.data.eventCategory === _vm.EVENT_CATEGORY.SUPPORT_INTERACTION ? _c("ChatBubble", _vm._g({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      "sent-date-unix": _vm.timestamp,
      "event-name": _vm.entry.data.eventName,
      "message-type": _vm.CHAT_TYPE.EVENT,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.entry.data.messageBody) + " "), _c("div", {
          staticClass: "tw-flex tw-gap-2"
        }, [_c("span", {
          staticClass: "text-muted"
        }, [_vm._v("Record URL:")]), _c("a", {
          attrs: {
            href: _vm.entry.data.transcriptionUrl
          }
        }, [_vm._v(" Listen here ")])])];
      },
      proxy: true
    }])
  }, _vm.listeners)) : _vm.entry.data.eventCategory === _vm.EVENT_CATEGORY.JUST_CALL ? _c("ChatBubble", _vm._g({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      "sent-date-unix": _vm.timestamp,
      "event-name": _vm.entry.data.eventName,
      "message-type": _vm.CHAT_TYPE.EVENT,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function () {
        return [_c("div", [_c("p", {
          staticClass: "tw-text-xs tw-text-muted"
        }, [_vm._v(" Note By: ")]), _c("p", [_vm._v(_vm._s(_vm.entry.data.noteBy))])]), _c("div", [_c("p", {
          staticClass: "tw-text-xs tw-text-muted"
        }, [_vm._v(" Call Status: ")]), _c("p", {
          staticClass: "tw-capitalize"
        }, [_vm._v(" " + _vm._s(_vm.entry.data.callStatus) + " ")])]), _c("div", [_c("p", {
          staticClass: "tw-text-xs tw-text-muted"
        }, [_vm._v(" Note Content: ")]), _c("p", [_vm._v(_vm._s(_vm.entry.data.messageBody))])])];
      },
      proxy: true
    }])
  }, _vm.listeners)) : _vm.entry.data.eventCategory === _vm.EVENT_CATEGORY.PATIENT && _vm.entry.data.eventName === _vm.EVENT_NAME.PATIENT_CANCELLED ? _c("ChatBubble", _vm._g({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      "sent-date-unix": _vm.timestamp,
      "event-name": _vm.entry.data.eventName,
      "message-type": _vm.CHAT_TYPE.EVENT,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function () {
        return [_vm.entry.data.cancelledByRole === "ADMIN" ? [_vm._v(" Admin "), _c("strong", {
          staticClass: "tw-text-black"
        }, [_vm._v(_vm._s(_vm.entry.data.cancelledByName))]), _vm._v(" has cancelled patient "), _c("strong", {
          staticClass: "tw-text-black"
        }, [_vm._v(_vm._s(_vm.entry.data.patientName))]), _vm._v(". ")] : _vm.entry.data.cancelledByRole === "DOCTOR" ? [_c("strong", {
          staticClass: "tw-text-black"
        }, [_vm._v("Dr. " + _vm._s(_vm.entry.data.cancelledByName))]), _vm._v(" has cancelled patient "), _c("strong", {
          staticClass: "tw-text-black"
        }, [_vm._v(_vm._s(_vm.entry.data.patientName))]), _vm._v(". ")] : [_c("span", [_vm._v("Patient "), _c("strong", {
          staticClass: "tw-text-black"
        }, [_vm._v(_vm._s(_vm.entry.data.patientName))]), _vm._v(" has cancelled her Winona Subscription.")])]];
      },
      proxy: true
    }])
  }, _vm.listeners)) : [_vm.EVENT_CATEGORY.ADMIN_UPDATE, _vm.EVENT_CATEGORY.INFORMATION_UPDATE].includes(_vm.entry.data.eventCategory) || _vm.entry.data.event === "Patient Migrated" ? _c("ChatBubble", _vm._g({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      "sent-date-unix": _vm.timestamp,
      "event-name": _vm.entry.data.eventName,
      "message-type": _vm.CHAT_TYPE.EVENT,
      message: _vm.entry.data.messageBody,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function () {
        return [_c("p", [_vm._v(" " + _vm._s(_vm.entry.data.messageBody) + " ")]), _c("ul", _vm._l(_vm.entry.data.changedFrom, function (formValue, formKey) {
          return _c("li", {
            key: formKey,
            staticClass: "tw-flex tw-flex-items-center tw-gap-2"
          }, [_c("span", {
            staticClass: "tw-text-muted tw-text-sm tw-capitalize tw-flex-shrink-0"
          }, [_vm._v(" " + _vm._s(_vm.getFormLabelByKey(formKey)) + ":   ")]), _c("span", [formKey == "birthday" ? _c("span", [_vm._v(" " + _vm._s(_vm.formatBirthday(formValue)) + " ")]) : _c("span", [_vm._v(_vm._s(formValue))]), _c("b-icon", {
            staticClass: "tw-mx-1",
            attrs: {
              icon: "arrow-right"
            }
          }), formKey == "birthday" ? _c("b", [_vm._v(" " + _vm._s(_vm.formatBirthday(_vm.entry.data.changedTo[formKey])) + " ")]) : _c("b", [_vm._v(" " + _vm._s(_vm.entry.data.changedTo[formKey]) + " "), !_vm.entry.data.changedTo[formKey] ? _c("span", [_vm._v("(none)")]) : _vm._e()])], 1)]);
        }), 0)];
      },
      proxy: true
    }])
  }, _vm.listeners)) : _vm.entry.data.messageBody ? _c("ChatBubble", _vm._g({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      message: _vm.entry.data.messageBody,
      "sent-date-unix": _vm.timestamp,
      "event-name": _vm.entry.data.eventName,
      "message-type": _vm.CHAT_TYPE.EVENT,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    }
  }, _vm.listeners)) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };