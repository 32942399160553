import { PatientService } from '@/services/patient.service';
export default {
  props: {
    rowData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  watch: {
    async showDialog() {
      if (!this.showDialog) {
        this.alert.show = false;
        this.firstname = '';
        this.lastname = '';
        this.birthday = '';
        this.phoneno = null;
        this.email = '';
        this.shippingaddress = {
          address1: '',
          address2: '',
          zipcode: '',
          city: ''
        };
        this.billingaddress = {
          address1: '',
          address2: '',
          zipcode: '',
          city: ''
        };
        this.editAddressMode = false;
        this.sameaddress = true;
        this.$emit('clear-data');
      } else {
        const res = await PatientService.getPatient(this.rowData.id);
        // Set data
        if (res.data.firstname) this.firstname = res.data.firstname;
        if (res.data.lastname) this.lastname = res.data.lastname;
        if (res.data.birthday) this.birthday = res.data.birthday;
        if (res.data.phoneno) this.phoneno = res.data.phoneno.replace('+1', '');
        if (res.data.email) this.email = res.data.email;
        if (res.data.shippingaddress) {
          this.shippingaddress = res.data.shippingaddress;
        }
        if (!res.data.sameaddress) {
          this.sameaddress = false;
        } else {
          this.sameaddress = res.data.sameaddress;
        }
        if (res.data.billingaddress) {
          this.billingaddress = res.data.billingaddress;
        }
      }
    }
  },
  data() {
    return {
      showDialog: false,
      alert: {
        show: false,
        message: ''
      },
      // Form date
      firstname: '',
      lastname: '',
      gender: 'Female',
      birthday: '',
      phoneno: null,
      email: '',
      shippingaddress: {
        address1: '',
        address2: '',
        zipcode: '',
        city: ''
      },
      billingaddress: {
        address1: '',
        address2: '',
        zipcode: '',
        city: ''
      },
      editAddressMode: false,
      sameaddress: true,
      buttonDisabled: false
    };
  },
  computed: {
    addressDisplay() {
      if (!this.shippingaddress.address1) {
        return '';
      } else {
        return `${this.shippingaddress.address1}, ${this.shippingaddress.city}, ${this.shippingaddress.state}`;
      }
    },
    age() {
      let birthday = this.birthday.split('-').map(Number);
      let today = new Date();
      let currentYear = today.getFullYear();
      let currentMonth = today.getMonth();
      let currentDay = today.getDate();
      let age = currentYear - birthday[0];
      if (currentMonth < birthday[1] - 1) age--;
      if (birthday[1] - 1 == currentMonth && currentDay < birthday[2]) age--;
      return age;
    }
  },
  methods: {
    toggleDialog() {
      this.showDialog = !this.showDialog;
    },
    showAlert(message) {
      this.alert.show = true;
      this.alert.message = message;
    },
    async submitChanges() {
      try {
        this.buttonDisabled = true;
        // Address changes
        if (this.editAddressMode) {
          let shippingData = {
            address1: this.shippingaddress.address1,
            address2: this.shippingaddress.address2,
            zipcode: this.shippingaddress.zipcode,
            country: 'US',
            city: this.shippingaddress.city
          };
          await PatientService.updateShippingAddress(this.rowData.id, shippingData);
          if (this.sameaddress) {
            await PatientService.updateBillingAddress(this.rowData.id, {
              sameaddress: true
            });
          } else {
            let billingData = {
              address1: this.billingaddress.address1,
              address2: this.billingaddress.address2,
              zipcode: this.billingaddress.zipcode,
              country: 'US',
              state: this.billingaddress.state,
              city: this.billingaddress.city
            };
            await PatientService.updateBillingAddress(this.rowData.id, {
              sameaddress: false,
              billingaddress: billingData
            });
          }
        }

        // Email
        if (this.email != this.rowData.email) {
          await PatientService.updatePatientEmail(this.rowData.id, {
            email: this.email
          });
        }

        // Rest of the data
        let userData = {
          firstname: this.firstname,
          lastname: this.lastname,
          phoneno: this.phoneno,
          birthday: this.birthday
        };
        const res = await PatientService.updatePatientData(this.rowData.id, userData);
        if (res) {
          this.buttonDisabled = false;
          this.$emit('refresh-email', this.rowData.id, this.email);
          this.toggleDialog();
        }
      } catch (err) {
        this.buttonDisabled = false;
        this.showAlert(err);
      }
    }
  }
};