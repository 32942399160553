var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "my-4 ml-1"
  }, [_c("b-form-group", {
    staticClass: "mb-0",
    attrs: {
      "label-for": "filter-input"
    }
  }, [_c("b-input-group", {
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function () {
        return [_c("b-input-group-text", [_c("i", {
          staticClass: "fe fe-search tw-text-[#353148]"
        })])];
      },
      proxy: true
    }])
  }, [_c("b-form-input", {
    attrs: {
      id: "filter-input",
      type: "search",
      placeholder: "Search..."
    },
    on: {
      input: _vm.onAnalyticsSearch
    },
    model: {
      value: _vm.searchText,
      callback: function ($$v) {
        _vm.searchText = $$v;
      },
      expression: "searchText"
    }
  })], 1)], 1), _c("b-card", {
    staticClass: "tw-mt-4",
    attrs: {
      "no-body": ""
    }
  }, [_c("b-table", {
    ref: "table",
    staticClass: "card-table",
    staticStyle: {
      "max-height": "calc(100vh - 25vh) !important"
    },
    attrs: {
      responsive: "",
      "select-mode": "single",
      fields: _vm.headers,
      items: _vm.items,
      "sticky-header": "",
      id: "doctorAnalytics"
    },
    nativeOn: {
      scroll: function ($event) {
        return _vm.onScroll.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "thead-top",
      fn: function () {
        return [_c("b-tr", [_c("b-th"), _c("b-th", {
          staticClass: "onboarding",
          attrs: {
            colspan: "3",
            variant: "secondary"
          }
        }, [_vm._v(" Onboarding ")]), _c("b-th", {
          staticClass: "followup",
          attrs: {
            colspan: "3",
            variant: "secondary"
          }
        }, [_vm._v(" 10 Week Follow-up ")]), _c("b-th", {
          staticClass: "metrics",
          attrs: {
            colspan: "2",
            variant: "secondary"
          }
        }, [_vm._v(" Time Metrics ")])], 1)];
      },
      proxy: true
    }, {
      key: "cell(name)",
      fn: function (row) {
        return [_c("span", {
          staticStyle: {
            color: "#984422"
          }
        }, [_vm._v(" " + _vm._s(row.item.name) + " ")])];
      }
    }, {
      key: "cell(noprescription)",
      fn: function (row) {
        return [_c("div", [_vm._v(_vm._s(row.item.noprescription) + "%")])];
      }
    }, {
      key: "cell(notanswered)",
      fn: function (row) {
        return [_c("div", [_vm._v(_vm._s(row.item.notanswered) + "%")])];
      }
    }, {
      key: "cell(discontinued)",
      fn: function (row) {
        return [_c("div", [_vm._v(_vm._s(row.item.discontinued) + "%")])];
      }
    }, {
      key: "cell(nofollowup)",
      fn: function (row) {
        return [_c("div", [_vm._v(_vm._s(row.item.nofollowup) + "%")])];
      }
    }, {
      key: "cell(timetoaccept)",
      fn: function (row) {
        return [_c("div", [_vm._v(" " + _vm._s(row.item.timetoaccept ? `${row.item.timetoaccept} ${_vm.pluralize(row.item.timetoaccept, "minute")}` : "") + " ")])];
      }
    }])
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };