import { BaseService } from './base.service';
import { ErrorWrapper, ResponseWrapper } from './util';

export class DoctorService extends BaseService {
  static async getDoctors({
    page = '',
    search = '',
    pageSize
  } = {}) {
    try {
      const response = await this.request({ auth: true, v2: true }).post(
        `/admin/doctorDashboard`,
        {
          page,
          search,
          ...(pageSize && { pageSize })
        }
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getAllDoctors () {
    try {
      const response = await this.request({ auth: true }).get('/admin/doctors');
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async ignoreDoctorConversation (conversationId) {
    try {
      const response = await this.request({ auth: true, v2: true }).post(`/admin/conversation/${conversationId}/ignore`);
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getDoctorsByState (state) {
    try {
      const response = await this.request({ auth: true }).get(
        `/admin/doctor/state/${state}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async setDoctorSettings (id, data) {
    try {
      const response = await this.request({ auth: true }).post(
        `/admin/doctor/${id}/settings`,
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getReassignments({
    page = '',
  }) {
    try {
      const response = await this.request({ auth: true, v2: true }).post(
        '/admin/doctorReassignment',
        {
          page,
          sortDirection: 'DESC',
          sortField: 'startdatestamp',
        }
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getReassignment (id) {
    try {
      const response = await this.request({ auth: true }).get(
        `/doctorreassignment/${id}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async addReassignment (body) {
    try {
      const response = await this.request({ auth: true }).post(
        '/doctorreassignment',
        body
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updateReassignment (id, body) {
    try {
      const response = await this.request({ auth: true }).put(
        `/doctorreassignment/${id}`,
        body
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async approveReassignment (id) {
    try {
      const response = await this.request({ auth: true }).put(
        `/doctorreassignment/${id}/approve`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getDoctorEarning (start, end, search = null) {
    try {
      const dateString = start ? `?start=${start}&end=${end}` : '';
      const searchString = search ? `&search=${search}` : '';
      const response = await this.request({ auth: true }).get(
        `/billing${dateString}${searchString}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async reassignPatient (id, body) {
    /**
     * body: {
     *  doctorid: String
     * }
     */
    try {
      const response = await this.request({ auth: true }).post(
        `/doctorreassignment/transfer/patient/${id}`,
        body
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async deleteReassignment(id) {
    try {
      const response = await this.request({ auth: true }).delete(
        `/doctorreassignment/${id}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async cancelReassignment (id) {
    try {
      const response = await this.request({ auth: true }).put(
        `/doctorreassignment/${id}/cancel`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getDoctorAnalytics (start, end, nextpage) {
    try {
      let response;
      if (nextpage) {
        response = await this.request({ auth: true }).get(
          `/admin/doctor/analytic?startdate=${start}&enddate=${end}&nextpage=${nextpage}`
        );
      } else {
        response = await this.request({ auth: true }).get(
          `/admin/doctor/analytic?startdate=${start}&enddate=${end}`
        );
      }
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getDoctorAnalyticsSearch (start, end, searchText) {
    try {
      const response = await this.request({ auth: true }).get(
        `/admin/doctor/analytic/search?startdate=${start}&enddate=${end}&contains=${searchText}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
