var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("EarningSearch"), _c("b-table", {
    ref: "table",
    staticClass: "card-table pb-4",
    staticStyle: {
      "max-height": "calc(100vh - 30vh) !important"
    },
    attrs: {
      responsive: "",
      "select-mode": "single",
      fields: _vm.headers,
      items: _vm.filteredItems,
      "sticky-header": "",
      fixed: ""
    },
    scopedSlots: _vm._u([{
      key: "cell(lastname)",
      fn: function (row) {
        return [_vm._v(" Dr. " + _vm._s(row.item.firstname) + " " + _vm._s(row.item.lastname) + " ")];
      }
    }, {
      key: "cell(monthyear)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.getBillingPeriodString(row.item.month, row.item.year)) + " ")];
      }
    }, {
      key: "cell(totalnewpatients)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.patients.new) + " ")];
      }
    }, {
      key: "cell(transferredpatients)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.patients.transferfollowup) + " ")];
      }
    }, {
      key: "cell(messageadjusted)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.patients.messageadjusted) + " ")];
      }
    }, {
      key: "cell(tenweekfollowups)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.patients.followuptenweek || 0) + " ")];
      }
    }, {
      key: "cell(oneyearfollowups)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.patients.followuponeyear || 0) + " ")];
      }
    }, {
      key: "cell(totalfollowups)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.patients.followup || 0) + " ")];
      }
    }, {
      key: "cell(grandtotal)",
      fn: function (row) {
        return [_vm._v(" $" + _vm._s(row.item.earning.total) + " ")];
      }
    }])
  }), _c("div", {
    staticClass: "tw-grid tw-grid-cols-9 tw-px-4 tw-py-5 tw-gap-x-2 tw-bg-[#f4f7f8]"
  }, [_c("div", {
    staticClass: "tw-col-span-2"
  }, [_vm._v(" Total ")]), _c("div", {
    staticClass: "tw-col-span-1"
  }, [_vm._v(" " + _vm._s(_vm.totals.totalPatients) + " ")]), _c("div", {
    staticClass: "tw-col-span-1"
  }, [_vm._v(" " + _vm._s(_vm.totals.totalTransferPatients) + " ")]), _c("div", {
    staticClass: "tw-col-span-1"
  }, [_vm._v(" " + _vm._s(_vm.totals.totalPatientsMessageAdjusted) + " ")]), _c("div", {
    staticClass: "tw-col-span-1"
  }, [_vm._v(" " + _vm._s(_vm.totals.totalTenWeek) + " ")]), _c("div", {
    staticClass: "tw-col-span-1"
  }, [_vm._v(" " + _vm._s(_vm.totals.totalOneYear) + " ")]), _c("div", {
    staticClass: "tw-col-span-1"
  }, [_vm._v(" " + _vm._s(_vm.totals.totalFollowups) + " ")]), _c("div", {
    staticClass: "tw-col-span-1 tw-ml-1"
  }, [_vm._v(" $" + _vm._s(_vm.totals.grandTotal) + " ")])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };