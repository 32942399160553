export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isFixedWidth: {
      type: Boolean,
      default: false
    },
    isFull: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  mounted() {
    window.addEventListener('keydown', this.keydownListenerCallback);
  },
  destroyed() {
    window.removeEventListener('keydown', this.keydownListenerCallback);
  },
  methods: {
    keydownListenerCallback(event) {
      if (event.key === 'Escape') {
        this.isShow = false;
      }
    },
    hide() {
      this.isShow = false;
    },
    show() {
      this.isShow = true;
    }
  }
};