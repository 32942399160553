var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("svg", {
    attrs: {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 14.84 18.51",
      width: "13",
      height: "16",
      fill: "currentColor"
    }
  }, [_c("path", {
    staticClass: "cls-1",
    attrs: {
      d: "M.19,6.01h14.64c0,1.63,0,3.23,0,4.82,0,2.06.02,4.13,0,6.19-.02,1.45-.75,1.86-1.99,1.14-1.32-.76-2.65-1.51-3.94-2.31-.89-.55-1.74-.64-2.67-.1-1.49.87-2.99,1.72-4.51,2.53-.89.48-1.68.13-1.69-.83-.04-3.76,0-7.51.01-11.27,0-.06.11-.12.16-.17Z"
    }
  }), _c("path", {
    staticClass: "cls-1",
    attrs: {
      d: "M14.75,3.63H.08C-.13,1.4,1.01.04,3.13.02c2.85-.03,5.7-.03,8.55,0,2.13.02,3.27,1.36,3.08,3.61Z"
    }
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };