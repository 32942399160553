import "core-js/modules/es.array.push.js";
import { DoctorService } from '@/services/doctor.service';
import $cookies from 'vue-cookies';
export default {
  data() {
    return {
      search: ''
    };
  },
  computed: {
    storeSearchQuery() {
      return this.$store.state.earnings.searchQuery;
    }
  },
  watch: {
    storeSearchQuery(val) {
      if (!val) {
        this.search = '';
      }
    }
  },
  methods: {
    clearSearch() {
      this.search = '';
      this.$refs.searchInput.focus();
      this.$store.commit('earnings/EMPTY_SEARCH_RESULTS');
    },
    async searchEarning() {
      if (!this.search) {
        this.$store.commit('earnings/EMPTY_SEARCH_RESULTS');
        this.$store.commit('earnings/SET_SEARCH_QUERY', '');
      } else {
        try {
          const filterFrom = this.$store.state.earnings.from;
          const filterTo = this.$store.state.earnings.to;
          const {
            data
          } = await DoctorService.getDoctorEarning(filterFrom, filterTo, this.search);
          this.$store.commit('earnings/SET_SEARCH_RESULTS', data);
          this.$store.commit('earnings/SET_SEARCH_QUERY', this.search);
        } catch (err) {
          if (err.status == 401) {
            $cookies.remove('accessToken');
            this.$router.push('/login?expired=true');
          }
        }
      }
    }
  }
};