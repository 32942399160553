var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.message.type === _vm.MESSAGE_TYPE.TEXT || _vm.message.type === _vm.MESSAGE_TYPE.ATTACHMENT ? _c("ChatBubble", _vm._g({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-url": _vm.profileUrl,
      message: _vm.message.message,
      "sent-date-unix": _vm.message.sentat,
      "sender-name": `${_vm.member.firstName} ${_vm.member.lastName}`,
      attachments: _vm.message.attachment,
      "hide-name": "",
      "send-status": _vm.message.sentStatus,
      "bubble-background-class": _vm.communicationRole === _vm.COMMUNICATION_ROLE.RECEIVER ? "tw-bg-peach" : ""
    }
  }, _vm.listeners)) : _vm.message.type === _vm.MESSAGE_TYPE.FORWARD_MESSAGE ? _c("ChatBubble", _vm._g({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-url": _vm.profileUrl,
      message: _vm.message.message,
      "sent-date-unix": _vm.message.sentat,
      attachments: _vm.message.attachment,
      "message-type": _vm.CHAT_TYPE.EVENT,
      "event-name": _vm.member.firstName,
      "hide-name": "",
      "send-status": _vm.message.sentStatus,
      "bubble-background-class": _vm.communicationRole === _vm.COMMUNICATION_ROLE.RECEIVER ? "tw-bg-peach" : ""
    },
    scopedSlots: _vm._u([{
      key: "header-actions",
      fn: function () {
        return [_c("button", {
          on: {
            click: function ($event) {
              return _vm.openRequestDialog(_vm.message.custommessage.forwardmessage);
            }
          }
        }, [_vm._v(" View ")])];
      },
      proxy: true
    }, {
      key: "message",
      fn: function () {
        return [_c("div", {
          staticClass: "tw-flex tw-flex-col tw-gap-2"
        }, _vm._l(_vm.reversedForwardMessages, function (msg, msgKey) {
          return _c("ChatBubble", _vm._g({
            key: msgKey,
            attrs: {
              "communication-role": msg.usertype === _vm.ROLES.PATIENT ? _vm.COMMUNICATION_ROLE.RECEIVER : _vm.COMMUNICATION_ROLE.SENDER,
              message: msg.message,
              "sent-date-unix": msg.sentat,
              "sender-name": msg.firstname,
              attachments: msg.attachment,
              "send-status": _vm.message.sentStatus,
              "hide-avatar": ""
            }
          }, _vm.listeners));
        }), 1)];
      },
      proxy: true
    }])
  }, _vm.listeners)) : _c("ChatBubble", _vm._g({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-url": _vm.profileUrl,
      message: _vm.message.message,
      "sent-date-unix": _vm.message.sentat,
      attachments: _vm.message.attachment,
      "message-type": _vm.CHAT_TYPE.EVENT,
      "event-name": _vm.member.firstName,
      "hide-name": "",
      "send-status": _vm.message.sentStatus,
      "bubble-background-class": _vm.communicationRole === _vm.COMMUNICATION_ROLE.RECEIVER ? "tw-bg-peach" : ""
    }
  }, _vm.listeners)), _c("DoctorRequestDialog", {
    ref: "DoctorRequestDialog",
    attrs: {
      "row-data": _vm.selectedForwardedRequest,
      "show-message-button": false
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };