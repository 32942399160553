// utils
import { isValidImageUrl } from '@/utils/isValidImageUrl';
export default {
  props: {
    src: {
      type: String,
      default: ''
    },
    fallback: {
      type: String,
      default: ""
    },
    alt: {
      type: String,
      default: ""
    },
    objectFit: {
      type: String,
      default: 'cover'
    }
  },
  data() {
    return {
      srcOrFallback: this.src,
      loading: false
    };
  },
  computed: {
    objectFitClass() {
      switch (this.objectFit) {
        case 'cover':
          return 'tw-object-cover';
        case 'contain':
          return 'tw-object-contain';
        default:
          return '';
      }
    }
  },
  async mounted() {
    this.validateImage();
  },
  methods: {
    async validateImage() {
      try {
        this.loading = true;
        const {
          src,
          fallback
        } = this;
        const isImageUrlValid = await isValidImageUrl(src);
        this.srcOrFallback = isImageUrlValid ? src : fallback;
      } finally {
        this.loading = false;
      }
    }
  }
};