
export const TAB_ID = {
  ALL: 'all',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  BANNED: 'banned',
  FULLY_CANCELLED: 'fullycancelled',
  DELETED: 'deleted',
  TRANSFER_RX: 'transferrx',
  LIFE_CYCLE_ONBOARDING: 'onboarding',
  LIFE_CYCLE_FAILED_ADDRESS: 'failedaddress',
  LIFE_CYCLE_VERIFICATION_REQUIRED: 'verificationrequired',
  LIFE_CYCLE_VERIFICATION_REQUIRED_DOCTOR: 'verificationrequireddoctor',
  LIFE_CYCLE_NOT_ACCEPTED: 'notaccepted',
  FOLLOW_UP_START: 'followupstart',
  FOLLOW_UP_COMPLETION: 'followupcompletion',
  ACTIONS_NEEDS_PATIENT_REPLY: 'needspatientreply',
  ACTIONS_CS_HOLD: 'cs-hold',
  ACTIONS_DOCTOR_REQUEST: 'doctorrequest',
  ACTIONS_VOICEMAIL: 'voicemail',
  PAYMENT_FAILED_TRANSACTION: 'failedtransaction',
  PAYMENT_CARD_EXPIRED: 'card-expired',
  PAYMENT_CANCELLED_BY_STRIPE: 'cancelled-by-stripe',
  PAYMENT_REFUND_REQUEST: 'refundrequest',
  MONITORING_NO_FOLLOW_UP: 'no-follow-up',
  MONITORING_PRESCRIPTION_END_BEFORE_FOLLOWUP: 'prescription-end-before-followup',
  MONITORING_CARD_UPDATED_WITHOUT_PRESCRIPTION_RESTART: 'card-updated-without-prescription-restart',
  MONITORING_SCRIPT_END_BEFORE_FOLLOWUP_NO_DHEA: 'script-end-before-followup-no-dhea',
  OUTREACH_ABANDONED_CHECKOUT: 'outreachAbandonedCheckout',
  OUTREACH_ABANDONED_VERIFICATION: 'outreachAbandonedVerification',
  OUTREACH_ABANDONED_TREATMENT_SELECTION: 'outreachAbandonedTreatmentSelection',
  OUTREACH_ABANDONED_QUESTIONNAIRE: 'outreachAbandonedQuestionnaire',
}

export const TABS = [
  {
    tabs: [
      {
        id: TAB_ID.ALL,
        text: 'All'
      },
      {
        id: TAB_ID.ACTIVE,
        text: 'Active'
      },
      {
        id: TAB_ID.INACTIVE,
        text: 'Inactive'
      },
      {
        id: TAB_ID.BANNED,
        text: 'Banned'
      },
      {
        id: TAB_ID.FULLY_CANCELLED,
        text: 'Fully Cancelled'
      },
      {
        id: TAB_ID.DELETED,
        text: 'Removed'
      },
      {
        id: TAB_ID.TRANSFER_RX,
        text: 'TransferRX'
      },
    ]
  },
  {
    name: 'Lifecycle',
    tabs: [
      {
        id: TAB_ID.LIFE_CYCLE_ONBOARDING,
        text: 'Onboarding'
      },
      {
        id: TAB_ID.LIFE_CYCLE_FAILED_ADDRESS,
        text: 'Address Verification'
      },
      {
        id: TAB_ID.LIFE_CYCLE_VERIFICATION_REQUIRED,
        text: 'Verification Required (Patient)',
      },
      {
        id: TAB_ID.LIFE_CYCLE_VERIFICATION_REQUIRED_DOCTOR,
        text: 'Verification Required (Doctor)',
      },
      {
        id: TAB_ID.LIFE_CYCLE_NOT_ACCEPTED,
        text: 'Not Accepted'
      },
    ],
  },
  {
    name: 'Follow Up',
    tabs: [
      {
        id: TAB_ID.FOLLOW_UP_START,
        text: 'Follow Up Start'
      },
      {
        id: TAB_ID.FOLLOW_UP_COMPLETION,
        text: 'Follow Up Complete'
      },
    ],
  },
  {
    name: 'Actions',
    tabs: [
      {
        id: TAB_ID.ACTIONS_NEEDS_PATIENT_REPLY,
        text: 'Needs Reply'
      },
      {
        id: TAB_ID.ACTIONS_CS_HOLD,
        text: 'Order Holds'
      },
      {
        id: TAB_ID.ACTIONS_DOCTOR_REQUEST,
        text: 'Doctor Request'
      },
      {
        id: TAB_ID.ACTIONS_VOICEMAIL,
        text: 'Voicemail'
      },
    ],
  },
  {
    name: 'Payment',
    tabs: [
      {
        id: TAB_ID.PAYMENT_FAILED_TRANSACTION,
        text: 'Failed Transaction'
      },
      {
        id: TAB_ID.PAYMENT_CARD_EXPIRED,
        text: 'Card Expired'
      },
      {
        id: TAB_ID.PAYMENT_CANCELLED_BY_STRIPE,
        text: 'Cancelled By Stripe'
      },
      {
        id: TAB_ID.PAYMENT_REFUND_REQUEST,
        text: 'Refund Request'
      },
    ],
  },
  {
    name: 'Monitoring',
    tabs: [
      {
        id: TAB_ID.MONITORING_NO_FOLLOW_UP,
        text: 'No Follow Up'
      },
      {
        id: TAB_ID.MONITORING_PRESCRIPTION_END_BEFORE_FOLLOWUP,
        text: 'Prescription End Before FU',
      },
      {
        id: TAB_ID.MONITORING_CARD_UPDATED_WITHOUT_PRESCRIPTION_RESTART,
        text: 'Card Updated w/o Prescription Restart',
      },
      {
        id: TAB_ID.MONITORING_SCRIPT_END_BEFORE_FOLLOWUP_NO_DHEA,
        text: 'Script End Before Followup (No DHEA)',
      },
    ],
  },
  {
    name: 'Outreach',
    tabs: [
      {
        id: TAB_ID.OUTREACH_ABANDONED_CHECKOUT,
        text: 'Abandoned Checkout'
      },
      {
        id: TAB_ID.OUTREACH_ABANDONED_VERIFICATION,
        text: 'Abandoned Verification'
      },
      {
        id: TAB_ID.OUTREACH_ABANDONED_TREATMENT_SELECTION,
        text: 'Abandoned Treatment Selection'
      },
      {
        id: TAB_ID.OUTREACH_ABANDONED_QUESTIONNAIRE,
        text: 'Abandoned Questionnaire'
      },

    ]
  }
]

const COLUMN = {
  photo: { key: 'photo', label: '' },
  name: { key: 'name', sortable: true },
  birthday: { key: 'birthday', label: 'Birthday' },
  phoneno: { key: 'phoneno', label: 'Contact' },
  stage: { key: 'stage', label: 'Status' },
  datetimelastaction: { key: 'datetimelastaction', label: 'Last Action', sortable: true, },
  upload: { key: 'upload', label: '' },
  actions: { key: 'actions', label: '' },
  history: { key: 'history', label: '' },
  links: { key: 'links', label: '' },
  email: { key: 'email', sortable: true },
  dateonboarded: { key: 'dateonboarded', label: 'In Stage', sortable: true, },
  bannedReason: { key: 'bannedReason', label: 'Banned Reason' },
  bannedNote: { key: 'bannedNote', label: 'Banned Note' },
  bannedDate: { key: 'bannedDate', label: 'Banned Date' },
  fullycancelleddatetime: { key: 'fullycancelleddatetime', label: 'In Stage', sortable: true, },
  dismiss: { key: 'dismiss', label: '' },
  status: { key: 'status', sortable: true },
  revert: { key: 'revert', label: '' },
  signupdate: { key: 'signupdate', label: 'In Stage', sortable: true, },
  datetimestamp: { key: 'datetimestamp', label: 'In Stage', },
  state: { key: 'state', label: 'State' },
  availabledoctor: { key: 'availabledoctor', label: 'Available Doctors' },
  assign: { key: 'assign', label: '' },
  followupdatetime: { key: 'followupdatetime', label: 'In Stage', sortable: true, },
  needspatientreplydatetime: { key: 'needspatientreplydatetime', label: 'In Stage', sortable: true, },
  request: { key: 'request', label: '' },
  failedtransactionreason: { key: 'failedtransactionreason', label: 'Reason', },
  failedtransactiondatetime: { key: 'failedtransactiondatetime', label: 'In Stage', sortable: true, },
  cancel: { key: 'cancel', label: '' },
  timestampcancelled: { key: 'timestampcancelled', label: 'Date', sortable: true },
  cancellationreason: { key: 'cancellationreason', label: 'Reason' },
  cancellationnotes: { key: 'cancellationnotes', label: 'Notes' },
  view: { key: 'view', label: '' },
  refund: { key: 'refund', label: '' },
  timescalled: { key: 'timescalled', label: 'Times Called (last 14 days)' },
  timesCalled72Days: { key: 'timesCalled', label: 'Times Called (last 72 days)' },
  timestampinteraction: { key: 'timestampinteraction', label: 'Time in Stage (In Stage)' },
  timezone: { key: 'timezone', label: 'Timezone' },
}

export const HEADERS_BY_TAB_ID = {
  [TAB_ID.ALL]: [
    COLUMN.photo,
    COLUMN.name,
    COLUMN.birthday,
    COLUMN.phoneno,
    COLUMN.stage,
    COLUMN.datetimelastaction,
    COLUMN.upload,
    COLUMN.actions,
    COLUMN.history,
    COLUMN.links,
  ],
  [TAB_ID.ACTIVE]: [
    COLUMN.photo,
    COLUMN.name,
    COLUMN.email,
    COLUMN.dateonboarded,
    COLUMN.datetimelastaction,
    COLUMN.actions,
    COLUMN.history,
    COLUMN.links,
  ],
  [TAB_ID.INACTIVE]: [
    COLUMN.photo,
    COLUMN.name,
    COLUMN.email,
    COLUMN.dateonboarded,
    COLUMN.datetimelastaction,
    COLUMN.actions,
    COLUMN.history,
    COLUMN.links,
  ],
  [TAB_ID.BANNED]: [
    COLUMN.photo,
    COLUMN.name,
    COLUMN.bannedReason,
    COLUMN.bannedNote,
    COLUMN.bannedDate,
    COLUMN.history,
    COLUMN.links,
  ],
  [TAB_ID.FULLY_CANCELLED]: [
    COLUMN.photo,
    COLUMN.name,
    COLUMN.email,
    COLUMN.fullycancelleddatetime,
    COLUMN.datetimelastaction,
    COLUMN.dismiss,
    COLUMN.actions,
    COLUMN.history,
    COLUMN.links,
  ],
  [TAB_ID.DELETED]: [
    COLUMN.photo,
    COLUMN.name,
    COLUMN.birthday,
    COLUMN.phoneno,
    COLUMN.status,
    COLUMN.fullycancelleddatetime,
    COLUMN.revert,
    COLUMN.actions,
    COLUMN.history,
    COLUMN.links,
  ],
  [TAB_ID.TRANSFER_RX]: [
    COLUMN.photo,
    COLUMN.name,
    COLUMN.email,
    COLUMN.datetimelastaction,
    COLUMN.actions,
    COLUMN.history,
    COLUMN.links,
  ],
  [TAB_ID.LIFE_CYCLE_ONBOARDING]: [
    COLUMN.photo,
    COLUMN.name,
    COLUMN.birthday,
    COLUMN.phoneno,
    COLUMN.status,
    COLUMN.signupdate,
    COLUMN.datetimelastaction,
    COLUMN.dismiss,
    COLUMN.actions,
    COLUMN.history,
    COLUMN.links,
  ],
  [TAB_ID.LIFE_CYCLE_FAILED_ADDRESS]: [
    COLUMN.photo,
    COLUMN.name,
    COLUMN.email,
    COLUMN.datetimelastaction,
    COLUMN.dismiss,
    COLUMN.actions,
    COLUMN.history,
    COLUMN.links,
  ],
  [TAB_ID.LIFE_CYCLE_VERIFICATION_REQUIRED]: [
    COLUMN.photo,
    COLUMN.name,
    COLUMN.birthday,
    COLUMN.phoneno,
    COLUMN.status,
    COLUMN.datetimestamp,
    COLUMN.datetimelastaction,
    COLUMN.upload,
    COLUMN.dismiss,
    COLUMN.actions,
    COLUMN.history,
    COLUMN.links,
  ],
  [TAB_ID.LIFE_CYCLE_VERIFICATION_REQUIRED_DOCTOR]: [
    COLUMN.photo,
    COLUMN.name,
    COLUMN.birthday,
    COLUMN.phoneno,
    COLUMN.status,
    COLUMN.datetimestamp,
    COLUMN.datetimelastaction,
    COLUMN.upload,
    COLUMN.dismiss,
    COLUMN.actions,
    COLUMN.history,
    COLUMN.links,
  ],
  [TAB_ID.LIFE_CYCLE_NOT_ACCEPTED]: [
    COLUMN.photo,
    COLUMN.name,
    COLUMN.dateonboarded,
    COLUMN.state,
    COLUMN.availabledoctor,
    COLUMN.assign,
    COLUMN.actions,
    COLUMN.history,
    COLUMN.links,
  ],
  [TAB_ID.FOLLOW_UP_START]: [
    COLUMN.photo,
    COLUMN.name,
    COLUMN.email,
    COLUMN.followupdatetime,
    COLUMN.datetimelastaction,
    COLUMN.dismiss,
    COLUMN.actions,
    COLUMN.history,
    COLUMN.links,
  ],
  [TAB_ID.FOLLOW_UP_COMPLETION]: [
    COLUMN.photo,
    COLUMN.name,
    COLUMN.email,
    COLUMN.followupdatetime,
    COLUMN.datetimelastaction,
    COLUMN.dismiss,
    COLUMN.actions,
    COLUMN.history,
    COLUMN.links,
  ],
  [TAB_ID.ACTIONS_NEEDS_PATIENT_REPLY]: [
    COLUMN.photo,
    COLUMN.name,
    COLUMN.email,
    COLUMN.needspatientreplydatetime,
    COLUMN.datetimelastaction,
    COLUMN.dismiss,
    COLUMN.actions,
    COLUMN.history,
    COLUMN.links,
  ],
  [TAB_ID.ACTIONS_CS_HOLD]: [
    COLUMN.photo,
    COLUMN.name,
    COLUMN.email,
    COLUMN.datetimelastaction,
    COLUMN.actions,
    COLUMN.history,
    COLUMN.links,
  ],
  [TAB_ID.ACTIONS_DOCTOR_REQUEST]: [
    COLUMN.photo,
    COLUMN.name,
    COLUMN.email,
    COLUMN.needspatientreplydatetime,
    COLUMN.datetimelastaction,
    COLUMN.request,
    COLUMN.actions,
    COLUMN.history,
    COLUMN.links,
  ],
  [TAB_ID.ACTIONS_VOICEMAIL]: [
    COLUMN.photo,
    COLUMN.name,
    COLUMN.email,
    COLUMN.datetimelastaction,
    COLUMN.actions,
    COLUMN.history,
    COLUMN.links,
  ],
  [TAB_ID.PAYMENT_FAILED_TRANSACTION]: [
    COLUMN.photo,
    COLUMN.name,
    COLUMN.email,
    COLUMN.failedtransactionreason,
    COLUMN.failedtransactiondatetime,
    COLUMN.datetimelastaction,
    COLUMN.cancel,
    COLUMN.actions,
    COLUMN.history,
    COLUMN.links,
  ],
  [TAB_ID.PAYMENT_CARD_EXPIRED]: [
    COLUMN.photo,
    COLUMN.name,
    COLUMN.email,
    COLUMN.datetimelastaction,
    COLUMN.dismiss,
    COLUMN.actions,
    COLUMN.history,
    COLUMN.links,
  ],
  [TAB_ID.PAYMENT_CANCELLED_BY_STRIPE]: [
    COLUMN.photo,
    COLUMN.name,
    COLUMN.email,
    COLUMN.failedtransactionreason,
    COLUMN.failedtransactiondatetime,
    COLUMN.datetimelastaction,
    COLUMN.dismiss,
    COLUMN.actions,
    COLUMN.history,
    COLUMN.links,
  ],
  [TAB_ID.PAYMENT_REFUND_REQUEST]: [
    COLUMN.photo,
    COLUMN.name,
    COLUMN.timestampcancelled,
    COLUMN.cancellationreason,
    COLUMN.cancellationnotes,
    COLUMN.view,
    COLUMN.refund,
    COLUMN.actions,
    COLUMN.history,
    COLUMN.links,
  ],
  [TAB_ID.MONITORING_NO_FOLLOW_UP]: [
    COLUMN.photo,
    COLUMN.name,
    COLUMN.email,
    COLUMN.datetimelastaction,
    COLUMN.dismiss,
    COLUMN.actions,
    COLUMN.history,
    COLUMN.links,
  ],
  [TAB_ID.MONITORING_PRESCRIPTION_END_BEFORE_FOLLOWUP]: [
    COLUMN.photo,
    COLUMN.name,
    COLUMN.email,
    COLUMN.datetimelastaction,
    COLUMN.dismiss,
    COLUMN.actions,
    COLUMN.history,
    COLUMN.links,
  ],
  [TAB_ID.MONITORING_CARD_UPDATED_WITHOUT_PRESCRIPTION_RESTART]: [
    COLUMN.photo,
    COLUMN.name,
    COLUMN.email,
    COLUMN.datetimelastaction,
    COLUMN.dismiss,
    COLUMN.actions,
    COLUMN.history,
    COLUMN.links,
  ],
  [TAB_ID.MONITORING_SCRIPT_END_BEFORE_FOLLOWUP_NO_DHEA]: [
    COLUMN.photo,
    COLUMN.name,
    COLUMN.email,
    COLUMN.datetimelastaction,
    COLUMN.dismiss,
    COLUMN.actions,
    COLUMN.history,
    COLUMN.links,
  ],
  [TAB_ID.OUTREACH_ABANDONED_CHECKOUT]: [
    COLUMN.photo,
    COLUMN.name,
    COLUMN.phoneno,
    COLUMN.timezone,
    COLUMN.timesCalled72Days,
    { ...COLUMN.timestampinteraction, label: 'Time in Stage' },
    COLUMN.dismiss,
    COLUMN.actions,
    COLUMN.history,
  ],
  [TAB_ID.OUTREACH_ABANDONED_VERIFICATION]: [
    COLUMN.photo,
    COLUMN.name,
    COLUMN.phoneno,
    COLUMN.timezone,
    COLUMN.timesCalled72Days,
    { ...COLUMN.timestampinteraction, label: 'Time in Stage' },
    COLUMN.dismiss,
    COLUMN.actions,
    COLUMN.history,
  ],
  [TAB_ID.OUTREACH_ABANDONED_TREATMENT_SELECTION]: [
    COLUMN.photo,
    COLUMN.name,
    COLUMN.phoneno,
    COLUMN.timezone,
    COLUMN.timesCalled72Days,
    { ...COLUMN.timestampinteraction, label: 'Time in Stage' },
    COLUMN.dismiss,
    COLUMN.actions,
    COLUMN.history,
  ],
  [TAB_ID.OUTREACH_ABANDONED_QUESTIONNAIRE]: [
    COLUMN.photo,
    COLUMN.name,
    COLUMN.phoneno,
    COLUMN.timezone,
    COLUMN.timesCalled72Days,
    { ...COLUMN.timestampinteraction, label: 'Time in Stage' },
    COLUMN.dismiss,
    COLUMN.actions,
    COLUMN.history,
  ],
}