import _ from 'lodash';
import { mapGetters } from 'vuex';
import { DoctorService } from '@/services/doctor.service';
export default {
  data() {
    return {
      headers: [{
        key: 'photo',
        label: ''
      }, {
        key: 'name',
        label: 'Doctor'
      }, {
        key: 'coveredstate',
        label: 'State'
      }, {
        key: 'patientCount',
        label: 'Number of Patients'
      }, {
        key: 'maxdailyaccept',
        label: 'Max Daily Accept'
      }, {
        key: 'tenweekfollowupversionname',
        label: '10W Follow up Version'
      }, {
        key: 'oneyearfollowupversionname',
        label: '1Y Follow up Version'
      }, {
        key: 'actions',
        label: 'Actions'
      }],
      items: [],
      search: '',
      debouncedSearch: '',
      fetching: false,
      currentPage: 1,
      showEmptyResultsAlert: false
    };
  },
  computed: {
    ...mapGetters({
      currentPageStore: 'tables/getCurrentPage',
      currentTableRowCount: 'tables/getRowCount'
    })
  },
  watch: {
    search() {
      this.debouncer();
    },
    async debouncedSearch(val) {
      this.showEmptyResultsAlert = false;
      this.$store.commit('tables/SET_ROW_COUNT', 0);
      this.$store.commit('tables/SET_CURRENT_PAGE', 1);
      this.currentPage = 1;
      this.fetching = true;
      const {
        count
      } = await this.getDoctors({
        page: this.currentPage,
        search: val
      });
      this.fetching = false;
      if (count === 0) {
        this.showEmptyResultsAlert = true;
      }
    },
    currentPageStore(newVal) {
      this.currentPage = newVal;
      this.$store.commit('tables/SET_CURRENT_PAGE', newVal);
      this.getDoctors({
        page: newVal,
        search: this.search
      });
    }
  },
  async mounted() {
    this.$store.commit('tables/SET_CURRENT_PAGE', 1);
    this.fetching = true;
    await this.getDoctors({
      page: this.currentPage,
      search: this.search
    });
    this.fetching = false;
  },
  methods: {
    async getDoctors({
      page = 1,
      search = ''
    }) {
      this.items = [];
      this.fetching = true;
      const {
        data
      } = await DoctorService.getDoctors({
        page,
        search
      });
      this.fetching = false;
      this.items = data.items;
      this.$store.commit('tables/SET_ROW_COUNT', Number(data.count));
      return data;
    },
    // eslint-disable-next-line vue/no-unused-properties
    async getCurrentDoctorFilters() {
      const {
        data
      } = await DoctorService.getDoctors({
        page: this.currentPage,
        search: this.search
      });
      this.items = data.items;
      this.$store.commit('tables/SET_ROW_COUNT', Number(data.count));
    },
    debouncer: _.debounce(function () {
      this.debouncedSearch = this.search;
    }, 500),
    getTotalLimit(states) {
      let max = 0;
      for (const state of states) {
        max += Number(state.limit);
      }
      return max;
    },
    handlePaginationClick(event, pageNumber) {
      this.$store.commit('tables/SET_CURRENT_PAGE', pageNumber);
    },
    clearSearch() {
      this.search = '';
      this.$refs.searchInput.focus();
    }
  }
};