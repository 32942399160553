import { mapState } from 'vuex';
import { format, fromUnixTime } from 'date-fns';
// import { DoctorService } from '@/services/doctor.service';

export default {
  data() {
    return {
      headers: [{
        key: 'admin',
        label: 'Admin'
      }, {
        key: 'email',
        label: 'Email'
      }, {
        key: 'onboardeddate',
        label: 'Onboarded date'
      }, {
        key: 'role',
        label: 'Role'
      }, {
        key: 'actions',
        label: 'Actions'
      }],
      nextPage: '',
      selectedRow: null
    };
  },
  async created() {
    await this.$store.dispatch('admins/getAdmins');
  },
  computed: {
    ...mapState('admins', ['admins']),
    items() {
      return this.admins.map(item => ({
        admin: `${item.firstname || ''} ${item.lastname || ''}`,
        email: item.email,
        onboardeddate: item.signupdate ? this.parseDate(item.signupdate) : '',
        role: item.roletype ? item.roletype : '',
        item
      }));
    },
    userid() {
      return localStorage.getItem('userid');
    }
  },
  methods: {
    parseDate(date) {
      return format(fromUnixTime(date), 'MM/dd/yyyy');
    },
    onChangeRole(item) {
      this.$emit('role-change', item);
    },
    onTransferRole(item) {
      this.$emit('role-transfer', item);
    }
  }
};