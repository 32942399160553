var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    attrs: {
      "hide-header": "",
      "hide-footer": "",
      centered: "",
      "no-close-on-backdrop": "",
      "no-stacking": "",
      size: "lg"
    },
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c("b-alert", {
    attrs: {
      variant: "warning",
      show: _vm.alert.show
    }
  }, [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]), _c("b-row", {
    staticClass: "mb-4",
    attrs: {
      "align-h": "between"
    }
  }, [_c("b-col", {
    attrs: {
      cols: "10"
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/icon_logo_violet.svg"),
      alt: ""
    }
  })]), _c("b-col", {
    staticClass: "text-right"
  }, [_c("a", {
    staticClass: "tw-text-eclipse",
    attrs: {
      href: "javascript:;"
    },
    on: {
      click: function ($event) {
        _vm.showDialog = false;
      }
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "x-circle",
      "font-scale": "1.5"
    }
  })], 1)])], 1), _c("b-row", {
    staticClass: "mt-4 pr-4"
  }, [_c("b-col", {
    attrs: {
      cols: "3"
    }
  }, [_vm._v("Assign Patient")]), _c("b-col", [_c("b-row", [_c("b-col", [_c("span", {
    staticClass: "text-small text-muted"
  }, [_vm._v("Patient")]), _c("br"), _c("p", [_vm._v(_vm._s(_vm.rowData.firstname) + " " + _vm._s(_vm.rowData.lastname))]), _c("span", {
    staticClass: "text-small text-muted mt-3"
  }, [_vm._v("State")]), _c("br"), _c("p", [_vm._v(" " + _vm._s(_vm.rowData.state) + " ")]), _c("span", {
    staticClass: "text-small text-muted mt-3"
  }, [_vm._v("Onboarded Date")]), _c("p", [_vm._v(_vm._s(_vm.getDate(_vm.rowData.dateonboarded)))])]), _c("b-col", {
    staticClass: "doctor-list"
  }, [_c("span", {
    staticClass: "text-small text-muted"
  }, [_vm._v("Assign to")]), _vm._l(_vm.rowData.availabledoctor, function (doctor) {
    return _c("b-row", {
      key: doctor.id
    }, [_c("b-col", {
      staticClass: "mt-2"
    }, [_c("b-form-radio", {
      attrs: {
        name: "selectedDoctor",
        value: doctor.id
      },
      model: {
        value: _vm.selectedDoctor,
        callback: function ($$v) {
          _vm.selectedDoctor = $$v;
        },
        expression: "selectedDoctor"
      }
    }, [_vm._v(" Dr. " + _vm._s(doctor.firstname) + " " + _vm._s(doctor.lastname) + " ")]), _c("hr")], 1)], 1);
  })], 2)], 1)], 1)], 1), _c("b-row", {
    staticClass: "mt-3"
  }, [_c("b-col", {
    attrs: {
      cols: "3"
    }
  }), _c("b-col", [_c("b-button", {
    attrs: {
      variant: "outline-dark",
      block: "",
      pill: ""
    },
    on: {
      click: function ($event) {
        _vm.showDialog = false;
      }
    }
  }, [_vm._v(" Back ")])], 1), _c("b-col", [_c("b-button", {
    attrs: {
      variant: "secondary",
      block: "",
      pill: ""
    },
    on: {
      click: _vm.assignDoctor
    }
  }, [_vm._v(" Confirm ")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };