var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("svg", {
    staticStyle: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "2"
    },
    attrs: {
      width: "25",
      height: "25",
      viewBox: "0 0 35 31",
      version: "1.1",
      xmlns: "http://www.w3.org/2000/svg"
    }
  }, [_c("path", {
    staticStyle: {
      fill: "#fff",
      "fill-rule": "nonzero"
    },
    attrs: {
      d: "M17.25,30.073l-14.883,-0c-0.398,-0 -0.806,-0 -1.204,-0.072c-0.877,-0.142 -1.163,-0.499 -1.163,-1.418l0,-11.058c0,-2.499 0.01,-4.988 0,-7.487c0,-0.826 0.306,-1.428 1.01,-1.867c1.112,-0.683 2.193,-1.418 3.305,-2.101c0.398,-0.245 0.541,-0.541 0.531,-0.99c-0.021,-1.112 -0,-2.224 -0,-3.346c-0,-1.122 0.285,-1.479 1.387,-1.683c0.245,-0.051 0.5,-0.051 0.745,-0.051l21.575,0c1.285,0.061 1.724,0.5 1.744,1.765c0.021,1.193 0.021,2.377 0.021,3.57c-0,0.408 0.143,0.663 0.489,0.898c1.184,0.826 2.336,1.683 3.51,2.53c0.489,0.357 0.683,0.795 0.683,1.428c-0.031,5.917 -0.031,11.833 -0.031,17.74c0,1.52 -0.571,2.132 -2.121,2.132c-5.091,0.02 -10.191,0.01 -15.282,0.02l-0.285,0l-0.031,-0.01Zm-14.589,-17.803c-0.021,0.194 -0.05,0.322 -0.05,0.441c0,4.661 0,9.334 -0.01,13.995c0,0.5 0.276,0.48 0.623,0.49c5.09,0.123 10.17,-0.112 15.26,0.163c4.315,0.235 8.651,0.041 12.976,0.051c0.469,0 0.633,-0.132 0.633,-0.622c-0.021,-3.856 -0.011,-7.722 -0.011,-11.578l0,-2.754c-0.346,0.142 -0.571,0.234 -0.795,0.336c-4.111,2.071 -8.253,4.111 -12.333,6.243c-1.122,0.582 -2.01,0.551 -3.112,-0.03c-4.182,-2.224 -8.416,-4.377 -12.618,-6.549c-0.164,-0.082 -0.347,-0.133 -0.562,-0.204l-0.001,0.018Zm14.885,-9.403c-3.111,-0 -6.223,-0 -9.344,-0.021c-0.429,0 -0.521,0.163 -0.521,0.531c0.021,2.591 0.041,5.182 0.041,7.773c0,0.387 0.163,0.591 0.49,0.755c3.06,1.55 6.121,3.121 9.191,4.662c0.163,0.081 0.429,0.091 0.582,0.01c3.039,-1.643 6.069,-3.295 9.099,-4.968c0.173,-0.092 0.347,-0.388 0.347,-0.582c0.02,-2.509 0.01,-5.029 0.03,-7.548c0,-0.459 -0.132,-0.643 -0.622,-0.633c-3.091,0.021 -6.192,0 -9.283,0l-0.01,0.021Zm-0.091,2.193c1.765,-0 3.54,-0.031 5.305,0.02c0.52,0.01 1.051,0.184 1.55,0.367c0.419,0.153 0.643,0.521 0.623,1.01c-0.021,0.5 -0.296,0.857 -0.745,1c-0.428,0.143 -0.898,0.235 -1.357,0.235c-3.539,0.02 -7.069,0.02 -10.609,-0c-0.428,-0 -0.877,-0.113 -1.285,-0.266c-0.52,-0.193 -0.755,-0.642 -0.735,-1.193c0.021,-0.52 0.327,-0.857 0.816,-1c0.327,-0.092 0.674,-0.163 1.01,-0.163c1.806,-0.02 3.612,-0 5.417,-0l0.01,-0.01Z"
    }
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };