var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("ChatThread", {
    attrs: {
      "has-next-page": _vm.isMessagesLoaded && !!_vm.nextPage,
      messages: _vm.messages,
      "date-resolver": e => [_vm.ENTRY_TYPES.MESSAGE, _vm.ENTRY_TYPES.ATTACHMENT].includes(e.type) ? e.data.sentAt * 1000 : (e.data.timestampInteraction || e.data.actionDateTimestamp) * 1000
    },
    on: {
      "next-page": _vm.messageScroll
    },
    scopedSlots: _vm._u([{
      key: "bubble",
      fn: function ({
        message
      }) {
        return [_c("AdminPatientChatBubble", {
          attrs: {
            entry: message
          }
        })];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };