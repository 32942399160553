export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    headerClass: {
      type: String
    },
    bodyClass: {
      type: String
    },
    showClose: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    zIndex: {
      type: Number,
      default: 600
    },
    side: {
      type: String,
      default: 'right'
    },
    overflow: {
      type: String,
      default: 'visible'
    }
  },
  data() {
    return {
      height: ''
    };
  },
  watch: {
    show(val) {
      // if (this.title === "Notes") {
      //   this.$store.commit("globals/SET_ADD_NOTES_MODAL_STATE", val);
      // }
      if (val) {
        window.addEventListener('keyup', this.onEscapeKeyUp);
      } else {
        window.removeEventListener('keyup', this.onEscapeKeyUp);
      }
    }
  },
  created() {
    // addEventListener("popstate", this.popStateHandler);
  },
  methods: {
    onEscapeKeyUp(event) {
      if (event.which === 27) {
        this.close();
      }
    },
    close(event) {
      this.$emit('exit', event);
    },
    observeHeight() {
      let modal = this.$refs.sideModal.clientHeight;
      let header = this.$refs.modalHeader.clientHeight;
      this.height = modal - header - 70 + 'px';
    },
    popStateHandler(event) {
      if (this.$store.state.globals.isAddNotesModalOpen) {
        this.close(event);
      }
    }
  },
  mounted() {
    this.observeHeight();
  },
  destroyed() {
    removeEventListener('popstate', this.popStateHandler);
  }
};