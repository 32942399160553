import { DoctorService } from '@/services/doctor.service';

export default {
  namespaced: true,
  state: {
    searchResults: [],
    searchQuery: '',
    from: null,
    to: null,
    isFetchingDoctors: false,
    doctors: [],
    doctorsNextPage: null,
  },
  mutations: {
    EMPTY_SEARCH_RESULTS (state) {
      state.searchResults = [];
      state.searchQuery = '';
    },
    SET_SEARCH_RESULTS (state, results) {
      state.searchResults = results;
    },
    SET_SEARCH_QUERY (state, query) {
      state.searchQuery = query;
    },
    SET_FILTER_FROM (state, from) {
      state.from = from;
    },
    SET_FILTER_TO (state, to) {
      state.to = to;
    },
    SET_STATE (state, payload) {
      for (const key in payload) {
        state[key] = payload[key]
      }
    }
  },
  actions: {
    async getDoctors ({ commit }) {
      try {
        commit('SET_STATE', {
          isFetchingDoctors: true,
        });

        const { data } = await DoctorService.getDoctors({ pageSize: 999 });

        commit('SET_STATE', {
          doctors: data.items,
        });

        commit('SET_STATE', {
          doctorsNextPage: data.nextpage,
        });
      } catch (error) {
        console.log(error)
        throw error
      } finally {
        commit('SET_STATE', {
          isFetchingDoctors: false,
        });
      }
    },
    async getAllDoctors ({ commit }) {
      try {
        commit('SET_STATE', {
          isFetchingDoctors: true,
        });

        const { data } = await DoctorService.getAllDoctors();

        commit('SET_STATE', {
          doctors: data,
        });

        commit('SET_STATE', {
          doctorsNextPage: data.nextpage,
        });
      } catch (error) {
        console.log(error)
        throw error
      } finally {
        commit('SET_STATE', {
          isFetchingDoctors: false,
        });
      }
    },
  },
};