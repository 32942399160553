import { querySelectorAsync } from '@/utils/querySelectorAsync'

export async function scrollToBottom (el, options) {
  const element = typeof el === 'string'
    ? await querySelectorAsync(el)
    : el

  const optionsWithDefaults = Object.assign({
    animate: false
  }, options)

  if (optionsWithDefaults.animate) {
    element.scrollTo({
      top: element.scrollHeight,
      behavior: 'smooth'
    })
  } else {
    element.scrollTop = element.scrollHeight
  }
}