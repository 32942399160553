var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("svg", {
    staticStyle: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "2"
    },
    attrs: {
      width: "25",
      height: "25",
      viewBox: "0 0 33 35",
      xmlns: "http://www.w3.org/2000/svg"
    }
  }, [_c("path", {
    staticStyle: {
      fill: "#fff",
      "fill-rule": "nonzero"
    },
    attrs: {
      d: "M16.254,19.759l5.262,-0c1.495,-0 2.933,0.306 4.307,0.9c1.717,0.743 3.174,1.838 4.325,3.313c1.085,1.383 1.809,2.952 2.162,4.678c0.158,0.77 0.195,1.54 0.195,2.329c-0,0.938 0.009,1.866 0.018,2.803c0,0.603 -0.454,1.142 -1.03,1.188c-0.668,0.056 -1.225,-0.343 -1.327,-0.974c-0.028,-0.149 -0.028,-0.307 -0.028,-0.464c0,-1.012 0,-2.024 -0.018,-3.035c-0,-1.058 -0.26,-2.07 -0.668,-3.044c-0.873,-2.079 -2.367,-3.546 -4.381,-4.52c-0.715,-0.344 -1.466,-0.566 -2.255,-0.668c-0.39,-0.047 -0.78,-0.084 -1.16,-0.084l-10.283,0c-0.938,0 -1.866,0.074 -2.766,0.344c-1.021,0.296 -1.958,0.761 -2.794,1.41c-1.596,1.225 -2.626,2.822 -3.155,4.761c-0.204,0.752 -0.251,1.522 -0.26,2.293l0,2.83c0,0.381 -0.158,0.687 -0.455,0.919c-0.38,0.297 -0.807,0.334 -1.243,0.149c-0.437,-0.186 -0.687,-0.557 -0.687,-1.021c-0.009,-1.3 -0.037,-2.599 0.037,-3.889c0.102,-1.93 0.77,-3.694 1.838,-5.299c1.355,-2.042 3.22,-3.425 5.503,-4.251c1.207,-0.436 2.459,-0.64 3.74,-0.64l5.142,-0l-0.019,-0.028Zm16.323,13.735c0,-0.055 -0.018,-0.111 -0.018,-0.176l-0,-2.673c-0,-0.065 0.009,-0.13 0.018,-0.204l0,3.053Zm-16.306,-33.494c4.826,0.028 8.622,3.879 8.65,8.613c0.037,4.844 -3.805,8.603 -8.437,8.733c-4.788,0.13 -8.881,-3.694 -8.9,-8.668c-0.018,-4.697 3.777,-8.659 8.687,-8.678Zm-0.019,14.942c3.536,0.019 6.284,-2.877 6.274,-6.274c0,-3.35 -2.71,-6.199 -6.144,-6.264c-3.508,-0.075 -6.338,2.747 -6.403,6.153c-0.065,3.508 2.784,6.432 6.273,6.385Z"
    }
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };