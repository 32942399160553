var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("svg", {
    attrs: {
      width: "20",
      height: "16",
      viewBox: "0 0 20 16",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg"
    }
  }, [_c("path", {
    attrs: {
      d: "M5.47189 16V13.1734C5.47189 12.8316 5.3693 12.7264 5.03599 12.7264H1.19017C1.06229 12.7395 0.933437 12.7395 0.80556 12.7264C0.67056 12.7079 0.546334 12.6409 0.45483 12.5374C0.363326 12.4339 0.310467 12.3006 0.305618 12.1611V0.946739C0.299169 0.876778 0.299169 0.806305 0.305618 0.736344C0.305618 0.223616 0.523547 0.0133495 1.02351 0.000202625H18.7017C18.8484 -0.00373316 18.991 0.0497916 19.1005 0.149934C19.2101 0.250077 19.2783 0.389354 19.2913 0.539269C19.3035 0.64847 19.3035 0.75874 19.2913 0.867941V11.8455C19.3039 11.9547 19.3039 12.065 19.2913 12.1742C19.2819 12.3198 19.2205 12.4567 19.1188 12.5586C19.017 12.6605 18.8822 12.7203 18.7401 12.7264H9.228C9.13784 12.7223 9.04787 12.7376 8.96393 12.7715C8.87998 12.8055 8.80394 12.8573 8.74085 12.9235L5.5873 15.8552L5.47189 16Z",
      fill: "currentColor"
    }
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };