import { DoctorMessageRouterService } from '@/services/doctor-message-router.service';
import { DoctorService } from '@/services/doctor.service';

const CONVERSATION_LIMIT = 20

export default {
  namespaced: true,
  state: {
    conversations: [],
    hasNextConversations: false,
    selectedConversation: null,
    isFetchingConversations: false,
    messages: [],
    messagesNextPage: null,
    isFetchingMessages: false,
    config: null,
    isUpdatingConfig: false,
    revertableMessageInterval: null,
    revertableMessageRemainingTime: null,
    recentlySentMessage: null
  },
  mutations: {
    SET_STATE (state, payload) {
      if (!(payload.state in state)) {
        throw new Error(`Invalid state: ${payload.state}`);
      }

      state[payload.state] = payload.value;
    },
    SET_CONVERSATION_ATTRS (state, { conversationId, attrs }) {
      const conversationsClone = JSON.parse(JSON.stringify(state.conversations))

      const idx = conversationsClone.findIndex(c => c.id === conversationId)

      for (const key in attrs) {
        conversationsClone[idx][key] = attrs[key]
      }

      state.conversations = conversationsClone
    },
    REMOVE_CONVERSATION_BY_ID (state, conversationId) {
      state.conversations = state.conversations.filter(conversation => {
        return conversation.id !== conversationId
      })
    }
  },
  actions: {
    resetTimers ({ commit, state }) {
      clearInterval(state.revertableMessageInterval)

      commit('SET_STATE', {
        state: 'revertableMessageInterval',
        value: null,
      });

      commit('SET_STATE', {
        state: 'revertableMessageRemainingTime',
        value: null,
      });
    },
    async getConversations ({ commit }, page = 1) {
      try {
        commit('SET_STATE', {
          state: 'isFetchingConversations',
          value: true,
        });

        const { data } = await DoctorMessageRouterService.getConversations({
          limit: CONVERSATION_LIMIT,
          offset: (page - 1) * CONVERSATION_LIMIT
        });

        commit('SET_STATE', {
          state: 'conversations',
          value: data,
        });

        commit('SET_STATE', {
          state: 'hasNextConversations',
          value: data.length === CONVERSATION_LIMIT,
        });
      } catch (error) {
        console.log(error)
        throw error
      } finally {
        commit('SET_STATE', {
          state: 'isFetchingConversations',
          value: false,
        });
      }
    },
    async deleteConversation (ctx, conversationId) {
      await DoctorMessageRouterService.deleteConversation(conversationId);
    },
    async getConversationMessages ({ commit }, { conversationId, skipLoading = false }) {
      try {
        commit('SET_STATE', {
          state: 'isFetchingMessages',
          value: !skipLoading,
        });

        const { data } =
          await DoctorMessageRouterService.getConversationMessages(conversationId);

        commit('SET_STATE', {
          state: 'messages',
          value: data.items,
        });
      } catch (error) {
        console.log(error)
        throw error
      } finally {
        commit('SET_STATE', {
          state: 'isFetchingMessages',
          value: false,
        });
      }
    },
    async getConfig ({ commit }) {
      const { data } = await DoctorMessageRouterService.getConfig();

      commit('SET_STATE', {
        state: 'config',
        value: data,
      });
    },

    async updateConfig ({ commit, dispatch }, payload) {
      try {
        commit('SET_STATE', {
          state: 'isUpdatingConfig',
          value: true,
        });

        await DoctorMessageRouterService.updateConfig(payload);
        await dispatch('getConfig');
      } catch (error) {
        console.log(error)
        throw error
      } finally {
        commit('SET_STATE', {
          state: 'isUpdatingConfig',
          value: false,
        });
      }
    },

    async revertLastMessage (_ctx, payload) {
      await DoctorMessageRouterService.revertLastMessage(payload);
    },

    async ignoreDoctorConversation (_ctx, conversationId) {
      await DoctorService.ignoreDoctorConversation(conversationId)
    },

    async routeConversation (ctx, {
      conversationId,
      messageIds,
      routeTo,
    }) {
      DoctorMessageRouterService.routeConversation({
        conversationId,
        messageIds,
        routeTo
      })
    },

    async deleteMessage (ctx, { conversationId, messageId }) {
      await DoctorMessageRouterService.deleteMessage({
        conversationId,
        messageId
      });
    },

    async markConversationAsRead ({ commit }, conversationId) {
      await DoctorMessageRouterService.markConversationAsRead(conversationId);

      commit('SET_CONVERSATION_ATTRS', {
        conversationId,
        attrs: {
          isAdminReviewRead: true
        }
      })
    },
  },
};