// libs
import { mapGetters } from 'vuex';

// services
import { DoctorService } from '@/services/doctor.service';
import { AdminsService } from '@/services/admins.service';

// components
import AdminPatientChatBubble from '@/components/chat/AdminPatientChatBubble';
import ChatThread from '@corefront/components-v2/Chat/ChatThread';

// mocks
// import adminChatMessagesMock from '@/mocks/admin-chat-messages.json';

// constants
import { ENTRY_TYPES } from '@corefront/constant/messages';

// mixins
import { chatThread } from '@corefront/mixins-v2/chatThread';
import { failedMessagesMixin } from '@/mixins/failedMessages';
export default {
  components: {
    AdminPatientChatBubble,
    ChatThread
  },
  mixins: [chatThread, failedMessagesMixin],
  props: {
    isMessagesLoaded: {
      type: Boolean,
      default: false
    },
    isEventsHidden: {
      type: Boolean,
      default: false
    },
    isRecipientTyping: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      doctors: [],
      admins: []
    };
  },
  computed: {
    // constants
    ENTRY_TYPES: () => ENTRY_TYPES,
    // maps
    ...mapGetters({
      nextPage: 'chat/getNextPage',
      selectedConversation: 'chat/getSelectedConversation',
      showProfile: 'chat/getShowProfile'
    }),
    messages() {
      const messages = this.$store.state.chat.messages;
      return messages.filter(message => {
        if (this.isEventsHidden) {
          return [ENTRY_TYPES.MESSAGE, ENTRY_TYPES.ATTACHMENT].includes(message.type);
        }
        return true;
      });
    }
  },
  watch: {
    isMessagesLoaded() {
      this.$_chatThread_scrollToBottom();
    },
    isRecipientTyping(val) {
      if (!val) {
        return;
      }
      this.$_chatThread_scrollToBottom();
    }
  },
  async mounted() {
    const [{
      data: doctors
    }, {
      data: admins
    }] = await Promise.all([DoctorService.getAllDoctors(), AdminsService.getAdmins()]);
    this.doctors = doctors;
    this.admins = admins;
  },
  methods: {
    async messageScroll($state) {
      if (!this.nextPage || this.loadingMessage) {
        return;
      }
      try {
        this.loadingMessage = true;
        const nextPageCursor = await this.$store.dispatch('chat/fetchMessages', {
          patientId: this.selectedConversation.patient.id,
          nextPage: this.nextPage
        });
        if (nextPageCursor) {
          $state.loaded();
        } else {
          $state.complete();
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loadingMessage = false;
      }
    }
  }
};