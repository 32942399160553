// libs
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import _capitalize from 'lodash/capitalize';
import _flow from 'lodash/flow';

// components
import ChatBubble from '@corefront/components-v2/Chat/ChatBubble';

// constants
import { COMMUNICATION_ROLE, EVENT_CATEGORY, PLATFORM, EVENT_NAME, ENTRY_TYPES, CHAT_TYPE, ROLES, TYPES, MESSAGE_SEND_STATUS } from '@corefront/constant/messages';

// mixins
import { failedMessagesMixin } from '@/mixins/failedMessages';
export default {
  components: {
    ChatBubble
  },
  mixins: [failedMessagesMixin],
  props: {
    entry: {
      type: Object,
      required: true
    }
  },
  computed: {
    // constants
    EVENT_CATEGORY: () => EVENT_CATEGORY,
    COMMUNICATION_ROLE: () => COMMUNICATION_ROLE,
    CHAT_TYPE: () => CHAT_TYPE,
    EVENT_NAME: () => EVENT_NAME,
    ENTRY_TYPES: () => ENTRY_TYPES,
    listeners() {
      return {
        ...this.$listeners,
        'delete-failed': this.onDeleteFailed,
        'retry-failed': this.onRetryFailed
      };
    },
    // maps
    ...mapState('chat', ['selectedConversation', 'messages', 'conversations']),
    ...mapGetters('chat', ['conversationPatientName']),
    timestamp() {
      const {
        entry
      } = this;
      return entry.data.timestampInteraction || entry.data.actionDateTimestamp;
    },
    communicationRole() {
      var _entry$data, _entry$data$patient, _entry$data2, _entry$data2$sender;
      const {
        entry
      } = this;
      const {
        platform,
        eventName,
        eventCategory
      } = entry.data;
      if (platform == PLATFORM.INTERCOM && eventName == EVENT_NAME.OUTGOING) {
        return COMMUNICATION_ROLE.SENDER;
      }
      if (eventCategory == EVENT_CATEGORY.NOTES) {
        return COMMUNICATION_ROLE.SENDER;
      }
      if (eventCategory == EVENT_CATEGORY.SMS && eventName == EVENT_NAME.OUTGOING) {
        return COMMUNICATION_ROLE.SENDER;
      }
      if ((entry === null || entry === void 0 ? void 0 : entry.type) === ENTRY_TYPES.MESSAGE && (entry === null || entry === void 0 ? void 0 : (_entry$data = entry.data) === null || _entry$data === void 0 ? void 0 : (_entry$data$patient = _entry$data.patient) === null || _entry$data$patient === void 0 ? void 0 : _entry$data$patient.id) !== (entry === null || entry === void 0 ? void 0 : (_entry$data2 = entry.data) === null || _entry$data2 === void 0 ? void 0 : (_entry$data2$sender = _entry$data2.sender) === null || _entry$data2$sender === void 0 ? void 0 : _entry$data2$sender.id)) {
        return COMMUNICATION_ROLE.SENDER;
      }
      return COMMUNICATION_ROLE.RECEIVER;
    },
    avatarIcon() {
      const {
        entry,
        conversationPatientName
      } = this;
      if (entry.data.eventCategory == EVENT_CATEGORY.INTERCOM) {
        return conversationPatientName === null || conversationPatientName === void 0 ? void 0 : conversationPatientName.charAt(0);
      }
      if (entry.data.eventCategory == EVENT_CATEGORY.INFORMATION_UPDATE || entry.data.eventCategory == EVENT_CATEGORY.ADMIN_UPDATE) {
        return '&#128221;';
      }
      switch (entry.data.eventName) {
        case 'Account Created':
          return '&#127874;';
        case 'Visit Started':
        case 'Questionnaire Completed':
        case 'Treatment Chosen':
        case 'Verification Started':
        case 'ID Uploaded':
        case 'Photo Uploaded':
        case 'Checkout Started':
          return '&#9989;';
        case 'ID Upload Skipped':
        case 'Photo Upload Skipped':
        case 'Identity Verification: Denied':
        case 'Follow Up Cancelled':
          return '&#9888;';
        case 'Checkout Complete':
        case 'Onboarding Completed':
          return '&#127881;';
        case 'Patient Accepted':
        case 'Identity Verification: Accepted':
          return '&#129351;';
        case 'Follow Up Started':
        case 'Follow Up Completed':
        case 'Follow Up Sent':
          return '&#128138;';
        case 'Dr Sent a Message':
          return '&#128104; &#9877;';
        case 'Patient Sent a Message':
        case 'Patient Messaged Doctor':
          return '&#128105;';
        case 'Photo Updated':
        case 'ID Updated':
          return '&#128247;';
        case 'Treatment Shipped':
          return '&#x1f4e6;';
        case 'Payment Cleared:':
          return '&#x1f4b5;';
        case 'Payment Failed':
          return '&#9940;';
        default:
          return '&#9889;';
      }
    },
    patientLastSeenTimestamp() {
      var _this$selectedConvers;
      const details = (_this$selectedConvers = this.selectedConversation) === null || _this$selectedConvers === void 0 ? void 0 : _this$selectedConvers.seenBy.find(c => c.type === ROLES.PATIENT);
      return details ? details.timestamp : 0;
    },
    isSeenByPatient() {
      var _this$selectedConvers2, _nextMessage$data$sen;
      if (this.entry.data.type !== TYPES.MESSAGE) {
        return false;
      }
      if (![ROLES.ADMIN, ROLES.SUPER_ADMIN].includes(this.entry.data.sender.role)) {
        return false;
      }
      const details = (_this$selectedConvers2 = this.selectedConversation) === null || _this$selectedConvers2 === void 0 ? void 0 : _this$selectedConvers2.seenBy.find(c => c.type === ROLES.PATIENT);
      if (!details) {
        return false;
      }
      const entryIdx = this.messages.findIndex(message => message.id === this.entry.id);
      const nextMessage = entryIdx ? this.messages[entryIdx - 1] : null;
      const isPatientNextMessage = (nextMessage === null || nextMessage === void 0 ? void 0 : (_nextMessage$data$sen = nextMessage.data.sender) === null || _nextMessage$data$sen === void 0 ? void 0 : _nextMessage$data$sen.role) === ROLES.PATIENT;
      return details.messageId === this.entry.id && !isPatientNextMessage;
    }
  },
  methods: {
    formatBirthday(date) {
      return moment(date, 'YYYY-MM-DD').format('MM-DD-YYYY');
    },
    getFormLabelByKey(key) {
      switch (key) {
        case 'firstname':
          return 'First Name';
        case 'lastname':
          return 'Last Name';
        case 'phoneno':
          return 'Phone Number';
        case 'address1':
          return 'Street Address';
        case 'address2':
          return 'Apartment/Suite';
        default:
          return key;
      }
    },
    getDoctorReassignedMessage(entry) {
      var _entry$data$doctorFro, _entry$data$doctorFro2, _entry$data$doctorTo, _entry$data$doctorTo2;
      const {
        data
      } = entry;
      const wrapInBold = str => `<span class="tw-text-black tw-font-bold">${str}</span>`;
      const transferrer = data.transferredBy ? `${data.transferredBy.firstName} ${data.transferredBy.lastName}` : data.requestedBy ? `${data.requestedBy.firstName} ${data.requestedBy.lastName}` : '';
      const transferrerInBold = wrapInBold(transferrer ? wrapInBold(transferrer) : '');
      const until = moment(data.endDateStamp || data.actionDateTimestamp * 1000).format('MM/DD/YY');
      const doctorFrom = `${(_entry$data$doctorFro = entry.data.doctorFrom) === null || _entry$data$doctorFro === void 0 ? void 0 : _entry$data$doctorFro.firstName} ${(_entry$data$doctorFro2 = entry.data.doctorFrom) === null || _entry$data$doctorFro2 === void 0 ? void 0 : _entry$data$doctorFro2.lastName}`;
      const doctorTo = `${(_entry$data$doctorTo = entry.data.doctorTo) === null || _entry$data$doctorTo === void 0 ? void 0 : _entry$data$doctorTo.firstName} ${(_entry$data$doctorTo2 = entry.data.doctorTo) === null || _entry$data$doctorTo2 === void 0 ? void 0 : _entry$data$doctorTo2.lastName}`;
      const assignment = entry.data.assignmentType === 'PERMANENT' ? 'permanently.' : `until ${wrapInBold(until)}.`;
      const str = `${transferrerInBold} reassigned the doctor from ${wrapInBold(doctorFrom)} to ${wrapInBold(doctorTo)} ${assignment}`;
      return _flow([str => str.trim(), str => _capitalize(str)])(str);
    },
    async onDeleteFailed() {
      const id = this.entry.id;
      await this.$store.dispatch('failedMessages/resolveByMessageId', id);
      this.$store.commit('chat/DELETE_MESSAGE_BY_ID', id);
    },
    async onRetryFailed() {
      const {
        entry
      } = this;
      this.$store.dispatch('chat/updateMessageById', {
        id: entry.id,
        properties: {
          ...entry,
          sentStatus: MESSAGE_SEND_STATUS.PENDING
        }
      });
      await this.$_failedMessages_sendAdminPatientChatMessage({
        message: {
          ...entry.data,
          sentAt: Math.floor(Date.now() / 1000)
        },
        isRetry: true
      });
    }
  }
};