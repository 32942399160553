var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-row", [_c("b-col"), _c("b-col", {
    attrs: {
      cols: "12"
    }
  }, [_c("div", {
    staticClass: "tw-flex tw-gap-2 tw-mx-10"
  }, [_c("b-button", {
    staticClass: "tw-flex tw-flex-col tw-items-center tw-m-0",
    attrs: {
      variant: "secondary",
      pill: "",
      block: ""
    },
    on: {
      click: function ($event) {
        return _vm.redirectToIntercom(_vm.row.id);
      }
    }
  }, [_vm._v(" Go to "), _c("IconIntercom", {
    staticClass: "tw-text-white tw-h-[20px]"
  })], 1), _c("b-button", {
    staticClass: "tw-flex tw-flex-col tw-items-center tw-m-0",
    attrs: {
      variant: "secondary",
      pill: "",
      block: "",
      href: _vm.stripeLink,
      target: "_blank"
    }
  }, [_vm._v(" Go to "), _c("IconStripe", {
    staticClass: "tw-text-white tw-h-[20px]"
  })], 1)], 1)]), _c("b-col", {
    staticClass: "text-right"
  }, [_c("input", {
    ref: "hiddenEmail",
    attrs: {
      type: "hidden"
    },
    domProps: {
      value: _vm.row.email
    },
    on: {
      focus: function ($event) {
        return $event.target.select();
      }
    }
  }), _c("input", {
    ref: "hiddenPhoneno",
    attrs: {
      type: "hidden"
    },
    domProps: {
      value: _vm.row.phoneno
    },
    on: {
      focus: function ($event) {
        return $event.target.select();
      }
    }
  }), _c("a", {
    staticClass: "text-primary",
    attrs: {
      href: "javascript:;"
    },
    on: {
      click: function ($event) {
        return _vm.copy(_vm.row.email);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.row.email) + " "), _c("img", {
    staticClass: "ml-2 tw-inline",
    attrs: {
      src: require("@/assets/images/copy.svg")
    }
  })]), _c("br"), _c("a", {
    attrs: {
      href: `tel:${_vm.row.phoneno}`
    }
  }, [_vm._v(_vm._s(_vm.row.phoneno))]), _c("a", {
    staticClass: "text-primary",
    attrs: {
      href: "javascript:;"
    },
    on: {
      click: function ($event) {
        return _vm.copy(_vm.row.phoneno);
      }
    }
  }, [_c("img", {
    staticClass: "ml-2 tw-inline",
    attrs: {
      src: require("@/assets/images/copy.svg")
    }
  })])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };