import Vue from 'vue';
import VueRouter from 'vue-router';
import VueBodyClass from 'vue-body-class';

import $cookies from 'vue-cookies';

// Views
import Home from '@/views/Home';
import Login from '@/views/Login';

// Child Components
import PatientView from '@/views/PatientView';
import DoctorView from '@/views/DoctorView';
import EarningsView from '@/views/EarningsView';
import MessagesView from '@/views/MessagesView';
import AdminsView from '@/views/AdminsView';
import InventoryView from '@/views/InventoryView';
import ChatView from '@/views/ChatView';
import RoutedMessagesView from '@/views/RoutedMessagesView';

Vue.use(VueRouter);

import { PatientService } from '@/services/patient.service';

// store
import store from '@/store/index'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Login',
      transition: 'fade-in-left',
    },
  },
  {
    path: '/playground',
    name: 'playground',
    component: () => import('@/views/Playground'),
  },
  {
    path: '/',
    component: Home,
    meta: {
      title: 'Dashboard',
      transition: 'fade',
    },
    beforeEnter: checkUser,
    children: [
      {
        path: '/',
        name: 'patients',
        meta: {
          title: 'Patients',
          transition: 'fade',
        },
        component: PatientView,
        children: [
          { path: '/patients/profile/:patientid', name: 'patientprofile' },
        ],
      },
      {
        path: '/doctors',
        name: 'doctors',
        meta: {
          title: 'Doctors',
          transition: 'fade',
        },
        component: DoctorView,
      },
      {
        path: '/earnings',
        name: 'earnings',
        meta: {
          title: 'Doctor Earnings',
          transition: 'fade',
        },
        component: EarningsView,
      },
      {
        path: '/messages',
        name: 'messages',
        meta: {
          title: 'Messages',
          transition: 'fade',
        },
        component: MessagesView,
      },
      {
        path: '/chat/:conversationId?',
        name: 'chat-id',
        meta: {
          title: 'Chat',
          transition: 'fade',
        },
        component: ChatView,
      },
      {
        path: '/routed-messages',
        name: 'routed-messages',
        meta: {
          title: 'Routed Messages',
          transition: 'fade',
        },
        component: RoutedMessagesView,
        beforeEnter (to, from, next) {
          /**
           * Allow superadmin only
           */
          if (!store.getters['auth/isSuperAdmin']) {
            return next('/')
          }

          next()
        }
      },
      {
        path: '/admins',
        name: 'admins',
        meta: {
          title: 'Admins',
          transition: 'fade',
        },
        component: AdminsView,
      },
      {
        path: '/inventory',
        name: 'inventory',
        meta: {
          title: 'Inventory',
          transition: 'fade',
        },
        component: InventoryView,
      },
      {
        path: '/hsareceipt/:patientid',
        name: 'hsareceipt',
        async beforeEnter (to, from, next) {
          try {
            const { data } = await PatientService.getHSAReceipt(
              to.params.patientid
            );
            window.location.href = data;
          } catch (err) {
            alert(err);
            next({ name: 'patients' });
          }
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

const vueBodyClass = new VueBodyClass(routes);
const DEFAULT_TITLE = 'Winona';
router.beforeEach((to, from, next) => {
  vueBodyClass.guard(to, next);
  document.title = `${DEFAULT_TITLE} | Admin`;
  next();
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => {
    if (err && err.name !== 'NavigationDuplicated') {
      throw err;
    }
  });
};

function checkUser (to, from, next) {
  if ($cookies.get('accessToken')) {
    next();
  } else {
    next({ name: 'login', query: { goToUrl: to.fullPath } });
  }
}

export default router;
