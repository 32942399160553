var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("BasePageTitle", {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Admin Messages ")];
      },
      proxy: true
    }])
  }), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$_chat_bubbleListHeight,
      expression: "$_chat_bubbleListHeight"
    }],
    staticClass: "tw-mx-5 tw-mb-5 tw-border tw-border-baby-powder tw-border-solid",
    attrs: {
      id: _vm.$_chat_CHAT_CONTAINER_ID
    }
  }, [_c("b-row", {
    staticClass: "content d-flex tw-m-0"
  }, [_c("b-col", {
    staticClass: "tw-p-0 tw-m-0 routed-messages__sidebar-container",
    attrs: {
      cols: "12",
      lg: "3"
    }
  }, [_c("RoutedMessagesSidebar")], 1), _c("b-col", {
    staticClass: "tw-p-0 tw-m-0 routed-messages__main-container",
    attrs: {
      cols: "12",
      lg: "9"
    }
  }, [_c("b-card", {
    staticClass: "mb-0 tw-p-0",
    attrs: {
      "no-body": ""
    }
  }, [_c("b-card-header", {
    staticClass: "tw-px-2"
  }, [_vm.selectedConversation ? _c("div", {
    staticClass: "tw-flex tw-gap-[15px]"
  }, [_c("div", {
    staticClass: "tw-size-[40px] tw-overflow-hidden tw-rounded-full"
  }, [_c("BaseImage", {
    key: _vm.selectedConversation.patient.profileUrl,
    ref: "preload",
    staticClass: "tw-w-full tw-h-full tw-object-cover",
    attrs: {
      src: _vm.selectedConversation.patient.profileUrl,
      fallback: _vm.fallbackUserProfile,
      alt: ""
    }
  })], 1), _c("div", {
    staticClass: "tw-flex tw-flex-col tw-justify-between tw-gap-1"
  }, [_c("h5", {
    staticClass: "tw-m-0 tw-text-black tw-text-[20px] tw-font-bold"
  }, [_vm._v(" " + _vm._s(`${_vm.selectedConversation.patient.firstName} ${_vm.selectedConversation.patient.lastName}`) + " ")]), _c("p", {
    staticClass: "tw-m-0 tw-text-muted tw-text-[10px] tw-leading-none tw-cursor-pointer",
    on: {
      click: _vm.viewPatientInfo
    }
  }, [_vm._v(" View Profile ")])])]) : _vm._e()]), _c("RoutedMessagesThread", {
    ref: "routed-messages-dialogues",
    attrs: {
      loading: _vm.isLoadingNextConvoAfterRoute
    }
  })], 1)], 1)], 1), _vm.doctorMessageRouterConfig ? _c("b-row", {
    staticClass: "tw-m-0",
    attrs: {
      id: _vm.$_chat_CHAT_ACTIONS_ID
    }
  }, [_c("b-col", {
    staticClass: "tw-p-0 tw-border-r tw-border-y-0 tw-border-solid tw-border-baby-powder",
    staticStyle: {
      "border-left": "0"
    },
    attrs: {
      cols: "3"
    }
  }, [_c("div", {
    staticClass: "[ tw-h-[55px] tw-w-full ] [ tw-flex tw-items-center tw-justify-end tw-gap-2 ] tw-p-2"
  }, [_c("IconCog", {
    staticClass: "tw-text-[20px] tw-cursor-pointer tw-text-muted hover:tw-text-posey-green tw-transition tw-select-none",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        _vm.isVisibleConfigPanel = !_vm.isVisibleConfigPanel;
      }
    }
  })], 1)]), _c("b-col", {
    staticClass: "tw-p-0"
  }, [_c("div", {
    staticClass: "tw-max-w-[800px] tw-ml-auto tw-h-full tw-flex tw-flex-wrap tw-items-center tw-justify-end tw-gap-2 tw-p-2"
  }, [_vm.selectedConversation ? [_c("BaseButton", {
    staticClass: "tw-uppercase tw-relative tw-flex-1",
    attrs: {
      loading: _vm.isRoutingConversation === _vm.ROUTE_TO.DOCTOR_NO_ACTION,
      color: "primary",
      outline: "",
      disabled: !!_vm.isRoutingConversation
    },
    on: {
      click: function ($event) {
        return _vm.routeConversation({
          routeTo: _vm.ROUTE_TO.DOCTOR_NO_ACTION
        });
      }
    }
  }, [_vm._v(" Send to Doctor "), _c("i", {
    staticClass: "tw-text-[8px] tw-opacity-70 [ tw-absolute tw-left-[50%] tw-bottom-[-2px] tw-translate-x-[-50%] ]"
  }, [_vm._v(" (No Action) ")])]), _c("BaseButton", {
    staticClass: "tw-uppercase tw-flex-1",
    attrs: {
      disabled: !!_vm.isRoutingConversation,
      color: "primary-to-secondary",
      loading: _vm.isRoutingConversation === _vm.ROUTE_TO.BOTH
    },
    on: {
      click: function ($event) {
        return _vm.routeConversation({
          routeTo: _vm.ROUTE_TO.BOTH
        });
      }
    }
  }, [_vm._v(" Send both ")]), _c("BaseButton", {
    staticClass: "tw-uppercase tw-flex-1",
    attrs: {
      disabled: !!_vm.isRoutingConversation,
      color: "primary",
      loading: _vm.isRoutingConversation === _vm.ROUTE_TO.DOCTOR
    },
    on: {
      click: function ($event) {
        return _vm.routeConversation({
          routeTo: _vm.ROUTE_TO.DOCTOR
        });
      }
    }
  }, [_vm._v(" Send to doctor ")]), _c("BaseButton", {
    staticClass: "tw-uppercase tw-flex-1",
    attrs: {
      disabled: !!_vm.isRoutingConversation,
      color: "secondary",
      loading: _vm.isRoutingConversation === _vm.ROUTE_TO.ADMIN
    },
    on: {
      click: function ($event) {
        return _vm.routeConversation({
          routeTo: _vm.ROUTE_TO.ADMIN
        });
      }
    }
  }, [_vm._v(" Send to admin ")])] : _vm._e()], 2)])], 1) : _vm._e(), _c("RoutedMessagesConfigSidePanel", {
    model: {
      value: _vm.isVisibleConfigPanel,
      callback: function ($$v) {
        _vm.isVisibleConfigPanel = $$v;
      },
      expression: "isVisibleConfigPanel"
    }
  }), _vm.selectedConversation ? _c("PatientsInfoSidePanel", {
    ref: "PatientsInfoSidePanel",
    attrs: {
      "patient-id": _vm.selectedConversation.patient.id,
      "update-url": false
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };