import "core-js/modules/es.array.push.js";
import moment from 'moment';
import DoctorEarningsTable from '@/components/tables/DoctorEarningsTable';
export default {
  components: {
    DoctorEarningsTable
  },
  watch: {
    currentFilter(val) {
      this.$store.commit('earnings/SET_SEARCH_QUERY', '');
      this.$store.commit('earnings/EMPTY_SEARCH_RESULTS');
      if (val != 'Custom') {
        this.showCustom = false;
      }
    }
  },
  mounted() {
    for (let i = 2021; i <= moment().format('YYYY'); i++) {
      this.years.push(i);
    }
  },
  data() {
    return {
      currentFilter: 'Month to Date',
      showCustom: false,
      months: [{
        text: 'January',
        value: '01'
      }, {
        text: 'February',
        value: '02'
      }, {
        text: 'March',
        value: '03'
      }, {
        text: 'April',
        value: '04'
      }, {
        text: 'May',
        value: '05'
      }, {
        text: 'June',
        value: '06'
      }, {
        text: 'July',
        value: '07'
      }, {
        text: 'August',
        value: '08'
      }, {
        text: 'September',
        value: '09'
      }, {
        text: 'October',
        value: 10
      }, {
        text: 'November',
        value: 11
      }, {
        text: 'December',
        value: 12
      }],
      years: [],
      fromMonth: null,
      fromYear: null,
      toMonth: null,
      toYear: null,
      isZeroValuesHidden: false
    };
  },
  methods: {
    getEarningData() {
      this.currentFilter = 'Custom';
      const from = Number(`${this.fromYear}${this.fromMonth}`);
      const to = Number(`${this.toYear}${this.toMonth}`);
      this.$store.commit('earnings/SET_FILTER_FROM', from);
      this.$store.commit('earnings/SET_FILTER_TO', to);
      this.$refs.DoctorEarningsTable.getEarning(from, to);
    }
  }
};