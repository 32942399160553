import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "tw-pl-0 tw-bg-[#F7F7F7]"
  }, [_c("PatientsInfoSidePanel", {
    ref: "PatientsInfoSidePanel",
    attrs: {
      "patient-id": _vm.selectedRow.id
    }
  }), _c("b-row", {
    staticClass: "tw-m-0"
  }, [_vm.showTableList ? _c("b-col", {
    staticClass: "tw-p-6 !tw-max-w-[283px] tw-w-full tw-overflow-y-auto tw-max-h-screen"
  }, [_c("TableList", {
    attrs: {
      tab: _vm.tab
    },
    on: {
      "set-tab": _vm.setTab,
      "toggle-table-list": function ($event) {
        _vm.showTableList = !_vm.showTableList;
      }
    }
  })], 1) : _vm._e(), _c("b-col", {
    staticClass: "bg-white tw-p-6 tw-pb-0",
    attrs: {
      id: _vm.$_patientView_MAIN_ID
    }
  }, [_c("div", [_c("b-row", {
    staticClass: "tw-mb-2",
    attrs: {
      id: _vm.$_patientView_TITLE_BAR_ID
    }
  }, [_c("b-col", {
    attrs: {
      cols: "12"
    }
  }, [_c("h1", {
    staticClass: "h1 tw-mb-0"
  }, [!_vm.showTableList ? _c("a", {
    staticClass: "mr-3",
    attrs: {
      href: "javascript:;"
    },
    on: {
      click: function ($event) {
        _vm.showTableList = !_vm.showTableList;
      }
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "layout-sidebar",
      "font-scale": "0.9",
      variant: "muted"
    }
  })], 1) : _vm._e(), _vm._v(" " + _vm._s(_vm.tabName) + " ")])]), _c("b-col", {
    attrs: {
      cols: "12"
    }
  }, [_c("b-input-group", {
    staticClass: "d-flex"
  }, [_c("b-input-group-prepend", [_c("b-input-group-text", [_c("b-icon-search", {
    staticClass: "tw-text-[#353148]"
  })], 1)], 1), _c("b-form-input", {
    ref: "searchInput",
    staticClass: "tw-p-0 flex-grow-1",
    attrs: {
      placeholder: "Find a Patient",
      type: "search",
      autocomplete: "off"
    },
    on: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return (() => {
          _vm.$router.push({
            query: {
              ..._vm.$route.query,
              page: 1,
              search: _vm.search
            }
          });
          _vm.getPatients();
        }).apply(null, arguments);
      }
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _vm.search.length > 0 ? _c("b-input-group-prepend", {
    staticClass: "p-0"
  }, [_c("b-input-group-text", {
    staticClass: "p-0"
  }, [_c("b-button", {
    staticClass: "border-0 align-self-center p-1",
    attrs: {
      variant: "outline-white"
    },
    on: {
      click: _vm.clearSearch
    }
  }, [_c("b-icon-x-circle", {
    staticClass: "tw-text-[#353148]"
  })], 1)], 1)], 1) : _vm._e()], 1)], 1)], 1), _c("b-alert", {
    attrs: {
      variant: "warning",
      show: _vm.totalPatients === 0 && !!_vm.search && !_vm.isFetchingPatients
    }
  }, [_vm._v(" No search results turned up. ")]), _c("b-alert", {
    attrs: {
      variant: "warning",
      show: _vm.alert.show
    }
  }, [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]), _c("b-toast", {
    attrs: {
      id: "viewToast",
      variant: _vm.toast.variant,
      "no-close-button": "",
      "auto-hide-delay": "2500",
      toaster: "b-toaster-top-center",
      solid: ""
    }
  }, [_vm._v(" " + _vm._s(_vm.toast.message) + " ")]), _c("ActionDialog", {
    ref: "ActionDialog",
    attrs: {
      "row-data": _vm.selectedRow,
      "action-id": _vm.tab
    },
    on: {
      "update-time": _vm.updateTime,
      "show-toast": _vm.showToast
    }
  }), _c("ActionHistory", {
    ref: "ActionHistory",
    attrs: {
      "row-data": _vm.selectedRow
    },
    on: {
      "open-action": function ($event) {
        return _vm.$refs.ActionDialog.toggleDialog();
      }
    }
  }), _c("b-card", {
    staticClass: "tw-border-0 tw-mb-0",
    attrs: {
      "no-body": ""
    }
  }, [_c("Main", {
    attrs: {
      type: _vm.tab,
      headers: _vm.headers
    },
    on: {
      "view-info": _vm.viewInfo,
      sort: _vm.onSort
    }
  })], 1)], 1), _c("b-row", {
    staticClass: "mt-3",
    attrs: {
      id: _vm.$_patientView_FOOTER_ACTIONS_ID
    }
  }, [_c("b-col", {
    attrs: {
      cols: "12"
    }
  }, [_c("div", {
    staticClass: "d-flex py-2 justify-content-between"
  }, [_c("div", [_c("b-dropdown", {
    attrs: {
      variant: "link",
      block: "",
      "no-caret": "",
      dropright: ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c("b-icon", {
          attrs: {
            icon: "three-dots",
            "font-scale": "2"
          }
        })];
      },
      proxy: true
    }])
  }, [_c("b-dropdown-item-button", {
    attrs: {
      variant: "secondary"
    },
    on: {
      click: function ($event) {
        _vm.$refs.ExportFiltersDialog.showDialog = true;
      }
    }
  }, [_vm._v(" Export ")])], 1)], 1), _c("div", {
    staticClass: "overflow-auto"
  }, [_vm.totalPatients ? _c("b-pagination", {
    attrs: {
      value: _vm.$route.query.page || 1,
      pills: "",
      "total-rows": _vm.totalPatients,
      "per-page": 25
    },
    on: {
      "page-click": (e, page) => _vm.$router.push({
        query: {
          ..._vm.$route.query,
          page
        }
      })
    }
  }) : _vm._e()], 1)])])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };