// libs
import { mapState, mapGetters } from 'vuex';

// components
import ChatMessageListItem from './chat-message-list/ChatMessageListItem.vue';
import { chatTitles } from '@/constants/chat';

// directives
import { onScrollToBottom } from '@corefront/directives-v2/onScrollToBottom';
export default {
  components: {
    ChatMessageListItem
  },
  directives: {
    onScrollToBottom
  },
  props: {
    isProcessing: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('chat', ['conversations', 'isFetchingConversations', 'category', 'conversationsNextPageCursor']),
    ...mapGetters('chat', ['selectedConversationId']),
    chatTitle() {
      return chatTitles[this.category] || '';
    }
  },
  methods: {
    onConversationSelect(conversation) {
      this.$emit('select', conversation);
    },
    goToNextPage() {
      if (!this.conversationsNextPageCursor) {
        return;
      }
      if (this.isFetchingConversations) {
        return;
      }
      this.$store.dispatch('chat/fetchConversations', {
        nextPage: true,
        category: this.category
      });
    }
  }
};