import "core-js/modules/es.array.push.js";
import { mapState } from 'vuex';
import { DoctorService } from '@/services/doctor.service';
export default {
  props: {
    rowData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      showDialog: false,
      processing: false,
      alert: {
        show: false,
        message: ''
      },
      limits: {},
      version10Week: '',
      version1Year: '',
      newPatient: '',
      transferredPatient: '',
      tenWeek: '',
      oneYear: '',
      messagesAdjusted: '',
      canAcceptPatient: false
    };
  },
  computed: {
    ...mapState('auth', ['currentUser'])
  },
  watch: {
    showDialog(show) {
      if (show) {
        var _this$rowData, _this$rowData$earning, _this$rowData$earning2;
        for (const state of this.rowData.coveredstate) {
          this.limits[state.state] = String(state.limit);
        }
        // this.timeInterval = this.rowData.messagetimeinterval;
        // this.messageCount = this.rowData.messagecount;
        this.canAcceptPatient = this.rowData.canacceptpatient ? true : false;
        this.version10Week = this.rowData.tenweekfollowupversion;
        this.version1Year = this.rowData.oneyearfollowupversion;

        // Earnings versioning
        if ((_this$rowData = this.rowData) !== null && _this$rowData !== void 0 && (_this$rowData$earning = _this$rowData.earnings) !== null && _this$rowData$earning !== void 0 && (_this$rowData$earning2 = _this$rowData$earning[0]) !== null && _this$rowData$earning2 !== void 0 && _this$rowData$earning2.hasOwnProperty('version')) {
          var _this$rowData2, _latestEarnings$rate, _latestEarnings$rate$, _latestEarnings$rate2, _latestEarnings$rate3, _latestEarnings$rate4, _latestEarnings$rate5, _latestEarnings$rate6, _latestEarnings$rate7, _latestEarnings$rate8, _latestEarnings$rate9;
          const versionNumbers = this.rowData.earnings.map(o => o.version);
          const latestVersionNum = Math.max(...versionNumbers);
          const latestEarnings = (_this$rowData2 = this.rowData) === null || _this$rowData2 === void 0 ? void 0 : _this$rowData2.earnings.find(o => o.version == latestVersionNum);
          this.newPatient = this.getMoneyValue((latestEarnings === null || latestEarnings === void 0 ? void 0 : (_latestEarnings$rate = latestEarnings.rate) === null || _latestEarnings$rate === void 0 ? void 0 : (_latestEarnings$rate$ = _latestEarnings$rate[0]) === null || _latestEarnings$rate$ === void 0 ? void 0 : _latestEarnings$rate$.value) || 0);
          this.transferredPatient = this.getMoneyValue((latestEarnings === null || latestEarnings === void 0 ? void 0 : (_latestEarnings$rate2 = latestEarnings.rate) === null || _latestEarnings$rate2 === void 0 ? void 0 : (_latestEarnings$rate3 = _latestEarnings$rate2[1]) === null || _latestEarnings$rate3 === void 0 ? void 0 : _latestEarnings$rate3.value) || 0);
          this.tenWeek = this.getMoneyValue((latestEarnings === null || latestEarnings === void 0 ? void 0 : (_latestEarnings$rate4 = latestEarnings.rate) === null || _latestEarnings$rate4 === void 0 ? void 0 : (_latestEarnings$rate5 = _latestEarnings$rate4[2]) === null || _latestEarnings$rate5 === void 0 ? void 0 : _latestEarnings$rate5.value) || 0);
          this.oneYear = this.getMoneyValue((latestEarnings === null || latestEarnings === void 0 ? void 0 : (_latestEarnings$rate6 = latestEarnings.rate) === null || _latestEarnings$rate6 === void 0 ? void 0 : (_latestEarnings$rate7 = _latestEarnings$rate6[3]) === null || _latestEarnings$rate7 === void 0 ? void 0 : _latestEarnings$rate7.value) || 0);
          this.messagesAdjusted = this.getMoneyValue((latestEarnings === null || latestEarnings === void 0 ? void 0 : (_latestEarnings$rate8 = latestEarnings.rate) === null || _latestEarnings$rate8 === void 0 ? void 0 : (_latestEarnings$rate9 = _latestEarnings$rate8[4]) === null || _latestEarnings$rate9 === void 0 ? void 0 : _latestEarnings$rate9.value) || 0);
        } else {
          var _this$rowData3, _this$rowData3$earnin, _this$rowData3$earnin2, _this$rowData4, _this$rowData4$earnin, _this$rowData4$earnin2, _this$rowData5, _this$rowData5$earnin, _this$rowData5$earnin2, _this$rowData6, _this$rowData6$earnin, _this$rowData6$earnin2, _this$rowData7, _this$rowData7$earnin, _this$rowData7$earnin2;
          this.newPatient = this.getMoneyValue(((_this$rowData3 = this.rowData) === null || _this$rowData3 === void 0 ? void 0 : (_this$rowData3$earnin = _this$rowData3.earnings) === null || _this$rowData3$earnin === void 0 ? void 0 : (_this$rowData3$earnin2 = _this$rowData3$earnin[0]) === null || _this$rowData3$earnin2 === void 0 ? void 0 : _this$rowData3$earnin2.value) || 0);
          this.transferredPatient = this.getMoneyValue(((_this$rowData4 = this.rowData) === null || _this$rowData4 === void 0 ? void 0 : (_this$rowData4$earnin = _this$rowData4.earnings) === null || _this$rowData4$earnin === void 0 ? void 0 : (_this$rowData4$earnin2 = _this$rowData4$earnin[1]) === null || _this$rowData4$earnin2 === void 0 ? void 0 : _this$rowData4$earnin2.value) || 0);
          this.tenWeek = this.getMoneyValue(((_this$rowData5 = this.rowData) === null || _this$rowData5 === void 0 ? void 0 : (_this$rowData5$earnin = _this$rowData5.earnings) === null || _this$rowData5$earnin === void 0 ? void 0 : (_this$rowData5$earnin2 = _this$rowData5$earnin[2]) === null || _this$rowData5$earnin2 === void 0 ? void 0 : _this$rowData5$earnin2.value) || 0);
          this.oneYear = this.getMoneyValue(((_this$rowData6 = this.rowData) === null || _this$rowData6 === void 0 ? void 0 : (_this$rowData6$earnin = _this$rowData6.earnings) === null || _this$rowData6$earnin === void 0 ? void 0 : (_this$rowData6$earnin2 = _this$rowData6$earnin[3]) === null || _this$rowData6$earnin2 === void 0 ? void 0 : _this$rowData6$earnin2.value) || 0);
          this.messagesAdjusted = this.getMoneyValue(((_this$rowData7 = this.rowData) === null || _this$rowData7 === void 0 ? void 0 : (_this$rowData7$earnin = _this$rowData7.earnings) === null || _this$rowData7$earnin === void 0 ? void 0 : (_this$rowData7$earnin2 = _this$rowData7$earnin[4]) === null || _this$rowData7$earnin2 === void 0 ? void 0 : _this$rowData7$earnin2.value) || 0);
        }
      } else {
        this.limits = {};
        this.newPatient = '';
        this.transferredPatient = '';
        this.tenWeek = '';
        this.oneYear = '';
        this.messagesAdjusted = '';
        this.alert.show = false;
      }
    }
  },
  methods: {
    showAlert(msg) {
      this.alert.message = msg;
      this.alert.show = true;
    },
    getMoneyValue(input) {
      if (!input && typeof input !== 'number') {
        return;
      } else if (input.length <= 1 && input.charAt(0) == '$') {
        return '';
      } else {
        const stringed = `${input}`;
        return `$${stringed.replace(/\D/g, '')}`;
      }
    },
    removeDollarAndConvert(str) {
      if (str) {
        const cleared = str.replace(/\D/g, '');
        return parseInt(cleared);
      } else {
        return null;
      }
    },
    async setDoctorSettings() {
      const coveredstate = [];
      for (const key in this.limits) {
        if (!this.limits[key]) {
          this.showAlert('Please fill in all the fields correctly.');
          return;
        }
        coveredstate.push({
          state: key,
          limit: Number(this.limits[key])
        });
      }
      if (!this.newPatient || !this.transferredPatient || !this.tenWeek || !this.oneYear || !this.version10Week || !this.version1Year || !this.messagesAdjusted) {
        this.showAlert('Please fill in all the fields.');
        return;
      }
      try {
        const body = {
          coveredstate: coveredstate,
          // messagecount: this.messageCount,
          // messagetimeinterval: this.timeInterval,
          tenweekfollowupversion: this.version10Week,
          oneyearfollowupversion: this.version1Year,
          earnings: [{
            key: 'newpatients',
            value: this.removeDollarAndConvert(this.newPatient)
          }, {
            key: 'transferedpatients',
            value: this.removeDollarAndConvert(this.transferredPatient)
          }, {
            key: 'tenweekfollowup',
            value: this.removeDollarAndConvert(this.tenWeek)
          }, {
            key: 'oneyearfollowup',
            value: this.removeDollarAndConvert(this.oneYear)
          }, {
            key: 'messageadjusted',
            value: this.removeDollarAndConvert(this.messagesAdjusted)
          }],
          canacceptpatient: this.canAcceptPatient
        };
        this.processing = true;
        await DoctorService.setDoctorSettings(this.rowData.id, body);
        this.$emit('fetch-doctors', () => {
          this.showDialog = false;
          this.processing = false;
        });
      } catch (err) {
        this.showAlert(err);
        this.processing = false;
      }
    }
  }
};