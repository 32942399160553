var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "tw-relative tw-overflow-hidden"
  }, [_vm.loading ? _c("div", {
    staticClass: "tw-absolute tw-top-[50%] tw-left-[50%] -tw-translate-y-1/2 -tw-translate-x-1/2 tw-size-full tw-bg-black/50 tw-flex tw-justify-center tw-items-center tw-text-white"
  }, [_c("IconLoading")], 1) : _vm._e(), _c("img", _vm._b({
    staticClass: "tw-size-full",
    class: [_vm.objectFitClass],
    attrs: {
      src: _vm.srcOrFallback,
      alt: _vm.alt
    }
  }, "img", _vm.$attrs, false))]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };