var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("h1", {
    staticClass: "h1 tw-font-gerstner tw-text-lg tw-text-violet-950 tw-uppercase tw-tracking-widest tw-mb-20"
  }, [_vm._v(" Chat ")]), _c("div", {
    staticClass: "space-y-4"
  }, _vm._l(_vm.categories, function (categoryItem) {
    return _c("ChatCategoryItem", {
      key: categoryItem.value,
      staticClass: "tw-mb-4",
      attrs: {
        "is-selected": _vm.category === categoryItem.value,
        "unread-count": categoryItem.unreadCount,
        label: categoryItem.label
      },
      on: {
        click: function ($event) {
          return _vm.onCategoryClick(categoryItem);
        }
      }
    });
  }), 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };