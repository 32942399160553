var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.patient ? _c("div", [_c("div", {
    staticClass: "tw-p-4 tw-flex tw-items-center tw-h-[69px] tw-border-0 tw-border-b tw-border-solid tw-border-slate-300"
  }, [_c("div", {
    staticClass: "d-flex justify-content-between tw-w-full"
  }, [_c("h1", {
    staticClass: "ht tw-text-lg tw-black tw-uppercase tw-tracking-widest tw-mb-0"
  }, [_vm._v(" Details ")]), _c("a", {
    attrs: {
      href: "javascript:;"
    },
    on: {
      click: _vm.toggleDisplay
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "x",
      "font-scale": "2",
      variant: "dark"
    }
  })], 1)])]), _c("b-toast", {
    attrs: {
      id: "viewToast",
      variant: "danger",
      "no-close-button": "",
      "auto-hide-delay": "2500",
      toaster: "b-toaster-top-center",
      solid: ""
    }
  }, [_vm._v(" " + _vm._s(_vm.toastMessage) + " ")]), _c("div", {
    staticClass: "container mt-5 pb-5 px-5 d-flex flex-column tw-border-0 tw-border-b tw-border-solid tw-border-slate-300"
  }, [_vm._m(0), _c("div", {
    staticClass: "d-flex justify-content-between mt-4 tw-text-sm"
  }, [_c("span", {
    staticClass: "text-muted"
  }, [_vm._v("Person:")]), _vm.loading ? _c("b-skeleton") : _c("span", {
    staticClass: "text-right"
  }, [_c("a", {
    attrs: {
      href: "javascript:;"
    },
    on: {
      click: _vm.viewPatientInfo
    }
  }, [_vm._v(" " + _vm._s(_vm.patient.firstname) + " " + _vm._s(_vm.patient.lastname) + " ")])])], 1), _c("div", {
    staticClass: "d-flex justify-content-between mt-4 tw-text-sm"
  }, [_c("span", {
    staticClass: "text-muted d-flex tw-items-center"
  }, [_vm._v("Assignee:")]), _vm.loading ? _c("b-skeleton") : _c("span", {
    staticClass: "text-right"
  }, [_vm.selectedConversation ? _c("b-dropdown", {
    ref: "assignmentDropdown",
    staticClass: "text-right",
    attrs: {
      id: "assignmentDropdown",
      variant: "link",
      "no-caret": "",
      size: "sm",
      dropleft: "",
      disabled: _vm.selectedConversation.dismissed
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c("span", {
          staticClass: "tw-text-sm tw-tracking-wide tw-text-secondary tw-font-normal tw-no-underline s tw-capitalize"
        }, [_vm.assignee ? _c("span", [_vm._v(" " + _vm._s(_vm.assignee) + " ")]) : _c("span", [_vm._v(" Unassigned ")])])];
      },
      proxy: true
    }], null, false, 1792991089)
  }, [_vm.showAssignButton ? _c("b-dropdown-item", {
    on: {
      click: function ($event) {
        return _vm.assignConversation(_vm.selectedConversation.id, true);
      }
    }
  }, [_c("a", {
    attrs: {
      href: "javascript:;"
    }
  }, [_vm._v("Assign to myself")])]) : _vm._e(), _vm.showUnassignButton ? _c("b-dropdown-item", {
    on: {
      click: function ($event) {
        return _vm.assignConversation(_vm.selectedConversation.id, false);
      }
    }
  }, [_c("a", {
    attrs: {
      href: "javascript:;"
    }
  }, [_vm._v("Unassign")])]) : _vm._e()], 1) : _vm._e()], 1)], 1)]), _c("div", {
    staticClass: "container mt-5 pb-5 px-5 d-flex flex-column tw-border-0 tw-border-b tw-border-solid tw-border-slate-300"
  }, [_vm._m(1), _c("div", {
    staticClass: "d-flex justify-content-between mt-4 tw-text-sm"
  }, [_c("span", {
    staticClass: "text-muted"
  }, [_vm._v("Email:")]), _c("span", {
    staticClass: "text-right text-truncate",
    staticStyle: {
      display: "inline-block",
      width: "50%"
    }
  }, [_vm.loading ? _c("b-skeleton") : _c("a", {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        hover: true
      }
    }],
    attrs: {
      href: "javascript:;",
      title: _vm.patient.email
    },
    on: {
      click: function ($event) {
        return _vm.copy(_vm.patient.email);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.patient.email) + " ")])], 1)]), _c("div", {
    staticClass: "d-flex justify-content-between mt-4 tw-text-sm"
  }, [_c("span", {
    staticClass: "text-muted"
  }, [_vm._v("Phone:")]), _vm.loading ? _c("b-skeleton") : _c("span", [_c("a", {
    attrs: {
      href: "javascript:;"
    },
    on: {
      click: function ($event) {
        return _vm.copy(_vm.patient.phoneno);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.patient.phoneno) + " ")])])], 1)]), _c("div", {
    staticClass: "container mt-5 pb-5 px-5 d-flex flex-column tw-border-0 tw-border-b tw-border-solid tw-border-slate-300"
  }, [_vm._m(2), _c("div", {
    staticClass: "d-flex justify-content-between mt-4"
  }, [_c("span", {
    staticClass: "text-muted"
  }, [_vm._v("DOB:")]), _vm.loading ? _c("b-skeleton") : _c("span", [_vm._v(_vm._s(_vm.formatDate(_vm.patient.birthday)))])], 1), _c("div", {
    staticClass: "d-flex justify-content-between mt-4"
  }, [_c("span", {
    staticClass: "text-muted"
  }, [_vm._v("Shipping Address:")]), _vm.loading ? _c("b-skeleton") : _c("span", {
    staticClass: "text-right",
    staticStyle: {
      "max-width": "70%"
    }
  }, [_vm._v(" " + _vm._s(_vm.getAddress(_vm.patient.shippingaddress)) + " ")])], 1)]), _vm.selectedConversation ? _c("PatientsInfoSidePanel", {
    ref: "PatientsInfoSidePanel",
    attrs: {
      "patient-id": _vm.selectedConversation.patient.id,
      "update-url": false
    }
  }) : _vm._e(), _c("div", {
    staticClass: "background",
    style: {
      "background-color": _vm.bgModal.color,
      display: _vm.bgModal.show
    },
    on: {
      click: function ($event) {
        _vm.showPatientInfo = false;
      }
    }
  })], 1) : _vm._e();
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "d-flex justify-content-between"
  }, [_c("span", {
    staticClass: "font-weight-bold text-muted"
  }, [_vm._v("Assignment")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "d-flex justify-content-between"
  }, [_c("span", {
    staticClass: "font-weight-bold text-muted"
  }, [_vm._v("Contact")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "d-flex justify-content-between"
  }, [_c("span", {
    staticClass: "font-weight-bold text-muted"
  }, [_vm._v("Info")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };