import "core-js/modules/es.object.has-own.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-card", {
    staticClass: "tw-cursor-pointer tw-rounded-md card-container",
    class: [_vm.prescription.prescriptionstatus.replace(/\s+/g, "-").toLowerCase(), _vm.selected ? "selected" : "tw-border !tw-border-transparent"],
    attrs: {
      "border-variant": "light"
    },
    on: {
      click: function ($event) {
        return _vm.openAirtableRecord(_vm.prescription);
      }
    }
  }, [_c("div", {
    staticClass: "tw-flex tw-items-start tw-mb-3"
  }, [_c("div", {
    staticClass: "tw-flex-1"
  }, [_c("h2", {
    staticClass: "tw-m-0 tw-font-atten tw-text-xl tw-text-[#19191C] tw-font-bold"
  }, [_vm._v(" " + _vm._s(_vm.prescription.medicinename) + " ")]), _c("p", {
    staticClass: "tw-m-0 tw-text-sm"
  }, [_vm._v(" " + _vm._s(_vm.prescription.dosage) + " ")]), _c("h4", {
    staticClass: "card-status mb-0 tw-text-sm tw-leading-snug"
  }, [_vm._v(" " + _vm._s(_vm.getRefillString(_vm.prescription)) + " ")]), _c("h4", {
    staticClass: "tw-text-[#828282] tw-text-sm tw-leading-snug mb-0"
  }, [_vm._v(" " + _vm._s(_vm.prescription.unit) + " ")])]), _c("div", {
    staticClass: "tw-flex-1 tw-text-right tw-pl-2"
  }, [_c("div", {
    staticClass: "text-primary card-status tw-mb-0 tw-capitalize tw-flex tw-items-center tw-justify-end tw-text-sm"
  }, [_vm.prescription && _vm.prescription.prescriptionstatus == "WAITING" ? _c("span", {
    staticClass: "mr-3 text-small"
  }, [_vm._v(" " + _vm._s(_vm.timer) + " seconds ")]) : _vm._e(), _c("span", {
    staticClass: "tw-capitalize"
  }, [_vm._v(" " + _vm._s(_vm.prescription.prescriptionstatus.toLowerCase()) + " ")]), _c("span", {
    staticClass: "tw-capitalize"
  }, [_vm.prescription.prescriptionstatus === "CANCELLED" ? _c("div", [Object.hasOwn(_vm.prescription, "can_restart") ? _c("span", {
    staticClass: "text-capitalize"
  }, [_vm._v(" (Stripe) ")]) : _vm.prescription.cancelledbyrole ? _c("span", {
    staticClass: "text-capitalize ml-1"
  }, [_vm._v(" (" + _vm._s(_vm.prescription.cancelledbyrole.toLowerCase()) + ") ")]) : _vm._e()]) : _vm._e()])]), _vm.prescription.prescriptionstatus == "PAUSED" ? _c("p", {
    staticClass: "scheduled-text text-muted tw-whitespace-nowrap tw-mb-0 tw-ml-1 tw-text-sm"
  }, [_vm._v(" (Paused until: "), _vm.prescription.resumecollection ? _c("span", [_vm._v(" " + _vm._s(_vm.parseUnixDate(_vm.prescription.resumecollection, "yyyy-MM-dd")) + " ")]) : _c("span", [_vm._v(" Indefinite ")]), _vm._v(" ) ")]) : _vm._e(), _vm.prescription.prescriptionstatus == "SCHEDULED" ? _c("p", {
    staticClass: "scheduled-text text-muted tw-whitespace-nowrap tw-mb-0 tw-text-sm"
  }, [_vm._v(" (Scheduled for: " + _vm._s(_vm.parseUnixDate(_vm.prescription.timestampsubscriptionstartdate, "MM/dd/yy")) + ") ")]) : _vm._e(), _c("span", {
    staticClass: "badge tw-py-1 tw-rounded-full tw-px-2",
    class: `status-${_vm.prescription.airtablestatus && _vm.prescription.airtablestatus.replace(/\s+/g, "-").toLowerCase()}`
  }, [_vm._v(" " + _vm._s(_vm.prescription.airtablestatus) + " ")])])]), _c("div", {
    staticClass: "tw-flex tw-justify-between"
  }, [_c("div", {
    staticClass: "tw-flex tw-gap-8"
  }, [_c("div", [_c("div", {
    staticClass: "tw-text-[#828282] tw-text-xs"
  }, [_vm._v(" Prescribed ")]), _c("div", {
    staticClass: "tw-text-xs"
  }, [_vm._v(" " + _vm._s(_vm.prescription.timestampprescibed ? _vm.parseUnixDate(_vm.prescription.timestampprescibed, "MM/dd/yy") : "-") + " ")])]), _c("div", [_c("div", {
    staticClass: "tw-text-[#828282] tw-text-xs"
  }, [_vm._v(" Last Refill Ends ")]), _c("div", {
    staticClass: "tw-text-xs"
  }, [_vm._v(" " + _vm._s(_vm.prescription.actualenddate ? _vm.parseUnixDate(_vm.prescription.actualenddate, "MM/dd/yy") : "-") + " ")])]), _vm.prescription.refills - _vm.prescription.paidrefills > 0 ? _c("div", [_c("div", {
    staticClass: "tw-text-[#828282] tw-text-xs"
  }, [_vm._v(" Next Refill ")]), _c("div", {
    staticClass: "tw-flex tw-items-center"
  }, [_c("p", {
    staticClass: "mb-0 mr-2 tw-text-xs"
  }, [_vm._v(" " + _vm._s(_vm.prescription.nextshipmentdate && _vm.prescription.transactiontype !== "one_time" ? _vm.parseUnixDate(_vm.prescription.nextshipmentdate, "MMMM d, yyyy") : "-") + " ")])])]) : _vm._e()]), _vm.enableControls ? _c("div", {
    staticClass: "tw-flex tw-justify-end tw-items-center"
  }, [_c("VTooltip", {
    scopedSlots: _vm._u([{
      key: "popper",
      fn: function () {
        return [_vm._v(" Prescription history ")];
      },
      proxy: true
    }], null, false, 2898596087)
  }, [_c("div", [_c("img", {
    attrs: {
      src: require("@/assets/images/prescription_info.svg"),
      alt: ""
    },
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.$emit("open-prescription-history", _vm.prescription);
      }
    }
  })])]), !["CANCELLED", "COMPLETED", "PROCESSING", "MIGRATED"].includes(_vm.prescription.prescriptionstatus) || Object.hasOwn(_vm.prescription, "can_restart") && !_vm.isMoreThan90Days(_vm.prescription.timestampcancelled) ? _c("div", [_c("b-dropdown", {
    staticClass: "more-dropdown",
    attrs: {
      size: "lg",
      variant: "link",
      "toggle-class": "text-decoration-none p-0 ml-2",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c("img", {
          attrs: {
            src: require("@/assets/images/more.svg"),
            alt: ""
          }
        })];
      },
      proxy: true
    }], null, false, 1331942805)
  }, [Object.hasOwn(_vm.prescription, "can_restart") && !_vm.isMoreThan90Days(_vm.prescription.timestampcancelled) && !(_vm.disablePlay(_vm.prescription) || _vm.prescription.prescriptionstatus === "WAITING" && _vm.prescription.delayuntil >= _vm.getUnix(new Date())) ? _c("b-dropdown-item", {
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.$emit("open-toggle-prescription", false, _vm.prescription);
      }
    }
  }, [_vm._v(" Restart Prescription ")]) : !(_vm.disablePlay(_vm.prescription) || _vm.prescription.prescriptionstatus === "WAITING" && _vm.prescription.delayuntil >= _vm.getUnix(new Date())) ? _c("b-dropdown-item", {
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.$emit("open-toggle-prescription", true, _vm.prescription);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.prescription.prescriptionstatus === "PAUSED" ? "Resume prescription" : _vm.prescription.prescriptionstatus === "FAILED" ? "Retry prescription" : "Play prescription") + " ")]) : _vm._e(), !_vm.disablePause(_vm.prescription) && _vm.prescription.transactiontype !== "one_time" ? _c("b-dropdown-item", {
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.openPausePrescription(_vm.prescription);
      }
    }
  }, [_vm._v(" Pause Prescription ")]) : _vm._e(), !_vm.disableStop(_vm.prescription) ? _c("b-dropdown-item", {
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.openSingleCancel(_vm.prescription);
      }
    }
  }, [_vm._v(" Cancel Prescription ")]) : _vm._e(), _vm.prescription.prescriptionstatus === "PRESCRIBED" && _vm.prescription.refills - _vm.prescription.paidrefills !== 0 ? _c("b-dropdown-item", {
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.$emit("open-refill", {
          ..._vm.prescription
        });
      }
    }
  }, [_vm._v(" Activate Next Refill ")]) : _vm._e(), _vm.prescription.prescriptionstatus === "PRESCRIBED" ? _c("b-dropdown-item", {
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.$emit("open-move-refill", {
          ..._vm.prescription,
          fullname: `${_vm.patientData.firstname} ${_vm.patientData.lastname}`,
          ongoingItems: _vm.ongoingItems,
          hysterectomy: _vm.patientData.hysterectomy
        });
      }
    }
  }, [_vm._v(" Edit Refill Date ")]) : _vm._e(), _vm.prescription.prescriptionstatus === "SCHEDULED" ? _c("b-dropdown-item", {
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.$emit("open-move-prescription-start-date", {
          ..._vm.prescription,
          fullname: `${_vm.patientData.firstname} ${_vm.patientData.lastname}`,
          ongoingItems: _vm.ongoingItems,
          hysterectomy: _vm.patientData.hysterectomy
        });
      }
    }
  }, [_vm._v(" Edit Start Date ")]) : _vm._e(), _vm.prescription.prescriptionstatus === "PRESCRIBED" ? _c("b-dropdown-item", {
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.$emit("open-escript", {
          ..._vm.prescription,
          fullname: `${_vm.patientData.firstname} ${_vm.patientData.lastname}`
        });
      }
    }
  }, [_vm._v(" Re-send Escript ")]) : _vm._e(), _vm.prescription.airtablestatus === "Processing" ? _c("b-dropdown-item", {
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.$emit("open-rush", {
          ..._vm.prescription,
          fullname: `${_vm.patientData.firstname} ${_vm.patientData.lastname}`
        });
      }
    }
  }, [_vm._v(" Rush Prescription ")]) : _vm._e(), _vm.prescription.prescriptionstatus === "PRESCRIBED" ? _c("b-dropdown-item", {
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.$emit("open-send-replacement", {
          ..._vm.prescription,
          fullname: `${_vm.patientData.firstname} ${_vm.patientData.lastname}`
        });
      }
    }
  }, [_vm._v(" Send Replacement ")]) : _vm._e(), _vm.prescription.airtablestatus === "Processing" ? _c("b-dropdown-item", {
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.$emit("open-cancel-order", _vm.prescription);
      }
    }
  }, [_vm._v(" Cancel Processing Order ")]) : _vm._e()], 1)], 1) : _vm._e()], 1) : _vm._e()])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };