var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    staticClass: "p-4",
    attrs: {
      "hide-header": "",
      "hide-footer": "",
      centered: "",
      "no-close-on-backdrop": "",
      "no-stacking": "",
      size: "xl"
    },
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c("b-row", [_c("b-col", {
    attrs: {
      cols: "3"
    }
  }, [_c("h4", {
    staticClass: "heading"
  }, [_vm._v("Forwarded Request")])]), _c("b-col", [_vm.rowData ? _c("p", [_vm._v("for " + _vm._s(_vm.rowData.firstname) + " " + _vm._s(_vm.rowData.lastname))]) : _vm._e()])], 1), _c("div", [_c("b-table", {
    attrs: {
      striped: "",
      hover: "",
      fields: _vm.fields,
      items: _vm.requestList
    },
    scopedSlots: _vm._u([{
      key: "cell(timestampcreated)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.getDate(row.item.timestampcreated)) + " ")];
      }
    }, {
      key: "cell(view)",
      fn: function (row) {
        return [_c("a", {
          attrs: {
            href: "javascript:;"
          },
          on: {
            click: function ($event) {
              return _vm.$emit("view-request", row.item);
            }
          }
        }, [_c("u", [_vm._v("View")])])];
      }
    }])
  })], 1), _c("b-row", {
    attrs: {
      "align-h": "end"
    }
  }, [_c("b-col", {
    attrs: {
      cols: "4"
    }
  }, [_c("b-button", {
    attrs: {
      variant: "outline-primary",
      pill: "",
      block: ""
    },
    on: {
      click: function ($event) {
        _vm.showDialog = false;
      }
    }
  }, [_vm._v(" Close ")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };