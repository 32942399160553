import "core-js/modules/es.array.push.js";
// libs
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';
import { format } from 'date-fns';

// components
import ChatMessageInputArea from '@/components/chat/chat-message-list/ChatMessageInputArea.vue';
import ChatMessagesContainer from '@/components/chat/chat-messages/ChatMessagesContainer.vue';
import ChatDismissModal from '@/components/chat/ChatDismissModal.vue';
import ChatReopenModal from '@/components/chat/ChatReopenModal.vue';
import ChatMacroDialog from '@/components/chat/ChatMacroDialog.vue';
import ChatMacroEditModal from '@/components/chat/ChatMacroEditModal.vue';
import CoreTooltip from '@corefront/components-v2/Core/CoreTooltip.vue';
import IconFolderFilled from '@corefront/components-v2/Icon/IconFolderFilled.vue';
import IconFolderOutline from '@corefront/components-v2/Icon/IconFolderOutline.vue';

// constants
import { TYPING_INDICATOR } from '@/constants';
import { CATEGORY } from '@corefront/constant/messages';
import { EVENTS } from '@corefront/constant/socket';

// utils
import { sleep } from '@/utils/sleep';
import { isIOS, isMacOs } from 'mobile-device-detect';

// mixins
import { chatThread } from '@corefront/mixins-v2/chatThread';
import { failedMessagesMixin } from '@/mixins/failedMessages';

// socket
import socket from '@corefront/utils/socket';
export default {
  components: {
    ChatMessagesContainer,
    ChatMessageInputArea,
    ChatDismissModal,
    ChatReopenModal,
    ChatMacroDialog,
    ChatMacroEditModal,
    CoreTooltip,
    IconFolderFilled,
    IconFolderOutline
  },
  mixins: [chatThread, failedMessagesMixin],
  props: {
    conversation: {
      type: Object,
      default: () => ({})
    },
    isMessagesLoaded: {
      type: Boolean,
      default: false
    },
    onlineStatusResponse: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isEventsHidden: false,
      isRecipientTyping: false,
      onlineStatus: false,
      onlineStatusInterval: null,
      typersList: [],
      isNote: false,
      showMacroEdit: false,
      patientId: '',
      patientData: null,
      toast: {
        message: '',
        variant: 'warning'
      },
      tab: 'all',
      isProcessingAssignment: false,
      socket
    };
  },
  computed: {
    ...mapState('chat', ['conversations', 'messages', 'category', 'selectedConversation', 'assignee']),
    ...mapState('auth', ['currentUser']),
    isApple() {
      return isMacOs || isIOS;
    },
    stringifyTypers() {
      return this.typersList.map(typer => typer.name).join(', ');
    },
    bgModal() {
      return {
        color: this.showMacroEdit ? 'rgba(12, 53, 35, 0.2)' : 'rgba(12, 53, 35, 0.0)',
        show: this.showMacroEdit ? 'block' : 'none'
      };
    },
    patientName() {
      return `${this.conversation.patient.firstName} ${this.conversation.patient.lastName}`;
    },
    isDismissed() {
      return this.conversation.dismissed;
    }
  },
  watch: {
    isMessagesLoaded(val) {
      if (!val) {
        this.resetTypersListState();
      }
    },
    messages(val) {
      if (val.length > 0) {
        const lastMessage = val[0];
        if (lastMessage.type === 'MESSAGE') {
          this.removeTypingIndicator({
            userId: lastMessage.data.sender.id
          });
        }
      }
    },
    onlineStatus(val) {
      if (!val) {
        this.removeTypingIndicator({
          userId: this.conversation.patient.id
        });
      }
    },
    onlineStatusResponse(val) {
      this.removeTypingIndicator({
        userId: val.userId
      });
    }
  },
  mounted() {
    this.startWatchingTyping();
    this.isOnline();
  },
  async created() {
    await this.socket.waitUntilReady();
  },
  destroyed() {
    clearInterval(this.onlineStatusInterval);
  },
  methods: {
    ...mapActions('chat', ['getConversationById']),
    startWatchingTyping() {
      this.socket.ctx.emit(EVENTS.TYPING_ACTIVITY_WATCH, {
        conversationIds: [this.conversation.id]
      });
      this.socket.ctx.on(EVENTS.TYPING_ACTIVITY_TYPING, this.handleTypingActivity);
    },
    handleTypingActivity(payload) {
      if (!payload.conversationId.includes(this.conversation.id)) {
        return;
      }
      if (payload.userId === this.currentUser.id) {
        return;
      }
      this.isRecipientTyping = true;
      this.addToTypersList(payload);
    },
    async addToTypersList(payload) {
      const typerName = `${payload.firstName} ${payload.lastName}`;
      const isTyperIncluded = this.typersList.some(typer => typer.name === typerName);
      if (!isTyperIncluded) {
        this.typersList.push({
          name: typerName,
          userId: payload.userId
        });
        await sleep(10000);
        this.removeTypingIndicator(payload);
      }
    },
    removeTypingIndicator(payload) {
      this.typersList = this.typersList.filter(typer => typer.userId !== payload.userId);
      this.isRecipientTyping = this.typersList.length > 0;
    },
    onType: _.throttle(function () {
      var _this$socket;
      if (this.isNote) {
        return;
      }
      (_this$socket = this.socket) === null || _this$socket === void 0 ? void 0 : _this$socket.emit('typing_activity:typing', {
        conversationId: [this.conversation.id]
      });
    }, TYPING_INDICATOR.THROTTLE_TIME),
    async assignConversation(conversationid, assign) {
      this.isProcessingAssignment = true;
      try {
        await this.$store.dispatch('chat/assignConversation', {
          conversationid,
          assign
        });
        this.$store.dispatch('chat/getMetrics');
        if (this.category === CATEGORY.ASSIGNED) {
          if (!assign) {
            const filteredConversation = this.conversations.filter(conversation => conversation.id !== this.conversation.id);
            this.$store.dispatch('chat/updateConversations', filteredConversation);
          }
          this.$store.commit('chat/SET_STATE', {
            selectedConversation: null,
            messages: []
          });
        }
        if (this.category === CATEGORY.OPEN) {
          if (assign) {
            const filteredConversation = this.conversations.filter(conversation => conversation.id !== this.conversation.id);
            this.$store.dispatch('chat/updateConversations', filteredConversation);
          }
        }
        if (assign) {
          this.$store.dispatch('chat/refreshAssignee');
        } else {
          this.$store.dispatch('chat/setAssigneeManual', null);
        }
        this.isProcessingAssignment = false;
      } catch (err) {
        console.log(err);
        this.isProcessingAssignment = false;
      }
    },
    onShowDismissModal() {
      this.$refs.ChatDismissModal.showDialog = true;
    },
    onShowReopenModal() {
      this.$refs.ChatReopenModal.showDialog = true;
    },
    async dismissConversation() {
      await this.$store.dispatch('chat/dismissConversation', {
        patientId: this.conversation.patient.id,
        conversationId: this.conversation.id
      });
      this.$refs.ChatDismissModal.showDialog = false;
      this.$store.commit('chat/SET_STATE', {
        selectedConversation: null,
        messages: []
      });
      this.$store.dispatch('chat/getMetrics');
    },
    async openMyInboxTab() {
      const conversationId = this.$route.params.conversationId;
      const currentConversation = await this.getConversationById(conversationId);
      await Promise.all([this.$store.dispatch('chat/assignConversation', {
        conversationid: conversationId,
        assign: true
      }), this.$store.dispatch('chat/refreshAssignee', currentConversation), this.$store.dispatch('chat/fetchConversations', {
        category: CATEGORY.ASSIGNED
      }), this.$store.dispatch('chat/getMetrics')]);
      this.$store.commit('chat/SET_STATE', {
        category: CATEGORY.ASSIGNED
      });
      this.$store.commit('chat/APPEND_CONVERSATION', currentConversation);
      this.$store.commit('chat/SET_STATE', {
        selectedConversation: {
          ...currentConversation,
          status: true
        },
        assignee: `${this.currentUser.firstname} ${this.currentUser.lastname}`,
        selectedPatient: currentConversation.patient.id,
        messages: []
      });
      await this.$store.dispatch('chat/fetchMessages', {
        patientId: currentConversation.patient.id
      });
      await this.$_failedMessages_loadAdminChatFailedMessages(currentConversation.id);
      await this.$_chatThread_scrollToBottom();
    },
    async reopenConversation() {
      await this.$store.dispatch('chat/reopenConversation', {
        patientId: this.conversation.patient.id
      });
      this.$refs.ChatReopenModal.showDialog = false;
      this.$store.commit('chat/SET_STATE', {
        selectedConversation: null,
        messages: [],
        conversations: this.conversations.filter(conversation => conversation.id !== this.conversation.id)
      });
      this.openMyInboxTab();
    },
    async onMessageSent() {
      var _this$$refs$ChatMessa;
      await sleep(1000);
      this.$store.dispatch('chat/moveChatToTop', this.conversation.id);
      (_this$$refs$ChatMessa = this.$refs.ChatMessagesContainer.$refs.bottomOfPage) === null || _this$$refs$ChatMessa === void 0 ? void 0 : _this$$refs$ChatMessa.scrollIntoView({
        behavior: 'smooth'
      });
    },
    async isOnline() {
      const checkOnlineStatus = async () => {
        const newOnlineStatus = this.conversation.isOnline;
        if (newOnlineStatus !== this.onlineStatus) {
          this.onlineStatus = newOnlineStatus;
          if (newOnlineStatus) {
            clearInterval(this.onlineStatusInterval);
          } else {
            this.onlineStatusInterval = setInterval(() => {
              this.onlineStatus = this.conversation.isOnline;
            }, 1000);
          }
        }
        window.requestAnimationFrame(checkOnlineStatus);
      };
      checkOnlineStatus();
    },
    handleMessageCategoryChanged(newCategory) {
      this.isNote = newCategory === 'note';
    },
    formatDate(date, formatString) {
      return format(new Date(date), formatString);
    },
    resetTypersListState() {
      this.isRecipientTyping = false;
      this.typersList = [];
    },
    async openPatientInfoSlidePanel() {
      this.patientId = this.conversation.patient.id;
      await this.$nextTick();
      this.$refs.PatientsInfoSidePanel.show();
    },
    async openActionDialog() {
      this.patientData = this.conversation.patient;
      await this.$nextTick();
      this.$refs['ActionsActionDialog'].showDialog = true;
    },
    showToast(message, variant = 'danger') {
      this.toast.message = message;
      this.toast.variant = variant;
      this.$bvToast.show('dialogToast');
    }
  }
};