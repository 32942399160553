var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("b-table", {
    ref: "table",
    staticClass: "card-table",
    staticStyle: {
      "max-height": "calc(100vh - 25vh) !important"
    },
    attrs: {
      responsive: "",
      "select-mode": "single",
      fields: _vm.headers,
      items: _vm.items,
      "sticky-header": ""
    },
    scopedSlots: _vm._u([{
      key: "row-details",
      fn: function (row) {
        return [_c("b-row", {
          attrs: {
            "align-h": "center"
          }
        }, [_c("b-col", {
          attrs: {
            cols: "2"
          }
        }, [_c("b", [_vm._v("State")])]), _c("b-col", {
          attrs: {
            cols: "2"
          }
        }, [_c("b", [_vm._v("Max Daily Accept")])])], 1), _vm._l(row.item.coveredstate, function (state) {
          return _c("b-row", {
            key: `${row.item.id}-${state.state}`,
            attrs: {
              "align-h": "center"
            }
          }, [_c("b-col", {
            attrs: {
              cols: "2"
            }
          }, [_vm._v(" " + _vm._s(state.state) + " ")]), _c("b-col", {
            attrs: {
              cols: "2"
            }
          }, [_vm._v(" " + _vm._s(state.limit) + " ")])], 1);
        })];
      }
    }, {
      key: "cell(actions)",
      fn: function (row) {
        return [_vm.userid !== row.item.item.id ? _c("b-link", {
          on: {
            click: function ($event) {
              $event.preventDefault();
              return _vm.onChangeRole(row.item.item);
            }
          }
        }, [_vm._v(" Change Role ")]) : _c("b-link", {
          on: {
            click: function ($event) {
              $event.preventDefault();
              return _vm.onTransferRole(row.item.item);
            }
          }
        }, [_vm._v(" Transfer Role ")])];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };