/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./BaseImage.vue?vue&type=template&id=07b1f988"
import script from "./BaseImage.vue?vue&type=script&lang=js"
export * from "./BaseImage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/admin-dashboard-frontend/admin-dashboard-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('07b1f988')) {
      api.createRecord('07b1f988', component.options)
    } else {
      api.reload('07b1f988', component.options)
    }
    module.hot.accept("./BaseImage.vue?vue&type=template&id=07b1f988", function () {
      api.rerender('07b1f988', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/base/BaseImage.vue"
export default component.exports