var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("SideModal", {
    attrs: {
      show: _vm.show,
      "show-header": false,
      "body-class": "macro-modal-body",
      title: "Macros"
    },
    on: {
      close: _vm.close,
      exit: function ($event) {
        return _vm.$emit("update:show", false);
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_c("div", {
          staticClass: "d-flex flex-column align-items-end",
          class: _vm.show ? "ml-n3" : "",
          staticStyle: {
            height: "100%"
          }
        }, [_c("b-row", {
          staticClass: "tw-border-0 tw-border-b tw-border-solid tw-border-slate-300",
          staticStyle: {
            width: "100%"
          }
        }, [_c("b-col", {
          staticClass: "tw-border-0 tw-border-r tw-border-solid tw-border-slate-300",
          attrs: {
            cols: "3"
          }
        }, [_c("h4", {
          staticClass: "ml-2 pl-1 mt-4 pt-2 tw-font-bold tw-text-[26px]"
        }, [_vm._v(" All Macros ")])]), _c("b-col", [_c("b-input-group", {
          staticClass: "ml-3 mt-2 mb-3"
        }, [_c("b-input-group-prepend", [_c("b-input-group-text", [_c("b-icon-search", {
          staticClass: "tw-text-eclipse py-0"
        })], 1)], 1), _c("b-form-input", {
          ref: "searchMacrosInput",
          staticClass: "h1",
          staticStyle: {
            "padding-bottom": "0"
          },
          attrs: {
            id: "search",
            placeholder: "Search Macros"
          },
          model: {
            value: _vm.search,
            callback: function ($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        })], 1)], 1)], 1), _c("b-row", {
          staticClass: "tw-w-full tw-h-full"
        }, [_c("b-col", {
          staticClass: "tw-border-0 tw-border-r tw-border-solid tw-border-slate-300 px-0",
          attrs: {
            cols: "3"
          }
        }, [_c("perfect-scrollbar", {
          staticClass: "ps tw-max-h-[calc(100vh-172px)]",
          attrs: {
            options: _vm.scrollbarOptions
          }
        }, [_c("div", {
          ref: "topOfModal",
          attrs: {
            id: "topOfModal"
          }
        }), _vm.search && _vm.filteredMacros.length > 0 ? _c("b-list-group", _vm._l(_vm.filteredMacros, function (macro) {
          return _c("b-list-group-item", {
            key: macro.id,
            staticClass: "text-truncate tw-cursor-pointer",
            class: {
              "tw-bg-whisper": _vm.selectedMacro && _vm.selectedMacro.id === macro.id
            },
            on: {
              click: function ($event) {
                return _vm.selectMacro(macro);
              }
            }
          }, [_vm._v(" " + _vm._s(macro.name) + " ")]);
        }), 1) : _vm.search && _vm.filteredMacros.length == 0 ? _c("div", {
          staticClass: "mt-4 ml-4"
        }, [_vm._v(" No results found. ")]) : _c("b-list-group", _vm._l(_vm.macros, function (macro) {
          return _c("b-list-group-item", {
            key: macro.id,
            staticClass: "text-truncate tw-cursor-pointer",
            class: {
              "tw-bg-whisper": _vm.selectedMacro && _vm.selectedMacro.id === macro.id
            },
            on: {
              click: function ($event) {
                return _vm.selectMacro(macro);
              }
            }
          }, [_vm._v(" " + _vm._s(macro.name) + " ")]);
        }), 1)], 1)], 1), _c("b-col", {
          staticClass: "mt-5 ml-3 mr-5"
        }, [_c("b-alert", {
          attrs: {
            show: _vm.alert.show,
            variant: _vm.alert.variant
          }
        }, [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]), _c("h2", {
          staticClass: "tw-font-bold tw-text-lg tw-mb-2"
        }, [_vm._v(" Title ")]), _c("div", {
          staticClass: "tw-border tw-border-solid tw-border-slate-300 tw-rounded-lg"
        }, [_c("b-form-input", {
          ref: "macroTitle",
          staticClass: "focus:tw-border-none tw-border-none tw-bg-transparent",
          attrs: {
            maxlength: "300"
          },
          model: {
            value: _vm.selectedMacro.name,
            callback: function ($$v) {
              _vm.$set(_vm.selectedMacro, "name", $$v);
            },
            expression: "selectedMacro.name"
          }
        })], 1), _c("h2", {
          staticClass: "mt-5 tw-font-bold tw-text-lg tw-mb-2"
        }, [_vm._v(" Body ")]), _c("div", [_c("ComposerEditor", {
          ref: "composer",
          attrs: {
            "enable-send": false,
            "hide-send-button": "",
            "hide-bubble-menu": ""
          },
          scopedSlots: _vm._u([{
            key: "actions",
            fn: function () {
              return [_c("div", {
                staticClass: "tw-flex tw-space-x-2 tw-cursor-pointer tw-mr-3.5 tw-text-muted",
                on: {
                  click: function ($event) {
                    return _vm.$refs.mergeTag.show();
                  }
                }
              }, [_c("IconMergeTag"), _c("b-dropdown", {
                ref: "mergeTag",
                attrs: {
                  "toggle-class": "invisible-dropdown",
                  "no-caret": "",
                  dropleft: "",
                  offset: "-40"
                }
              }, [_c("b-dropdown-header", {
                staticClass: "text-muted"
              }, [_vm._v(" Insert merge tag ")]), _c("b-dropdown-item", {
                on: {
                  click: function ($event) {
                    return _vm.insertMergeTag(" {patient}", $event);
                  }
                }
              }, [_vm._v(" Patient First Name ")]), _c("b-dropdown-item", {
                on: {
                  click: function ($event) {
                    return _vm.insertMergeTag(" {doctor}", $event);
                  }
                }
              }, [_vm._v(" Doctor Last Name (Dr. xxx) ")]), _c("b-dropdown-item", {
                on: {
                  click: function ($event) {
                    return _vm.insertMergeTag(" {admin}", $event);
                  }
                }
              }, [_vm._v(" Admin First Name ")])], 1)], 1)];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedMacro.content,
            callback: function ($$v) {
              _vm.$set(_vm.selectedMacro, "content", $$v);
            },
            expression: "selectedMacro.content"
          }
        })], 1), _vm.selectedMacro && !_vm.isNewMacro ? _c("div", {
          staticClass: "d-flex justify-content-between mt-3",
          staticStyle: {
            width: "100%"
          }
        }, [_c("span", {
          staticClass: "text-muted"
        }, [_vm._v(" Created by: " + _vm._s(_vm.selectedMacro.created_by) + " " + _vm._s(_vm.getTime(_vm.selectedMacro.timestampcreated)) + " "), _c("br"), _vm.selectedMacro.updated_by ? _c("span", [_vm._v("Updated last: " + _vm._s(_vm.selectedMacro.updated_by) + " " + _vm._s(_vm.getTime(_vm.selectedMacro.timestampupdated)))]) : _vm._e()]), _c("span", {
          staticClass: "text-muted"
        }, [_vm._v("used " + _vm._s(_vm.selectedMacro.times_used) + " times")])]) : _vm._e()], 1)], 1), _c("b-row", {
          staticClass: "mt-auto tw-border-0 tw-border-t tw-border-solid tw-border-slate-300",
          staticStyle: {
            width: "100%",
            height: "10%"
          }
        }, [_c("b-col", {
          staticClass: "tw-border-0 tw-border-r tw-border-solid tw-border-slate-300",
          attrs: {
            cols: "3"
          }
        }, [_c("b-button", {
          staticClass: "mt-4 px-6",
          class: {
            "tw-bg-slate-200 tw-text-slate-500 tw-border-transparent hover:tw-border-transparent": _vm.isNewMacro
          },
          attrs: {
            variant: "outline-dark",
            size: "lg",
            disabled: _vm.isNewMacro,
            block: ""
          },
          on: {
            click: function ($event) {
              return _vm.clear();
            }
          }
        }, [_vm._v(" New ")])], 1), _c("b-col", {
          staticClass: "d-flex py-4 pr-5"
        }, [_c("span", {
          staticClass: "mr-auto align-self-center ml-4",
          class: {
            "hover:tw-underline": _vm.selectedMacro.id != null,
            "tw-cursor-pointer": _vm.selectedMacro.id != null,
            "tw-cursor-not-allowed": _vm.selectedMacro.id == null,
            "text-muted": _vm.selectedMacro.id == null
          },
          attrs: {
            disabled: _vm.isNewMacro
          },
          on: {
            click: _vm.onShowDeleteDialog
          }
        }, [_vm._v("Delete")]), _c("span", {
          staticClass: "align-self-center mx-6",
          class: {
            "hover:tw-underline": !_vm.isActionDisabled,
            "tw-cursor-pointer": !_vm.isActionDisabled,
            "tw-cursor-not-allowed": _vm.isActionDisabled,
            "text-muted": _vm.isActionDisabled
          },
          on: {
            click: function ($event) {
              return _vm.cancelMacro();
            }
          }
        }, [_vm._v("Cancel")]), _c("b-button", {
          staticClass: "px-6 ml-2",
          attrs: {
            variant: "secondary",
            size: "lg",
            disabled: _vm.isActionDisabled
          },
          on: {
            click: _vm.save
          }
        }, [_vm.loading ? _c("b-spinner", {
          attrs: {
            small: ""
          }
        }) : _c("div", [_vm.isNewMacro ? _c("b", [_vm._v("Save")]) : _c("b", [_vm._v("Update")])])], 1)], 1)], 1)], 1), _c("b-modal", {
          staticStyle: {
            "z-index": "700"
          },
          attrs: {
            "hide-header": "",
            "hide-footer": "",
            centered: "",
            "no-stacking": "",
            size: "lg"
          },
          model: {
            value: _vm.showDeleteDialog,
            callback: function ($$v) {
              _vm.showDeleteDialog = $$v;
            },
            expression: "showDeleteDialog"
          }
        }, [_c("b-row", {
          staticClass: "mb-4",
          attrs: {
            "align-h": "between"
          }
        }, [_c("b-col", {
          attrs: {
            cols: "10"
          }
        }, [_c("img", {
          attrs: {
            src: require("@/assets/images/icon_logo_violet.svg"),
            alt: ""
          }
        })]), _c("b-col", {
          staticClass: "text-right"
        }, [_c("a", {
          staticClass: "tw-text-eclipse",
          attrs: {
            href: "javascript:;"
          },
          on: {
            click: function ($event) {
              _vm.showDeleteDialog = false;
            }
          }
        }, [_c("b-icon", {
          attrs: {
            icon: "x-circle",
            "font-scale": "1.5"
          }
        })], 1)])], 1), _c("b-row", [_c("b-col", {
          attrs: {
            cols: "12"
          }
        }, [_c("h2", {
          staticClass: "heading tw-mb-2 tw-text-2xl"
        }, [_vm._v(" Deleting macro ")]), _c("p", {
          staticClass: "tw-text-muted tw-text-base"
        }, [_vm._v(" Are you sure you want to delete this macro? ")])])], 1), _c("b-row", [_c("b-col", {
          staticClass: "mb-3",
          attrs: {
            offset: "4",
            cols: "4"
          }
        }, [_c("b-button", {
          attrs: {
            pill: "",
            block: "",
            variant: "secondary",
            disabled: _vm.deleteLoading
          },
          on: {
            click: _vm.deleteMacro
          }
        }, [_vm._v(" Delete ")])], 1), _c("b-col", {
          attrs: {
            cols: "4"
          }
        }, [_c("b-button", {
          attrs: {
            pill: "",
            block: "",
            variant: "outline-dark",
            disabled: _vm.deleteLoading
          },
          on: {
            click: function ($event) {
              _vm.showDeleteDialog = false;
            }
          }
        }, [_vm._v(" Cancel ")])], 1)], 1)], 1), _c("b-modal", {
          staticStyle: {
            "z-index": "700"
          },
          attrs: {
            "hide-header": "",
            "hide-footer": "",
            centered: "",
            "no-stacking": "",
            size: "lg"
          },
          model: {
            value: _vm.showDiscardDialog,
            callback: function ($$v) {
              _vm.showDiscardDialog = $$v;
            },
            expression: "showDiscardDialog"
          }
        }, [_c("b-row", {
          staticClass: "mb-4",
          attrs: {
            "align-h": "between"
          }
        }, [_c("b-col", {
          attrs: {
            cols: "10"
          }
        }, [_c("img", {
          attrs: {
            src: require("@/assets/images/icon_logo_violet.svg"),
            alt: ""
          }
        })]), _c("b-col", {
          staticClass: "text-right"
        }, [_c("a", {
          staticClass: "tw-text-eclipse",
          attrs: {
            href: "javascript:;"
          },
          on: {
            click: function ($event) {
              _vm.showDiscardDialog = false;
            }
          }
        }, [_c("b-icon", {
          attrs: {
            icon: "x-circle",
            "font-scale": "1.5"
          }
        })], 1)])], 1), _c("b-row", [_c("b-col", [_c("h2", {
          staticClass: "heading tw-mb-2 tw-text-2xl"
        }, [_vm._v(" You have unsaved changes ")]), _c("p", {
          staticClass: "tw-text-muted tw-text-base"
        }, [_vm._v(" You have edited this macro. Do you want to discard these changes? ")])])], 1), _c("b-row", [_c("b-col", {
          staticClass: "mb-3",
          attrs: {
            offset: "4",
            cols: "4"
          }
        }, [_vm.discardAction == "switch" ? _c("b-button", {
          attrs: {
            pill: "",
            block: "",
            variant: "outline-dark"
          },
          on: {
            click: function ($event) {
              return _vm.selectMacro(_vm.macroToSelect, true);
            }
          }
        }, [_vm._v(" Discard ")]) : _vm._e(), _vm.discardAction == "cancel" ? _c("b-button", {
          attrs: {
            pill: "",
            block: "",
            variant: "outline-dark"
          },
          on: {
            click: function ($event) {
              return _vm.cancelMacro(true);
            }
          }
        }, [_vm._v(" Discard ")]) : _vm._e()], 1), _c("b-col", {
          attrs: {
            cols: "4"
          }
        }, [_c("b-button", {
          attrs: {
            pill: "",
            block: "",
            variant: "secondary"
          },
          on: {
            click: function ($event) {
              _vm.showDiscardDialog = false;
            }
          }
        }, [_vm._v(" Continue Editing ")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };