import "core-js/modules/es.array.push.js";
import "core-js/modules/es.string.replace-all.js";
import { v4 as uuidv4 } from 'uuid';
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import { ActionService } from '@/services/action.service';

// components
import IconMacro from '@/components/icon/IconMacro.vue';
import IconChat from '@/components/icon/IconChat.vue';
import IconChatNote from '@/components/icon/IconChatNote.vue';
import ComposerEditor from '@corefront/components-v2/Composer/ComposerEditor';

// constants
import { TYPES, MESSAGE_SEND_STATUS, EVENT_CATEGORY, EVENT_NAME } from '@corefront/constant/messages';

// mixins
import { failedMessagesMixin } from '@/mixins/failedMessages';
import { chatThread } from '@corefront/mixins-v2/chatThread';

// socket
import socket from '@corefront/utils/socket';
export default {
  components: {
    IconMacro,
    IconChat,
    IconChatNote,
    ComposerEditor
  },
  mixins: [chatThread, failedMessagesMixin],
  props: {
    conversation: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      files: [],
      message: '',
      messageCategory: 'chat',
      macroIds: [],
      macroLoading: false,
      socket
    };
  },
  computed: {
    ...mapGetters({
      messageTemplate: 'chat/getMessageTemplate'
    }),
    ...mapState('auth', {
      currentUser: 'currentUser'
    }),
    ...mapState('chat', {
      messages: 'messages'
    })
  },
  watch: {
    messageCategory(newValue) {
      this.emitMessageCategory(newValue);
    },
    async messageTemplate(msgObject) {
      this.macroLoading = true;
      let msg = msgObject.content;
      msg = msg.replace(/(?:\r\n|\r|\n)/g, '<br>');
      if (msg) {
        if (msg.includes('{patient}')) {
          msg = msg.replaceAll('{patient}', this.conversation.patient.firstName);
        }
        if (msg.includes('{admin}')) {
          msg = msg.replaceAll('{admin}', this.currentUser.firstname);
        }
        if (msg.includes('{doctor}')) {
          msg = msg.replaceAll('{doctor}', `Dr. ${this.conversation.doctor.doctorLastName}`);
        }
        if (!this.macroIds.includes(msgObject.id)) {
          this.macroIds.push(msgObject.id);
        }
        this.message = this.message + msg + '\n';
      }
      this.macroLoading = false;
    },
    message(msg) {
      if (msg == '') {
        this.macroIds = [];
      }
    }
  },
  methods: {
    openMacroDialog() {
      this.$store.commit('chat/SET_OPEN_MACRO', true);
    },
    async sendChatMessage() {
      if (!this.message.trim().length && !this.files.length) {
        return;
      }
      const message = {
        id: uuidv4(),
        patientId: this.conversation.patient.id,
        sentAt: Math.floor(Date.now() / 1000),
        message: this.message,
        messageTemplateIds: this.macroIds.length > 0 ? this.macroIds : undefined,
        senderId: this.currentUser.id,
        senderRole: this.currentUser.roletype,
        files: this.files,
        conversationId: this.conversation.id,
        sender: {
          id: this.currentUser.id,
          role: this.currentUser.roletype,
          firstName: this.currentUser.firstname,
          lastName: this.currentUser.lastname,
          email: this.currentUser.email
        },
        type: TYPES.MESSAGE,
        patient: this.conversation.patient
      };
      this.macroIds = [];
      this.message = '';
      this.$refs['composer'].clearAttachments();
      await this.$_failedMessages_sendAdminPatientChatMessage({
        message
      });
    },
    async sendNoteMessage() {
      if (!this.message.trim().length) {
        return;
      }
      const message = {
        patientId: this.conversation.patient.id,
        sentAt: Math.floor(Date.now() / 1000),
        message: this.message,
        authorId: this.currentUser.id,
        eventCategory: EVENT_CATEGORY.NOTES,
        event: EVENT_NAME.NOTE,
        eventName: EVENT_NAME.NOTE,
        noteBy: `${this.currentUser.firstname} ${this.currentUser.lastname}`,
        messageBody: this.message,
        timestampInteraction: Math.floor(Date.now() / 1000),
        conversationId: this.conversation.id,
        type: TYPES.NOTE,
        sender: {
          firstName: this.currentUser.firstname,
          lastName: this.currentUser.lastname
        }
      };
      try {
        const entry = {
          type: TYPES.NOTE,
          data: message,
          sentStatus: MESSAGE_SEND_STATUS.PENDING
        };
        this.$store.commit('chat/APPEND_MESSAGE', entry);
        this.$_chatThread_scrollToBottom();
        await ActionService.addActionNote(message.patientId, {
          actionid: 'ac-all',
          notes: message.message,
          action: 'Other',
          source: 'APC'
        });
        this.messages[0].sentStatus = MESSAGE_SEND_STATUS.SENT;
        this.$store.commit('chat/UPDATE_CONVERSATION_BY_ID', {
          id: message.conversationId,
          sender: message.sender,
          lastMessage: message.message,
          lastMessageSenderRole: message.noteBy,
          lastMessageActivity: message.sentAt,
          lastMessageType: message.type
        });
      } catch (error) {
        console.log(error);
        this.messages[0].sentStatus = MESSAGE_SEND_STATUS.FAILED;
      }
    },
    async sendMessage() {
      if (this.messageCategory === 'chat') {
        await this.sendChatMessage();
      } else {
        await this.sendNoteMessage();
      }
      if (!this.conversation.status && !this.conversation.assignee) {
        this.$emit('assign-to-self');
      }
      this.message = '';
    },
    toggleMessageCategory() {
      this.messageCategory = this.messageCategory === 'chat' ? 'note' : 'chat';
    },
    emitMessageCategory() {
      this.$emit('messageCategoryChanged', this.messageCategory);
    }
  }
};