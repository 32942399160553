import { mapState } from 'vuex';
import { ActionService } from '@/services/action.service';

// constants
import { TYPES, MESSAGE_SEND_STATUS, EVENT_CATEGORY, EVENT_NAME } from '@corefront/constant/messages';

// mixins
import { chatThread } from '@corefront/mixins-v2/chatThread';
export default {
  mixins: [chatThread],
  props: {
    rowData: {
      type: Object,
      default() {
        return {
          firstname: '',
          lastname: ''
        };
      }
    },
    customId: {
      type: String,
      default: ''
    },
    actionId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showDialog: false,
      alert: {
        show: false,
        message: ''
      },
      actionText: '',
      buttonDisabled: false,
      selectedPurpose: '',
      purposeList: ['ID/Photo Upload', 'Failed Payment', 'Follow Up', 'Needs Reply', 'Verify Address', 'Onboarding', 'Other', 'Refund'],
      selectedAction: 'Phone Call',
      actionList: ['Phone Call', 'Other', 'SMS', 'Email'],
      selectedExtras: '',
      resultList: ['Left VM', 'Spoke to Patient', 'Mailbox Full']
    };
  },
  computed: {
    currentDate() {
      return new Date().toDateString();
    },
    ...mapState('auth', ['currentUser']),
    ...mapState('chat', ['selectedConversation', 'messages'])
  },
  watch: {
    showDialog() {
      if (!this.showDialog) {
        this.actionText = '';
        this.selectedPurpose = '';
        this.selectedAction = 'Phone Call';
        this.selectedExtras = '';
      } else {
        if (this.actionId === 'verificationrequired') {
          this.selectedPurpose = 'ID/Photo Upload';
        } else if (this.actionId === 'failedtransaction') {
          this.selectedPurpose = 'Failed Payment';
        } else if (this.actionId === 'followupstart' || this.actionId === 'followupcompletion') {
          this.selectedPurpose = 'Follow Up';
        } else if (this.actionId === 'needspatientreply') {
          this.selectedPurpose = 'Needs Reply';
        } else if (this.actionId === 'failedaddress') {
          this.selectedPurpose = 'Verify Address';
        } else if (this.actionId === 'onboarding') {
          this.selectedPurpose = 'Onboarding';
        } else {
          this.selectedPurpose = 'Other';
        }
      }
    }
  },
  methods: {
    toggleDialog() {
      this.showDialog = !this.showDialog;
    },
    async addNoteToConversation(data) {
      const message = {
        patientId: data.patientid,
        sentAt: Math.floor(Date.now() / 1000),
        message: data.notes,
        authorId: this.currentUser.id,
        eventCategory: EVENT_CATEGORY.NOTES,
        event: EVENT_NAME.NOTE,
        eventName: EVENT_NAME.NOTE,
        noteBy: `${this.currentUser.firstname} ${this.currentUser.lastname}`,
        messageBody: data.notes,
        timestampInteraction: Math.floor(Date.now() / 1000),
        conversationId: this.selectedConversation.id,
        type: TYPES.NOTE,
        sender: {
          firstName: this.currentUser.firstname,
          lastName: this.currentUser.lastname
        }
      };
      const entry = {
        type: TYPES.NOTE,
        data: message,
        sentStatus: MESSAGE_SEND_STATUS.SENT
      };
      this.$store.commit('chat/APPEND_MESSAGE', entry);
      this.$_chatThread_scrollToBottom();
      this.$store.commit('chat/UPDATE_CONVERSATION_BY_ID', {
        id: message.conversationId,
        sender: message.sender,
        lastMessage: message.message,
        lastMessageSenderRole: message.noteBy,
        lastMessageActivity: message.sentAt,
        lastMessageType: message.type
      });
    },
    async save() {
      const actionId = this.actionId == 'all' ? 'all' : this.actionId;
      const body = {
        actionid: actionId,
        notes: this.actionText,
        purpose: this.selectedPurpose,
        action: this.selectedAction,
        ...(this.selectedExtras && {
          extras: [this.selectedExtras]
        })
      };
      const id = this.customId ? this.customId : this.rowData.id;
      try {
        const {
          data
        } = await ActionService.addActionNote(id, body);
        if (data) {
          this.addNoteToConversation(data);
          this.$emit('show-toast', 'Note added successfully!', 'success');
          this.$emit('update-time', this.rowData.id);
          this.toggleDialog();
        }
      } catch (error) {
        console.log(error);
        this.$emit('show-toast', error);
      }
    }
  }
};