import "core-js/modules/es.array.push.js";
// libs
import { mapState } from 'vuex';
import _ from 'lodash';

// mixins
import { messageHeightMixin } from '@/mixins/messageHeightMixin';

// utils
import { trimHtmlTags } from '@corefront/utils/trimHtmlTags';

// components
import IconLoading from '@/components/icon/IconLoading.vue';
export default {
  components: {
    IconLoading
  },
  mixins: [messageHeightMixin],
  props: {
    conversation: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isSearching: false,
      filter: '',
      fetchingNextPage: false
    };
  },
  computed: {
    ...mapState('message', ['converstationList', 'conversationListPageNumber', 'conversationListNextPage']),
    hasUndefinedNextPage() {
      return this.conversationListNextPage.some(item => !item);
    },
    resultQuery() {
      return _.orderBy(this.converstationList, ['lastactivity'], ['desc']);
    },
    paginatedConversationList() {
      const itemsPerPage = 50;
      const conversationListPageNumber = this.conversationListPageNumber;
      return this.resultQuery.slice((conversationListPageNumber - 1) * itemsPerPage, conversationListPageNumber * itemsPerPage);
    }
  },
  methods: {
    trimHtmlTags,
    onLoadAllConversations() {
      this.$router.push('/messages');
      this.$emit('fetch-conversations');
    },
    onConversationSearch: _.debounce(async function () {
      this.isSearching = !!this.filter;
      await this.$store.dispatch('message/searchConversationList', this.filter);
      this.isSearching = false;
    }, 350),
    async fetchPreviousConversationList() {
      this.$refs.conversationListRef.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      this.$store.dispatch('message/previousConversationListPage');
    },
    onMarkUnread(conversationid) {
      const conversationList = this.converstationList;
      const idx = conversationList.findIndex(x => x.id == conversationid);
      if (idx > -1) {
        conversationList[idx].unreadcount = true;
      }
      this.$store.commit('message/SET_CONVERSATION', conversationList);
      this.$store.dispatch('message/markAsUnread', {
        conversationid
      });
    }
  }
};