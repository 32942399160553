// libs
import { fromUnixTime } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { mapState, mapGetters } from 'vuex';
import _isEmpty from 'lodash/isEmpty';

// services
import { ChatService } from '@/services/chat.service.js';

// assets
import audio from '@/assets/notifications/pristine.mp3';
const notificationSound = new Audio(audio);

// constants
import { MESSAGE_ACTION, MESSAGE_TYPE } from '@corefront/constant/messages';

// mixins
import { messageHeightMixin } from '@/mixins/messageHeightMixin';
import { chatThread } from '@corefront/mixins-v2/chatThread';
import { failedMessagesMixin } from '@/mixins/failedMessages';
import heightObserver from '@/assets/js/observeHeight.js';

// components
import PatientsInfoSidePanel from '@/components/patients/PatientsInfoSidePanel';
import ComposerEditor from '@corefront/components-v2/Composer/ComposerEditor.vue';
import ChatThread from '@corefront/components-v2/Chat/ChatThread';
import MessagesChatBubble from '@/components/messages/MessagesChatBubble.vue';

// corefront utils
import { sleep } from '@corefront/utils/sleep';

// socket
import socket from '@corefront/utils/socket';
export default {
  components: {
    ComposerEditor,
    PatientsInfoSidePanel,
    ChatThread,
    MessagesChatBubble
  },
  mixins: [heightObserver, messageHeightMixin, chatThread, failedMessagesMixin],
  data() {
    return {
      patientName: '',
      isSending: false,
      newMessage: null,
      loading: false,
      conversation: {},
      conversationId: '',
      patientid: '',
      socket,
      toastMessage: ''
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    ...mapGetters('message', ['conversationDoctor']),
    ...mapState('message', ['converstationList', 'conversationMembers', 'messages', 'messagesNextPagePointer'])
  },
  watch: {
    conversationlist: {
      deep: true,
      handler() {
        if (this.$route.query.patientid) {
          this.conversationId = this.converstationList[0].id;
        }
      }
    }
  },
  mounted() {
    this.initSocketListeners();
    this.$store.commit('message/SET_STATE', {
      state: 'conversationMembers',
      value: []
    });
    this.$_failedMessages_pollSaveFailedMessages();
  },
  methods: {
    viewPatientInfo() {
      this.$refs.PatientsInfoSidePanel.show();
    },
    async sendMessage({
      attachments
    }) {
      try {
        if (_isEmpty(this.newMessage.trim()) && _isEmpty(attachments)) {
          return;
        }
        if (this.isSending) {
          return;
        }
        this.isSending = true;
        await this.$_failedMessages_sendAdminMessage({
          message: {
            id: uuidv4(),
            conversationId: this.conversation.id,
            message: this.newMessage,
            files: attachments
          }
        });
      } catch (error) {
        console.log(error);
        return false;
      } finally {
        this.isSending = false;
        this.newMessage = '';
        this.$refs['composer-editor'].clear();
      }
    },
    async openConversation(conversation) {
      this.conversationId = conversation.id;
      this.patientName = conversation.name;
      this.patientid = conversation.patientid;
      this.listMessage(conversation.id);
      ChatService.markConversationAsRead(conversation.id);
      this.$store.commit('message/UPDATE_CONVERSATION_BY_ID', {
        id: conversation.id,
        unreadcount: false
      });
    },
    async listMessage(conversationId) {
      try {
        this.newMessage = '';
        this.loading = true;
        this.loadingMessage = false;
        this.conversation = this.converstationList.find(x => x.id === conversationId);
        await this.$store.dispatch('message/getConversationMessages', conversationId);
        await Promise.all([this.$_failedMessages_loadAdminMessageFailedMessages(conversationId), this.$store.dispatch('message/getConversationMembers', conversationId)]);
      } catch (error) {
        console.log(error.message);
      } finally {
        this.loading = false;
        await sleep(10);
        this.$_chatThread_scrollToBottom();
      }
    },
    async fetchNextPage() {
      this.$store.dispatch('message/getConversationNextPageMessages', this.conversationId);
    },
    chatThreadDateResolver(msg) {
      return fromUnixTime(msg.sentat);
    },
    async initSocketListeners() {
      await socket.waitUntilReady();
      socket.ctx.on('message', message => {
        if (message.conversationid === this.conversationId) {
          this.onMessageReceived(message);
        }
        if (!this.$route.query.patientid) {
          this.onConversationUpdate(message);
        }
      });
    },
    async onConversationUpdate(message) {
      var _this$$refs$conversat;
      this.loading = false;
      (_this$$refs$conversat = this.$refs.conversationListRef) === null || _this$$refs$conversat === void 0 ? void 0 : _this$$refs$conversat.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      const idx = this.converstationList.findIndex(x => x.id == message.conversationid);
      const isCurrentConversation = this.conversationId === message.conversationid;
      if (isCurrentConversation) {
        this.$_chatThread_scrollToBottom();
      }
      this.$store.commit('message/UPDATE_CONVERSATION_BY_ID', {
        id: message.conversationid,
        unreadcount: isCurrentConversation ? 0 : 1,
        lastactivity: message === null || message === void 0 ? void 0 : message.sentat,
        lastmessage: message === null || message === void 0 ? void 0 : message.message
      });
      if (idx > -1) {
        const removedItem = this.converstationList.splice(idx, 1);
        this.converstationList.splice(0, 0, removedItem[0]);
      } else {
        const {
          data: conversation
        } = await ChatService.getConversationInfo(message.conversationid);
        if (this.conversationId !== message.conversationid) {
          conversation.unreadcount = 1;
        }
        this.converstationList.splice(0, 0, conversation);
      }
    },
    async onMessageReceived(message) {
      if (message.action === MESSAGE_ACTION.SENT) {
        const messageClone = message;
        this.$store.commit('message/UPDATE_CONVERSATION_BY_ID', {
          id: messageClone.conversationid,
          unreadcount: false,
          lastmessage: messageClone.message
        });
        if (messageClone.userid !== this.currentUser.id) {
          notificationSound.play();
          this.$store.dispatch('message/markAsRead', {
            conversationid: messageClone.conversationid,
            messageid: messageClone.id
          });
        }
        if (messageClone.type === 'prescription_confirmation') {
          if (messageClone.custommessage.constructor.name === 'String') {
            messageClone.custommessage = JSON.parse(messageClone.custommessage);
          }
        }
        this.messages.splice(0, 0, messageClone);
        this.loading = false;
        this.$_chatThread_scrollToBottom();
      } else if (message.action === MESSAGE_ACTION.UPDATED) {
        const messageupdate = message;
        const messagetoupdate = this.messages.find(x => x.id === messageupdate.id);
        if (!messagetoupdate) {
          return;
        }
        if (messagetoupdate.type === MESSAGE_TYPE.PRESCRIPTION_CONFIRMATION) {
          this.$store.commit('message/UPDATE_MESSAGE_BY_ID', {
            id: messageupdate.id,
            actionstatus: messageupdate.actionstatus
          });
          notificationSound.play();
        }
        if (messagetoupdate.type === MESSAGE_TYPE.PRESCRIPTION_FOLLOWUP) {
          this.$store.commit('message/UPDATE_MESSAGE_BY_ID', {
            id: messageupdate.id,
            actionstatus: messageupdate.actionstatus,
            custommessage: JSON.parse(messageupdate.custommessage)
          });
          notificationSound.play();
        }
      }
    }
  }
};