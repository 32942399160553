var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    style: {
      height: `${_vm.$_chat_bubbleListHeight}px`,
      maxHeight: `${_vm.$_chat_bubbleListHeight}px`
    }
  }, [_vm.isFetchingMessages || _vm.loading ? _c("div", {
    staticClass: "tw-size-full tw-flex tw-justify-center tw-items-center tw-text-muted tw-select-none"
  }, [_vm._v(" Loading conversation details ")]) : !_vm.selectedConversation ? _c("div", {
    staticClass: "tw-size-full tw-flex tw-justify-center tw-items-center tw-text-muted tw-select-none"
  }, [_vm._v(" Select patient to view conversation ")]) : !_vm.messages.length ? _c("div", {
    staticClass: "tw-size-full tw-flex tw-justify-center tw-items-center tw-text-muted tw-select-none"
  }, [_vm._v(" Start the conversation ")]) : _c("ChatThread", {
    style: {
      height: `${_vm.$_chat_bubbleListHeight}px`,
      maxHeight: `${_vm.$_chat_bubbleListHeight}px`
    },
    attrs: {
      messages: _vm.messages,
      "date-resolver": e => e.sentat * 1000
    },
    scopedSlots: _vm._u([{
      key: "bubble",
      fn: function ({
        message
      }) {
        return [_c("RoutedMessagesBubble", {
          attrs: {
            message: message
          }
        })];
      }
    }])
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };