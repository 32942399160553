import "core-js/modules/es.array.push.js";
import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/web.structured-clone.js";
import moment from 'moment';
import constants from '@/constants';
import { mapState } from 'vuex';
import SideModal from '@/components/modals/SideModal';
import { MacroService } from '@/services/macro.service';
import ComposerEditor from '@corefront/components-v2/Composer/ComposerEditor.vue';

// Components
import IconMergeTag from '@/components/icon/IconMergeTag.vue';

// utils
import { trimHtmlTags } from '@corefront/utils/trimHtmlTags';
export default {
  components: {
    SideModal,
    ComposerEditor,
    IconMergeTag
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      alert: {
        show: false,
        message: '',
        variant: 'warning'
      },
      selectedMacro: {
        id: null,
        name: '',
        content: ''
      },
      macros: [],
      filteredMacros: [],
      categoryId: null,
      search: '',
      loading: false,
      scrollbarOptions: {
        suppressScrollX: true
      },
      showDeleteDialog: false,
      deleteLoading: false,
      showDiscardDialog: false,
      discardAction: 'switch',
      macroToSelect: null
    };
  },
  computed: {
    isNewMacro() {
      if (this.macros.length === 0) {
        return true;
      } else {
        const found = this.macros.find(macro => macro.id === this.selectedMacro.id);
        return this.selectedMacro.id === null || !found;
      }
    },
    isActionDisabled() {
      const isEmptySelectedMacroName = this.selectedMacro.name.trim() === '';
      const isEmptySelectedMacroContent = trimHtmlTags(this.selectedMacro.content).trim() === '';
      if (isEmptySelectedMacroName || isEmptySelectedMacroContent) {
        return true;
      }
      if (!this.isNewMacro) {
        const baseMacro = this.macros.find(macro => macro.id === this.selectedMacro.id);
        return baseMacro.name === this.selectedMacro.name && trimHtmlTags(baseMacro.content) === trimHtmlTags(this.selectedMacro.content);
      }
      return false;
    },
    ...mapState('admins', ['admins']),
    ...mapState('auth', ['currentUser'])
  },
  watch: {
    search(val) {
      if (val) {
        this.filteredMacros = this.macros.filter(macro => macro.name.toLowerCase().includes(val.toLowerCase()) || macro.content.toLowerCase().includes(val.toLowerCase()));
      } else {
        this.filteredMacros = this.macros;
      }
    },
    async show(val) {
      if (val) {
        this.categoryId = constants.macroCategoryId;
        const {
          data
        } = await MacroService.getAllMacros(this.categoryId);
        this.macros = data;
        if (this.macros.length > 0) {
          this.selectedMacro = structuredClone(this.macros[0]);
        }
        const topOfPage = document.getElementById('topOfModal');
        topOfPage.scrollIntoView();
        if (this.macros.length === 0) {
          this.$refs.macroTitle.focus();
        } else {
          this.$refs.searchMacrosInput.focus();
        }
      } else {
        this.alert.show = false;
        this.search = '';
        const topOfPage = document.getElementById('topOfModal');
        topOfPage.scrollIntoView();
      }
    }
  },
  async mounted() {
    await this.$store.dispatch('admins/getAdmins');
  },
  methods: {
    showAlert(message, variant = 'warning') {
      this.alert.show = true;
      this.alert.variant = variant;
      this.alert.message = message;
    },
    close() {
      this.search = '';
      this.$emit('update:show', false);
    },
    selectMacro(macro, override = false) {
      this.alert.show = false;
      if (!this.isNewMacro && !override) {
        // Update macro newline to break tag
        macro.content = macro.content.replace(/(?:\r\n|\r|\n)/g, '<br>');

        // Get the selected macro from the list
        const macroFromList = this.macros.find(m => m.id === this.selectedMacro.id);
        // If macro has been changed
        if (macroFromList.name != this.selectedMacro.name || macroFromList.content != this.selectedMacro.content) {
          this.discardAction = 'switch';
          this.showDiscardDialog = true;
          this.macroToSelect = macro;
        } else {
          // Macro is unedited
          this.selectedMacro = structuredClone(macro);
        }
        // For new macro and override
      } else {
        this.selectedMacro = structuredClone(macro);
        this.showDiscardDialog = false;
      }
    },
    async deleteMacro() {
      if (this.selectedMacro.id === null) {
        return;
      }
      this.alert.show = false;
      this.deleteLoading = true;
      const index = this.macros.findIndex(macro => macro.id === this.selectedMacro.id);
      this.macros.splice(index, 1);
      // Filtered macros
      if (this.search) {
        const filteredIndex = this.filteredMacros.findIndex(macro => macro.id === this.selectedMacro.id);
        this.filteredMacros.splice(filteredIndex, 1);
      }
      await MacroService.deleteMacro(this.categoryId, this.selectedMacro.id);
      this.clear();
      this.showAlert('Macro deleted', 'success');
      this.showDeleteDialog = false;
      this.deleteLoading = false;
      if (this.macros.length > 0) {
        this.selectedMacro = structuredClone(this.macros[0]);
      }
      await this.$nextTick();
      this.$refs.macroTitle.focus();
    },
    clear() {
      this.alert.show = false;
      this.selectedMacro = {
        id: null,
        name: '',
        content: ''
      };
      this.$refs.macroTitle.focus();
    },
    async save() {
      this.alert.show = false;
      if (!this.selectedMacro.name || !this.selectedMacro.content) {
        this.showAlert('Please fill in all fields');
        return;
      }
      if (this.isWhitespaceString(this.selectedMacro.name) || this.isWhitespaceString(this.selectedMacro.content)) {
        this.showAlert('Whitespace only fields are not allowed.');
        return;
      }
      const toSubmit = {
        name: this.selectedMacro.name,
        content: this.selectedMacro.content
      };
      this.loading = true;
      if (this.isNewMacro) {
        const {
          data
        } = await MacroService.createMacro(this.categoryId, toSubmit);
        this.showAlert('Macro saved', 'success');

        // Clone data into selectedMacro
        this.selectedMacro = structuredClone(data);
        // Set the created by and timestamp created
        this.selectedMacro.created_by = `${this.currentUser.firstname} ${this.currentUser.lastname}`;
        this.selectedMacro.timestampcreated = moment().unix();
        // Clone macro into main list
        this.macros.push(structuredClone(this.selectedMacro));
        if (this.search) {
          this.filteredMacros.push(structuredClone(data));
        }
      } else {
        const index = this.macros.findIndex(macro => macro.id === this.selectedMacro.id);
        await MacroService.updateMacro(this.categoryId, this.selectedMacro.id, toSubmit);
        this.selectedMacro.last_updated_by = this.currentUser.id;
        this.selectedMacro.updated_by = `${this.currentUser.firstname} ${this.currentUser.lastname}`;
        this.selectedMacro.timestampupdated = moment().unix();
        this.$set(this.macros, index, structuredClone(this.selectedMacro));
        if (this.search) {
          const filteredIndex = this.filteredMacros.findIndex(macro => macro.id === this.selectedMacro.id);
          this.$set(this.filteredMacros, filteredIndex, structuredClone(this.selectedMacro));
        }
        this.showAlert('Macro updated', 'success');
      }
      this.loading = false;
    },
    isWhitespaceString(str) {
      return !str.replace(/\s/g, '').length;
    },
    insertMergeTag(text, event) {
      event.stopPropagation();
      const editor = this.$refs.composer.editor;
      const startPos = editor.state.selection.from;
      const endPos = editor.state.selection.to;
      editor.commands.insertContentAt({
        start: startPos,
        end: endPos
      }, text);
      editor.commands.focus();
      this.$refs.mergeTag.hide();
    },
    cancelMacro(override = false) {
      if (this.isActionDisabled) {
        return;
      }
      if (!this.isNewMacro) {
        console.log('override', override);
        if (override) {
          const baseMacro = this.macros.find(macro => macro.id === this.selectedMacro.id);
          this.selectedMacro = structuredClone(baseMacro);
          this.showDiscardDialog = false;
        } else {
          this.showDiscardDialog = true;
          this.discardAction = 'cancel';
        }
      } else {
        this.clear();
        if (this.macros.length > 0) {
          this.selectedMacro = structuredClone(this.macros[0]);
        }
      }
    },
    getTime(date) {
      if (date) {
        return moment(date * 1000).format('MM/DD/YY hh:mm A');
      } else {
        return '';
      }
    },
    onShowDeleteDialog() {
      if (this.isNewMacro) {
        return;
      }
      this.showDeleteDialog = true;
    }
  }
};