import { InventoryService } from '@/services/inventory.service';
export default {
  data() {
    return {
      alert: {
        message: '',
        show: false
      },
      rowData: null,
      showDialog: false,
      loading: false
    };
  },
  watch: {
    showDialog(show) {
      if (!show) {
        this.rowData = null;
      }
    }
  },
  methods: {
    async deleteItem() {
      this.loading = true;
      this.alert.show = false;
      // TEMPORARY
      try {
        const {
          status
        } = await InventoryService.deleteInventoryRestock(this.rowData.id);
        if (status == 200) {
          this.$emit('refresh');
          this.showDialog = false;
        }
      } catch (err) {
        this.alert.message = err;
        this.alert.show = true;
      }
    }
  }
};