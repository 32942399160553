import { format, fromUnixTime, differenceInDays } from 'date-fns';
export default {
  props: {
    selected: {
      type: Boolean,
      default: false
    },
    enableControls: {
      type: Boolean,
      default: false
    },
    prescription: {
      type: Object,
      required: true
    },
    timer: {
      type: Number,
      default: 0,
      required: false
    },
    ongoingItems: {
      type: Array,
      default() {
        return [];
      }
    },
    // Not the same as slideout patient data
    /* 
      {
        firstname: 'John',
        lastname: 'Doe',
        hysterectomy: false,
      }
    */
    patientData: {
      type: Object,
      default() {
        return {
          firstname: '',
          lastname: '',
          hysterectomy: false
        };
      }
    }
  },
  data() {
    return {
      statusMapper: {
        DRAFTED: {
          text: 'Draft',
          controls: {
            play: false,
            pause: false,
            stop: true
          }
        },
        PROCESSING: {
          text: 'Processing',
          controls: {
            play: false,
            pause: false,
            stop: false
          }
        },
        PRESCRIBED: {
          text: 'Prescribed',
          controls: {
            play: false,
            pause: true,
            stop: true
          }
        },
        ORDERED: {
          text: 'Ordered',
          controls: {
            play: false,
            pause: true,
            stop: true
          }
        },
        SCHEDULED: {
          text: 'Scheduled',
          controls: {
            play: true,
            pause: false,
            stop: true
          }
        },
        FAILED: {
          text: 'Failed',
          controls: {
            play: true,
            pause: false,
            stop: true
          }
        },
        PAUSED: {
          text: 'Paused',
          controls: {
            play: true,
            pause: false,
            stop: true
          }
        },
        COMPLETED: {
          text: 'Completed',
          controls: {
            play: false,
            pause: false,
            stop: false
          }
        },
        WAITING: {
          text: 'Waiting',
          controls: {
            play: false,
            pause: true,
            stop: true
          }
        },
        CANCELLED: {
          text: 'Cancelled',
          controls: {
            play: false,
            pause: false,
            stop: false
          }
        },
        REFILLED: {
          text: 'Refilled',
          controls: {
            play: false,
            pause: false,
            stop: true
          }
        }
      }
    };
  },
  methods: {
    getRefillString(med) {
      if (med.refills) {
        if (['SCHEDULED', 'WAITING', 'PROCESSING', 'MIGRATED', 'DRAFTED'].includes(med.prescriptionstatus)) {
          return `Refills: ${med.refillinput}`;
        }
        const refills = med.refills - med.paidrefills;
        if (med.paidrefills === 0 && (med.paymentstatus === 'FAILED' || med.prescriptionstatus === 'PAUSED' || med.prescriptionstatus === 'CANCELLED')) {
          return `${med.refills - med.paidrefills - 1} out of ${med.refills - 1} refills`;
        }
        if (med.refills && med.paidrefills) {
          return `${refills} out of ${med.refills - 1} refills`;
        } else {
          return 'One time purchase';
        }
      } else {
        return 'No refills';
      }
    },
    parseUnixDate(date, formatString) {
      return format(fromUnixTime(date), formatString);
    },
    openAirtableRecord(prescription) {
      this.$store.dispatch('infoview/openAirtableDisplay', prescription);
    },
    // Button disablers
    disablePlay(prescription) {
      var _this$statusMapper$pr;
      if (prescription.can_restart) {
        return false;
      }
      return !((_this$statusMapper$pr = this.statusMapper[prescription.prescriptionstatus]) !== null && _this$statusMapper$pr !== void 0 && _this$statusMapper$pr.controls.play);
    },
    disablePause(prescription) {
      var _this$statusMapper$pr2;
      return !((_this$statusMapper$pr2 = this.statusMapper[prescription.prescriptionstatus]) !== null && _this$statusMapper$pr2 !== void 0 && _this$statusMapper$pr2.controls.pause);
    },
    disableStop(prescription) {
      var _this$statusMapper$pr3;
      return !((_this$statusMapper$pr3 = this.statusMapper[prescription.prescriptionstatus]) !== null && _this$statusMapper$pr3 !== void 0 && _this$statusMapper$pr3.controls.stop);
    },
    isMoreThan90Days(date) {
      if (!date) {
        return false;
      }
      return differenceInDays(new Date(), new Date(date * 1000)) > 90;
    },
    openPausePrescription(prescription) {
      if (prescription.prescriptionstatus == 'WAITING') {
        this.$emit('open-stop-delay', prescription);
      } else {
        this.$emit('open-pause-prescription', prescription);
      }
    },
    async openSingleCancel(prescription) {
      if (!this.statusMapper[prescription.prescriptionstatus].controls.stop) {
        return;
      } else if (prescription.prescriptionstatus == 'WAITING') {
        this.$emit('pause-timer', prescription);
      } else {
        this.$emit('open-cancel-single', prescription);
      }
    }
  }
};