import { makeId } from "@/utils/makeId";
import { getTextWidth } from "@/utils/getTextWidth";
export default {
  inheritAttrs: false,
  props: {
    truthyLabel: {
      type: String,
      default: "On"
    },
    falsyLabel: {
      type: String,
      default: "Off"
    },
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: `base-toggle-${makeId()}`,
      margin: 5,
      size: 25,
      fontSize: 12
    };
  },
  computed: {
    isChecked: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    containerHeight() {
      return this.margin * 2 + this.size;
    },
    containerWidth() {
      const {
        truthyLabel,
        falsyLabel,
        margin,
        size
      } = this;
      const longestLabel = truthyLabel.length > falsyLabel.length ? truthyLabel : falsyLabel;
      const textWidth = getTextWidth(longestLabel, this.fontSize);
      return (margin * 4 + size + textWidth) * 1.1;
    },
    insetInlineStart() {
      return this.isChecked ? this.containerWidth - this.size - this.margin * 2 : 0;
    }
  }
};