export default {
  props: {
    tab: {
      type: String,
      default: 'all'
    },
    rowData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      showDialog: false
    };
  },
  methods: {
    confirm() {
      if (this.tab == 'refundrequest') {
        this.$emit('dismiss-type', this.rowData.orderid, this.tab);
        this.showDialog = false;
      } else {
        this.$emit('dismiss', this.rowData.id);
        this.showDialog = false;
      }
    }
  }
};