import { PatientService } from '@/services/patient.service';
import { PrescriptionService } from '@/services/prescription.service';
export default {
  props: {
    rowData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      showDialog: false,
      buttonDisabled: false,
      patientData: {},
      prescriptionData: [],
      alert: {
        show: false,
        message: ''
      },
      toRetry: [],
      toResolve: []
    };
  },
  watch: {
    async showDialog(show) {
      if (show) {
        this.buttonDisabled = true;
        const patientData = await PatientService.getPatient(this.rowData.id);
        this.patientData = patientData.data;
        const {
          data
        } = await PrescriptionService.getPrescription(this.rowData.id);
        this.prescriptionData = data;
        this.buttonDisabled = false;
      } else {
        this.buttonDisabled = false;
        this.alert.show = false;
        this.patientData = {};
        this.prescriptionData = [];
        this.toResolve = [];
        this.toRetry = [];
      }
    }
  },
  computed: {
    failedprescriptions() {
      return this.prescriptionData.filter(o => o.paymentstatus == 'FAILED');
    },
    total() {
      let total = 0;
      for (let prescription of this.failedprescriptions) {
        total += prescription.totalamountwithtax;
      }
      return total;
    }
  },
  methods: {
    showAlert(msg) {
      this.alert.show = true;
      this.alert.message = msg;
    },
    async reloadData() {
      this.buttonDisabled = true;
      const patientData = await PatientService.getPatient(this.rowData.id);
      this.patientData = patientData.data;
      const {
        data
      } = await PrescriptionService.getPrescription(this.rowData.id);
      this.prescriptionData = data;
      this.buttonDisabled = false;
    },
    async retryPayment() {
      try {
        this.buttonDisabled = true;
        this.alert.show = false;
        if (this.toRetry.length == 0 && this.toResolve.length == 0) {
          this.showAlert('Please select a prescription to retry or resolve.');
        }
        for (let prescription of this.toRetry) {
          const {
            data
          } = await PrescriptionService.retryPayment(prescription);
          if (data.paymentstatus == 'FAILED') {
            await this.reloadData();
            throw `Payment retry for item "${data.medicinename}" failed. Reason: ${data.paymentremarks}`;
          }
        }
        this.$emit('delete-self', this.patientData.id);
        this.showDialog = false;
      } catch (error) {
        this.showAlert(error);
        this.buttonDisabled = false;
      }
    }
  }
};