import { PatientService } from '@/services/patient.service';
import { ActionService } from '@/services/action.service';

export default {
  namespaced: true,
  state: {
    searchResults: [],
    searchQuery: '',
    filters: {},
    statusCounts: {},
    isFetchingPatients: false,
    patients: [],
    totalPatients: 0,
    patient: null,
    emptyResultsAlert: false,
  },
  getters: {
    patientFullName: state => {
      return state.patient
        ? `${state.patient.firstname} ${state.patient.lastname}`
        : ''
    }
  },
  mutations: {
    EMPTY_SEARCH_RESULTS(state) {
      state.searchResults = [];
      state.searchQuery = '';
    },
    SET_SEARCH_RESULTS(state, patients) {
      state.searchResults = patients;
    },
    SET_SEARCH_QUERY(state, query) {
      state.searchQuery = query;
    },
    SET_TAB(state, tab) {
      state.tab = tab;
      if (state.searchQuery) {
        state.searchResults = [];
        state.searchQuery = '';
      }
    },
    CLEAR_FILTERS(state) {
      state.filters = {};
    },
    FILTER_DATA(state, filters) {
      state.filters = filters;
    },
    SET_STATE (state, payload) {
      state[payload.state] = payload.value
    },

    REMOVE_PATIENT_BY_ID (state, id) {
      const idx = state.patients.findIndex((o) => o.id == id)
      state.patients.splice(idx, 1);
    },

    UPDATE_PATIENT_BY_ID (state, { id, ...properties }) {
      const idx = state.patients.findIndex((o) => o.id == id)

      for (const key in properties) {
        state.patients[idx][key] = properties[key]
      }
    }
  },
  actions: {
    async getStatusCounts ({ commit }) {
      const { data } = await ActionService.getStatusCount();

      commit('SET_STATE', {
        state: 'statusCounts',
        value: data
      })
    },
    async getPatients ({ commit }, payload) {
      try {
        commit('SET_STATE', {
          state: 'isFetchingPatients',
          value: true
        });

        const { data } = await PatientService.getPatients(
          payload.type,
          payload.page,
          payload.search,
          payload.sortDirection,
          payload.sortField
        );

        commit('SET_STATE', {
          state: 'patients',
          value: data.items
        });

        commit('SET_STATE', {
          state: 'totalPatients',
          value: Number(data.count)
        });
      } catch (error) {
        console.log(error)
        throw error
      } finally {
        commit('SET_STATE', {
          state: 'isFetchingPatients',
          value: false
        });
      }
    },
    async getPatient ({ commit }, patientId) {
      try {
        const { data } = await PatientService.getPatient(patientId);

        commit('SET_STATE', {
          state: 'patient',
          value: data
        });

        return data
      } catch (error) {
        console.log(error)
        throw error
      }
    },
    getTabName(state) {
      return state.tabName;
    },
  },
};
