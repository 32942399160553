import { messageHeightMixin } from '@/mixins/messageHeightMixin';
export default {
  name: 'MessagesView',
  mixins: [messageHeightMixin],
  data() {
    return {
      userid: ''
    };
  },
  watch: {
    '$route.query.patientid': {
      async handler(patientId) {
        const {
          $store
        } = this;
        if (patientId) {
          await $store.dispatch('message/getSingleConversationList', {
            patientid: patientId
          });
        } else {
          $store.dispatch('message/resetState');
          await $store.dispatch('message/getConversationList', {
            isSearchEmpty: true
          });
        }
      },
      immediate: true
    }
  },
  async created() {
    this.userid = localStorage.getItem('userid');
  }
};